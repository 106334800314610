// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { ChangeEvent } from "react";
import { ContentView, DealershipNormalized, Role, ServiceableLocation } from "./MyTeamController.web";

export const webConfigJSON = require("./config.js");

export interface Props {
  currentView: ContentView;
  dealershipsData?: DealershipNormalized[];
  serviceableLocations? : ServiceableLocation[];
  totalItems?: number;
  itemsPerPage?: number;
  checkedItems?: number;
  sort: string;
  search: string;
  services? : string[];
  states?: string[];
  roles?: Role[];
  role?: string;
  page: number;
  totalPages: number;
  isAllChecked: boolean;
  isDeletePromptOpened?: boolean;
  isAdmin: () => boolean;
  dealershipsFilter?: string[];
  setSearch: (value: string) => void;
  setPage: (page: number) => void;
  setSort: (value: string) => void;
  setRole?: (role: string) => void;
  setServices?: (service: string[]) => void;
  setStates?: (service: string[]) => void;
  handleCheckAll: () => void;
  setDeletePromptState?: () => void;
  handleBulkDeleteUserPrompt?: () => void
  handleDealershipsModal?: () => void;
  handleDealershipsFilterSelect?: (event: ChangeEvent<{ name?: string; value: unknown }>) => void;
  handleDealershipsFilterClose?: () => void;
}

interface S {
  showPrompt: boolean;
}
interface SS {}

export default class TableHeadController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPrompt: false,
    };
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  handleSetSort = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    this.props.setSort(event.target.value as string);
  };

  handleSetRole = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    if (!this.props.setRole) return;
    this.props.setRole(event.target.value as string);
  };

  handleSetServices= (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    if (!this.props.setServices) return;
    this.props.setServices(event.target.value as string[] || []);
  }

  handleSetStates= (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    if (!this.props.setStates) return;
    this.props.setStates(event.target.value as string[] || []);
  }

  handleSearch = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const search = event.target.value; 
    this.props.setSearch(search);
  };

  findProperItemsName = (itemsNumber: number) => {
    if (this.props.currentView === ContentView.DEALERSHIPS) {
      return itemsNumber > 1
        ? "dealerships"
        : "dealership";
    } else if(this.props.currentView === ContentView.SERVICEABLE_LOCATIONS){
      return itemsNumber > 1
        ? "locations"
        : "location";
    } else{
      return itemsNumber > 1 
        ? "users" 
        : "user";
    }
  };

  nextPage = () => {
    const { page, totalPages, setPage } = this.props;

    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  prevPage = () => {
    const { page, setPage } = this.props;

    if (page > 1) {
      setPage(page + 1);
    }
  };
}
// Customizable Area End
