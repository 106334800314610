import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities"
import { FormikValues } from "formik";
import * as Yup from 'yup';


interface OtpSuccess{
  meta:{
    token:string;
  }
}
interface ErrorResponseEmail {
  errors?: (ErrorsEntity)[] | null;
}interface ErrorsEntity {
  account: string;
}

interface SuccessResponseEmail {
  data:{
    message:string;
  }
}

export interface GetUserResponseSuccess {
  data: Data;
}
export interface Data {
  id: string;
  type: string;
  attributes: Attributes;
}
export interface Attributes {
  activated: boolean;
  country_code: string;
  email: string;
  full_phone_number: string;
  phone_number: string;
  type: string;
  full_name: string;
  created_at: string;
  updated_at: string;
  is_dealer_group: boolean;
  dealer_group_name?: null;
  role: string;
  profile_picture?: null;
  group_created_on?: null;
  is_deal_pending: boolean;
  deal_data?: null;
  solo_dealership_id: number;
}

interface GetUserError {
  errors:[
    {
      token:string;
    }
  ]
}

interface OtpError{
  errors:[
    {
      full_phone_number:string;
      detail:string;
      account:string;
    }
  ]
}
interface OtpAuthSuccess{
  meta:{
    token:string;
    message:string;
  }
}

interface NameResponseSuccess{
  data:{
    message:string;
  }
}

interface PhotoSuccess{
  status_code: number;
}

interface PasswordSucess{
  data:{
    message:string;
  }
}
interface PasswordFailure{
  errors:[
    {
      profile:string;
    }
  ]
}

interface DeactivateAccountError{
  errors:{
    message:string;
  }
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: any;
  lastName: any;
  email: any;
  phoneNumber: any;
  currentCountryCode: any;
  data: any[];
  passwordHelperText: String;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;

  currentPasswordText: any;
  newPasswordText: any;
  reTypePasswordText: any;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;

  profilePhoto:string | null |undefined;
  initials:string | null;
  fullName:string;
  myEmail:string;
  isChangeName:boolean;
  newpas:string;
  myPhoneNumber:string;
  displayedPassword:string;
  selectedItem:string;
  isEmailDrawerOpen:boolean;
  changedEmail:string;
  isPhoneDrawerOpen:boolean;
  changedPhone:string;
  emailErrorFromBe:string;
  openToastEmail:boolean;
  receivedRole:string;
  openToastName:boolean;
  otpErrorFromBe:string;
  isPhoneConfirmed:boolean;
  otp:string;
  otpToken:string;
  openToastPhone:boolean;
  timer:number;
  isPasswordDrawerOpen:boolean;
  errorMsgOtp:string;
  errorPhoto:string;
  changedPhoto:string | null |undefined;
  savePhotoButtonEnable:boolean;
  newPhoto:File|null;
  openToastPhoto: boolean;

  showPassword1: boolean;
  showPassword2: boolean;
  passwordError: string;
  openToastpassword:boolean;

  deactivateReason:string;
  charCount:number;
  deactivateMessage:string;
  isDeactivateDrawerOpen:boolean;
  showDeactivatePassword:boolean;
  deactivateError:string;

  isOpenLogoutModal:boolean;
  anchorEl: null | HTMLElement;

  labelEmail:string;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start


  changeNameApiCallId:string="";
  changeEmailApiCallId:string="";
  getuserDataApiCallId:string="";
  otpCallId:string="";
  otpAuthApiCallId:string="";
  timerID: NodeJS.Timeout | null = null;
  changePhotoApiCallId:string="";
  passwordCallId:string="";

  deactivateCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
   
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,

      currentPasswordText: "",
      newPasswordText: "",
      reTypePasswordText: "",

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,

      profilePhoto:null,
      initials:'',
      fullName:'',
      myEmail:'',
      isChangeName:false,
      newpas:'Test@1234',
      myPhoneNumber: '',
      displayedPassword:'',
      selectedItem:'My Profile',
      isEmailDrawerOpen:false,
      changedEmail:'',
      isPhoneDrawerOpen:false,
      changedPhone:'',
      emailErrorFromBe:'',
      openToastEmail:false,
      receivedRole:'',
      openToastName:false,
      otpErrorFromBe:'',
      isPhoneConfirmed:false,
      otp:'',
      otpToken:'',
      openToastPhone:false,
      timer:120,
      isPasswordDrawerOpen:false,
      errorMsgOtp:'',
      errorPhoto:'',
      changedPhoto:'',
      savePhotoButtonEnable:false,
      newPhoto:null,
      openToastPhoto:false,

      showPassword1:false,
      showPassword2:false,
      passwordError:'',
      openToastpassword:false,

      deactivateReason:'',
      charCount:0,
      deactivateMessage:'',
      isDeactivateDrawerOpen:false,
      showDeactivatePassword:false,
      deactivateError:'',

      isOpenLogoutModal:false,
      anchorEl:null,

      labelEmail:'New Email Address'
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.changeNameApiCallId:
            this.handleNameResponse(responseJson);
            break;
  
          case this.changeEmailApiCallId:
            this.handleEmailApiResponse(responseJson);
            break;
  
          case this.getuserDataApiCallId:
            this.handleUserData(responseJson);
            break;
  
          case this.otpCallId:
            this.handleOtpCall(responseJson);
            break;
  
          case this.otpAuthApiCallId:
            this.handleOtpAuth(responseJson);
            break;
  
          case this.changePhotoApiCallId:
            this.handlePhotoResponse(responseJson)
            break;
          
          case this.passwordCallId:
            this.handlePasswordResponse(responseJson)
            break;
          
          case this.deactivateCallId:
            this.handleDeactivateResponse(responseJson)
      }
      }
    } 

    // Customizable Area End
  }




  // Customizable Area Start
  handleEditProfileMenu=()=>{
    this.handleCloseMenu();
    this.handleSelection('My Profile')
  }
  handleLogoutMenu=()=>{
    this.handleLogoutModal();
    this.handleCloseMenu();
  }

  handleOpenMenu=( event: React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
    this.setState({anchorEl:event.currentTarget})
  }

  handleCloseMenu=()=>{
    this.setState({anchorEl:null})
  }





  handleLogoutModal=()=>{
    this.setState({isOpenLogoutModal: !this.state.isOpenLogoutModal})
  }

  handleDeactivateResponse=(responseJson:DeactivateAccountError)=>{
    if(responseJson.errors){
      this.setState({deactivateError:responseJson.errors.message})
    }
    else{
      this.gotoLogin()
    }
  }

  gotoDashboard=()=>{
    const newroute = new Message(getName(MessageEnum.NavigationMessage));
    newroute.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    newroute.addData(getName(MessageEnum.NavigationTargetMessage), 'Dashboard')
    
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {view: "HOME"});
    newroute.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    runEngine.sendMessage("MergeEngineUtilities", newroute);
  }

  gotoLogin=()=>{
    removeStorageData('authToken')
    const gggg = new Message(getName(MessageEnum.NavigationMessage));
    gggg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    gggg.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailAccountLoginBlock')
    this.send(gggg);
  }

  handleSubmitDeactivatePassword=async(values: FormikValues)=>{
    const tokn = await getStorageData("authToken")
    const passs = values.deactivatePassword
    const Webattri = {
      deactive_reason: this.state.deactivateReason,
      feedback: this.state.deactivateMessage,
      password: passs
    };
    const Webheader = {
      "Content-Type": 'application/json',
      "token": tokn
    };
    const Webdata = {
      data:{
        attributes: Webattri,
      }
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deactivateCallId = WebrequestMessage.messageId;
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     'bx_block_settings/settings/deactive_account'
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Webdata)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  toggleDrawerDeactivate=()=>{
    this.setState({isDeactivateDrawerOpen: !this.state.isDeactivateDrawerOpen, deactivateError:''})
  }

  handleDeactivateReason=(event: React.ChangeEvent<{ value: unknown }>)=>{
    this.setState({deactivateReason: event.target.value as string})
  }

  handleTextAreaChange=(event: React.ChangeEvent<{ value: string }>)=>{
    this.setState({deactivateMessage:event.target.value },()=>{ 
      this.setState({charCount:this.state.deactivateMessage.length })
      })
  }

  handleShowDeactivatePassword = () => {
    this.setState({showDeactivatePassword: !this.state.showDeactivatePassword})
    }  

  getDescr=(value:string)=>{
    switch (value) {
      case 'My Profile':
        return 'Your account profile information';
      case 'Deactivate Account':
        return 'Follow these steps to deactivate your account.'
      case 'My Subscription Plan':
        return 'Choose the Best Plan for Your Needs'
    }
  }

  getHeader=(value:string)=>{
    if(value==='My Profile'){
      return 'Profile'
    }else{
      return value
    }
  }

  isErrorborderr = (touched:boolean | undefined, error:string | undefined, color1: string, color2: string)=>{
    if(touched && error){
    return color1
    }
    return color2
   }


  validationSchema = Yup.object({
    password2: Yup.string()
      .min(8, "Invalid Password")
      .matches(/[a-z]/, "Invalid Password")
      .matches(/[A-Z]/, "Invalid Password")
      .matches(/\d/, "Invalid Password")
      .required("Required"),
  });

  handlePasswordResponse=(responseJson: PasswordSucess & PasswordFailure)=>{
    if(responseJson.errors){
      this.setState({passwordError : responseJson.errors[0].profile})
    }
    else if(responseJson.data.message){
      this.toggleDrawerPassword()
      this.setState({openToastpassword :true})
    }
  }

  handleSubmitPassword=async(values: FormikValues)=>{
    const tokn = await getStorageData("authToken")
    const Webdata = {
      current_password: values.password1,
      new_password: values.password2
    };
    const Webheader = {
      "Content-Type": 'application/json',
      "token": tokn
    };
    const Wedata = {
      data: Webdata,
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.passwordCallId = WebrequestMessage.messageId;
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     'bx_block_profile/passwords'
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Wedata)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  handleFocusEmail = () => {
    this.setState({ labelEmail: "Email Address" });
  };

  handleBlurEmail = () => {
    if (!this.state.changedEmail) {
      this.setState({ labelEmail: 'New Email Address' });
    }
  };

  handleShowPassword = (value: string) => {
    switch (value) {
      case "password2":
        this.setState({showPassword2: !this.state.showPassword2})
        break;
      case "password1":
        this.setState({showPassword1: !this.state.showPassword1})
        break;
    }  
  }; 

  passwordCriteria = [
    { label: 'At least one uppercase character (A-Z)', test: (value:string) => /[A-Z]/.test(value) },
    { label: 'At least one numerical (0-9)', test: (value:string) => /\d/.test(value) },
    { label: 'At least one lowercase character (a-z)', test: (value:string) => /[a-z]/.test(value) },
    { label: 'Minimum 8 characters long', test: (value :string) => value && value.length >= 8 },
  ];

  handlePhotoResponse=(responseJson: PhotoSuccess)=>{
    if (responseJson.status_code === 200) {
      this.setState({
        savePhotoButtonEnable: false,
        profilePhoto: this.state.changedPhoto,
        openToastPhoto: true,
      });
    }
  }

  handleNameResponse=(responseJson: NameResponseSuccess)=>{
    if (responseJson.data.message) {
      this.setState({ isChangeName: false, openToastName: true });
    }
  }

  gotoTermsCondition = () =>{
    setStorageData('selectedSection', this.state.selectedItem)
    const msgg = new Message(getName(MessageEnum.NavigationTermAndConditionMessage));
    msgg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msgg);
  }
 gotoContactUs=()=>{
    const msgg = new Message(getName(MessageEnum.NavigationMessage));
    msgg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msgg.addData(getName(MessageEnum.NavigationTargetMessage), 'Contactus')
    this.send(msgg);
  }

  async componentDidMount(){
    this.getUserData();
    this.setState({displayedPassword: '*'.repeat(this.state.newpas.length)})

    const selecteditem = await getStorageData('selectedSection')
    if(selecteditem){
      this.setState({selectedItem: selecteditem}, ()=>{removeStorageData('selectedSection')})
    }
    else{
      this.setState({selectedItem: 'My Profile'})
    }
   
  }

  formatTime = (timer: number) => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  startTimer() {
    this.timerID = setInterval(() => {
      if(this.state.timer >0){
        this.setState((prevState) => ({
          timer: prevState.timer - 1,
        }));
      }
    }, 1000);
  }

  resendOtpagain = ()=>{
    this.setState({timer:120})
    this.handleSubmitPhone()
  }

  handleOtpAuth=(responseJson:OtpAuthSuccess & OtpError)=>{
    if(responseJson.errors){
        this.setState({errorMsgOtp:responseJson.errors[0].detail})
    }
    else if (responseJson.meta.message && responseJson.meta.token) {
      this.setState({openToastPhone:true, myPhoneNumber:this.state.changedPhone},()=>{this.toggleDrawerPhone()})
    }
  }

  checkRoleConditon=(valu:string)=>{
    return(valu==='dealer_admin' || valu==="service_provider_admin")
  }

  handleOtpCall=(responseJson:OtpError & OtpSuccess)=>{
    if(responseJson.errors){
      if(responseJson.errors[0].full_phone_number){this.setState({otpErrorFromBe: responseJson.errors[0].full_phone_number})}
      else if(responseJson.errors[0].account){this.setState({otpErrorFromBe: responseJson.errors[0].account})}
    }
    else{
      this.setState({otpErrorFromBe:'', isPhoneConfirmed:true, otpToken:responseJson.meta.token}, ()=>{this.startTimer()})
    }
  }

handleSubmitFormik=(values: FormikValues)=>{
  this.setState({changedPhone: values.phoneNumber}, ()=>{this.handleSubmitPhone()})
}

  handleSubmitPhone=async()=>{
    const newtoken = await getStorageData('authToken')
    const Webattrs = {
      full_phone_number: "1"+this.state.changedPhone,
    };
    const Webheader = {
      "Content-Type": 'application/json',
      "token": newtoken
    };
    
    const Wedata = {
      attributes: Webattrs,
    };
    const newData = {
      data:Wedata
    }
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.otpCallId = WebrequestMessage.messageId;
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     '/account_block/accounts/send_otps/send_mobile_otp'
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(newData)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  getColorr=(value:string)=>{
    return(value ? "rgba(75, 76, 75, 1)":"gray")
  }
  handleRegex = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, handleChange: {(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void}) => {
    if(event.target.value.length<10){this.setState({otpErrorFromBe:''})}
    const value = event.target.value
    if (/^\d*$/.test(value)) {
    handleChange(event)
}
}
  handleUserData=(responseJson:GetUserResponseSuccess & GetUserError)=>{
    if(!responseJson.errors){
      this.setState({changedPhoto:responseJson.data.attributes.profile_picture, profilePhoto:responseJson.data.attributes.profile_picture ,receivedRole:responseJson.data.attributes.role, fullName:responseJson.data.attributes.full_name, myEmail: responseJson.data.attributes.email, myPhoneNumber: responseJson.data.attributes.phone_number},()=>{ this.getInitials()})
    }
    else{
      this.getInitials()
    }
  }

  handleEmailApiResponse=(responseJson:ErrorResponseEmail & SuccessResponseEmail)=>{
    if(responseJson.errors){
      this.setState({emailErrorFromBe : responseJson.errors[0].account})
    }
    else{
      this.setState({emailErrorFromBe:'', openToastEmail:true})
      this.toggleDrawerEmail();
    }
  }

  toggleDrawerEmail=()=>{
    this.setState({isEmailDrawerOpen: !this.state.isEmailDrawerOpen, changedEmail:'', emailErrorFromBe:'', labelEmail:'New Email Address'})
  }
  toggleDrawerPhone=()=>{
    this.setState({isPhoneDrawerOpen: !this.state.isPhoneDrawerOpen, changedPhone:''}, ()=>{
      this.setState({isPhoneConfirmed:false, otp:'', otpToken:'',errorMsgOtp:'', otpErrorFromBe:''})
    })
  }
  toggleDrawerPassword=()=>{
    this.setState({isPasswordDrawerOpen: !this.state.isPasswordDrawerOpen, passwordError:''})
  }

  getUserData=async()=>{
    const newtoken = await getStorageData('authToken')
    const Webheader = {
      "Content-Type": 'application/json',
      "token": newtoken
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getuserDataApiCallId = WebrequestMessage.messageId;   
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     'account_block/accounts/get_user'
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  formatMobileNumber = (phoneNumber: string) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^1?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  handleChangeNameBe= async()=>{
    const newtoken = await getStorageData('authToken')
    const Webattrs = {
      full_name: this.state.fullName,
    };
    const Webheader = {
      "Content-Type": 'application/json',
      "token": newtoken
    };
    const Wedata = {
      account: Webattrs,
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.changeNameApiCallId = WebrequestMessage.messageId;
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     'account_block/accounts/update_account'
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Wedata)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  handleChangeOtp = (otp: string) => {
    if(otp.length<4){this.setState({errorMsgOtp:''})}
    this.setState({ otp });
  }

  doItFalse=()=>{
    this.setState({ openToastEmail: false, openToastName:false, openToastPhone:false, openToastPhoto:false, openToastpassword:false })
  }

  getHeading=(newState :boolean, textmessage1 :string, textmessage2:string)=>{
    return(newState ? textmessage1: textmessage2)
  }

  handleSubmitOtp=async()=>{
    const newotp = this.state.otp;
    const ttoken =  await getStorageData("authToken")
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
      const myData = {
        otp_token: this.state.otpToken,
        pin: Number(newotp)
      };

      const headers = {
        "Content-Type":'application/json',
        "token" : ttoken
      };

      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'account_block/accounts/otp_confirmation'
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(myData)
      );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  ErrorBoxx = (status: boolean, valuee: string) => {
    return (status ? valuee : '')
  };

  handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const validTypes = ['image/jpg', 'image/png', 'image/jpeg'];
      const maxSize = 1 * 1024 * 1024;
  
      if (!validTypes.includes(file.type)) {
         this.setState({ errorPhoto: 'Only JPG or PNG files are allowed.' });
        return;
      }
  
      if (file.size > maxSize) {
         this.setState({ errorPhoto: 'File size should not exceed 1 MB.' });
        return;
      }
  
      this.setState({
        changedPhoto: URL.createObjectURL(file),
        newPhoto: event.target.files[0],
        errorPhoto: '',
      },()=>{
        if(this.state.changedPhoto != this.state.profilePhoto){
          this.setState({savePhotoButtonEnable:true})
        }
      });

      event.target.value = '';
    }
  };
  handleDeletePicture = ()=>{
    this.setState({changedPhoto: null},()=>{
      if(this.state.changedPhoto != this.state.profilePhoto){
        this.setState({savePhotoButtonEnable:true})
      }else{
        this.setState({savePhotoButtonEnable:false})
      }
    })
  }


  savePhotoApi=async()=>{

    const formData = new FormData()
    if(this.state.newPhoto)
      formData.append("account[profile_picture]", this.state.newPhoto)
    else{
      formData.append("account[remove_profile_picture]", 'true')
    }
    

    const newtoken = await getStorageData('authToken')

    const Webheader = {
      "token": newtoken
    };;
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.changePhotoApiCallId = WebrequestMessage.messageId;
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     'account_block/accounts/update_account'
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  clearPasswordError=()=>{
    this.setState({passwordError:''})
  }
  handleNameChange=(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
    const value = event.target.value;
    const cleanedValue = value.replace(/[^a-zA-Z\s]/g, '').replace(/^\s+/, '').replace(/\s\s+/g, ' ');
    this.setState({fullName: cleanedValue}, ()=>this.getInitials())
  }
  getInitials = ()=>{
    if (this.state.fullName) {
      const nameArray = this.state.fullName.split(' ');
      const derivedInitials = nameArray.map(name => name.charAt(0)).join('').slice(0, 2).toUpperCase();
      this.setState({initials:derivedInitials})
    } else {
      this.setState({initials:null})
    }
  }

  handleEmailChange=(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
    if(this.state.changedEmail != event.target.value){
      this.setState({emailErrorFromBe:''})
    }
     this.setState({changedEmail:event.target.value})
  }
  handleOnFocusFullName = (event:React.FocusEvent<HTMLInputElement>)=>{
    return (event.target.labels && event.target.labels.length > 0)
 }

  bbbb = (labels: NodeListOf<HTMLLabelElement> | null) => {
   if (labels && labels.length > 0) {
     const label = labels[0];
     label.style.marginLeft = '0px';
     label.style.paddingLeft = '0px';
     label.style.color = "#4B4C4B";
   }
 };

 handleBlurr = (event: React.FocusEvent<HTMLInputElement>)=>{
   return (event.target.value === '' && event.target.labels && event.target.labels.length > 0)
 }
 cccc = (labels: NodeListOf<HTMLLabelElement> | null, leftmargin:string)=>{
   if (labels && labels.length > 0) {
     const label = labels[0];
     label.style.marginLeft = leftmargin;
     label.style.color = "gray";
   }
 }

 handleEditName=()=>{
  this.setState({isChangeName:true})
 }

  handleSelection = (item:string) => {
  this.setState({selectedItem:item})
};

handleColorr = (valuew: string)=>{
  return(valuew? '#4B4C4B' : '#C5CBC9')
}

handleEmailEditApi= async()=>{
  const newtokenn = await getStorageData('authToken')
    const Webattrs = {
      email: this.state.changedEmail,
    };
    const Webheader = {
      "Content-Type": 'application/json',
      "token": newtokenn
    };
  
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.changeEmailApiCallId = WebrequestMessage.messageId;
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     'bx_block_profile/profiles/send_change_email_link'
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Webattrs)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
}
  // Customizable Area End

}