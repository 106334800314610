Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.ApiGetMethodType = "GET";
exports.ApiDeleteMethodType = "DELETE";
exports.ApiPostMethodType = "POST";
exports.ApiPutMethodType = "PUT";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";


//// My team
exports.getUserUrl = "account_block/accounts/get_user"
exports.getDealershipsUrl = "bx_block_profile/dealer_informations";
exports.getUsersUrl = "bx_block_teambuilder/dealership_members/dealership_user_list";
exports.deleteUserUrl = "bx_block_teambuilder/dealership_members/delete_user";
exports.deleteUserUrlSP = "bx_block_teambuilder/provider_members/delete_user";
exports.addUserUrl = "bx_block_teambuilder/dealership_members/update_dealership_user";
exports.editUserSPUrl = "bx_block_teambuilder/provider_members/update_provider_user";
exports.updateDealerGroupUrl = "account_block/accounts/update_account";
exports.inviteUserToDealershipUrl = "bx_block_teambuilder/dealership_members/invite_user";
exports.inviteUserToServiceableLocationUrl = "bx_block_teambuilder/provider_members/invite_user";
exports.getRolesUrl = "/bx_block_profile/dealer_informations/get_dealership_roles"
exports.contentType = "application/json";
// Customizable Area End