import React from "react";
// Customizable Area Start
import {
  Box,
  withStyles,
  createStyles,
  Typography,
  Button,
  AppBar,
  Tabs,
  Tab,
  FormGroup,
  TextField,
} from "@material-ui/core";
import DealDashboardTaskListController, {
  Props,
  Task,
  configJSON,
} from "./DealDashboardTaskListController";
import CustomTask from "../../../components/src/CustomTask";
const styles = createStyles({
  taskNoteSection: {
    marginTop: "25px",
    padding: "35px 24px",
    borderRadius: "10px",
    background: "#FFFFFF",
    boxShadow: "0px 0px 4px 0px #8989895C",
    overflow: "auto",
    maxHeight: "230px"
  },

  taskNoteHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  addTaskBtn: {
    textTransform: "initial",
    color: "#4FC8EC",
    fontWeight: 700,
    fontSize: "16px"
  },

  taskListSection: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  taskListTabs: {
    width: "288px",
    boxShadow: "none",
    color: "black",
    background: "#FFF",
  },

  taskTabs: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "24px",

    "& .MuiTabs-flexContainer": {
      justifyContent: "space-between",
    },
  },

  taskTab: {
    minWidth: "0px",
    fontSize: "16px",
    fontWeight: 500,
    padding: 0,
    color: "rgba(197, 203, 201, 1)",
    "&.Mui-selected": {
      color: "rgba(1, 61, 79, 1)"
    },
    "& > span": {
      textTransform: "initial",
    },
  },

  tabPanelContainer: {
    width :"100%",
    marginTop: "10px",

    "& .MuiBox-root": {
      padding: 0,
    },
  },

  tabPanel: {
    fontSize: "20px",
    fontWeight: 300,
    fontFamily: "Gotham, sans-serif",
  },

  taskListContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  popperPaper: {
    boxShadow: "none",
  },

  popperTypo: {
    padding: 2,
    color: "#4FC8EC",

    "&:hover": {
      cursor: "pointer",
    },
  },

  newTaskInput: {
    width: "100%",
    marginBottom: "18px",

    "& .MuiInput-input": {
      fontFamily: "Gotham",
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "18px",
      textAlign: "left",
    },
  },

  taskLength: {
    color: "#C5CBC9",
  },

  noTasks:{
    fontFamily: "Gotham",
    fontSize: "20px",
    fontWeight: 300,
    lineHeight: "18px",
    textAlign: "center",
    color: "rgba(197, 203, 201, 1)",
    marginTop: "40px",
  }
});

function a11yProps(index: any) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
// Customizable Area End

export class DealDashboardTaskList extends DealDashboardTaskListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      taskListOption: 0,
      tasks: [],
      isAddingTask: false,
      taskEditting: "",
    };
    // Customizable Area End
  }
  // Customizable Area Start
  onSelectChange = (value: string, id: string) => {
    if (value === "edit") {
      this.editTask(id);
    }
    if (value === "delete") {
      this.deleteTask(id);
    }
  };
  
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { taskListOption, tasks, isAddingTask, taskEditting } = this.state;
    
    let completedTasks : Task[]= [];
    let inProgressTasks : Task[] = [];
    if(tasks){
      completedTasks = tasks.filter((task) => task.status === "complete");
      inProgressTasks = tasks.filter(
        (task) => task.status === "in_progress"
      );
    }
    
    const tabs = [
      {
        name: "All",
        dataTestID: "all-tab",
      },
      {
        name: "In Progress",
        dataTestID: "completed-tab",
      },
      {
        name: "Completed",
        dataTestID: "in-progress-tab",
      },
    ];

    const tabContents = [
      {
        tasks: tasks,
        dataTestID: "all-tasks-content",
        noTaskMessage: configJSON.noTasks,
      },
      {
        tasks: inProgressTasks,
        dataTestID: "in-progress-tasks-content",
        noTaskMessage: configJSON.noInprogressTasks,
      },
      {
        tasks: completedTasks,
        dataTestID: "completed-tasks-content",
        noTaskMessage: configJSON.noCompletedTasks,
      },
    ];
    return (
      <Box className={classes.taskNoteSection}>
        <Box className={classes.taskNoteHeader}>
          <Typography variant="h1" className="gothamText">
            Task List
            <span className={classes.taskLength}> ({tasks? tasks.length : 0})</span>
          </Typography>
          <Button
            className={classes.addTaskBtn}
            data-test-id="button-add-task"
            color="primary"
            onClick={() => {
              this.handleChangeAddTaskState();
            }}
          >
            Add Task
          </Button>
        </Box>
        <Box className={classes.taskListSection}>
          <AppBar position="static" className={classes.taskListTabs}>
            <Tabs
              value={taskListOption}
              aria-label="full width tabs example"
              className={classes.taskTabs}
              TabIndicatorProps={{
                style: { display: "none" },
              }}
            >
              {tabs.map((tab, index) => (
                <Tab
                  onClick={() => this.handleChangeTaskListOption(index)}
                  key={index}
                  data-test-id={tab.dataTestID}
                  disableRipple
                  label={tab.name}
                  {...a11yProps(index)}
                  className={classes.taskTab}
                />
              ))}
            </Tabs>
          </AppBar>
          <Box className={classes.tabPanelContainer}>
            {isAddingTask && (
              <TextField
                focused={true}
                label="New Task"
                data-test-id="new-task-text-field"
                className={classes.newTaskInput}
                onKeyDown={this.handleAddTask}
              />
            )}
            {tabContents.map((tabContent, index) => {
              return (
                <TabPanel
                  value={taskListOption}
                  index={index}
                  key={index}
                >
                  <Box className={classes.taskListContainer}>
                    {(
                      <FormGroup data-test-id={tabContent.dataTestID} style={{width: "100%"}}>
                        {tabContent.tasks.length === 0 && !isAddingTask && <Typography className={classes.noTasks}>{tabContent.noTaskMessage}</Typography>}
                        {tabContent.tasks?.map((task, index) =>
                          taskEditting === task.id ? (
                            <TextField
                              value={task.title}
                              key={task.id}
                              data-test-id="edit-task-text-field"
                              className={classes.newTaskInput}
                              onKeyDown={this.handleEditTask}
                              onChange={this.handleEditTask}
                            />
                          ) : (
                            <CustomTask
                              task={task}
                              key={task.id}
                              onSelectChange={this.onSelectChange}
                              changeTaskStatus={this.changeTaskStatus}
                              onMoveTaskDown={this.handleMoveTaskDown}
                              onMoveTaskUp={this.handleMoveTaskUp}
                            />
                          )
                        )}
                      </FormGroup>
                    )}
                  </Box>
                </TabPanel>
              );
            })}
          </Box>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
// Customizable Area End
export default withStyles(styles)(DealDashboardTaskList);