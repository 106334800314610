import React from "react";

// Customizable Area Start
import { GoogleLogin } from 'react-google-login';
import {
  Box,
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
  InputAdornment ,
  IconButton, styled
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import {grouppng,vitulogoimg,googleimgicon} from '../../email-account-login/src/assets'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { Formik, Form, Field,ErrorMessage  } from 'formik';


import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

const Buttondb = styled(Button)({
  '&:disabled': {
    color:'#75D3F0',
    width:'512px',
    backgroundColor:'#F0FAFA', height:'50px',
    '@media (max-width: 600px)': {
      width: "220px",
    },
    '@media (max-width: 700px)': {
      width: "300px",
    },
  },
  '&:enabled': {
    color:'white',
    width:'512px',
    backgroundColor:'#4FC8EC', height:'50px',
    '@media (max-width: 600px)': {
      width: "220px",
    },
    '@media (max-width: 700px)': {
      width: "300px",
    },
  },
  
});

const RightBox =styled(Box)({
  "@media (max-width:699px)": {
    display:'none'
  },
  "@media (min-width:700px)": {
    display:'block'
  },
})

const LeftBox =styled(Box)({
  "@media (max-width:699px)": {
    width:'100%'
  },
  "@media (min-width:700px)": {
    width:'66.6%'
  },
})
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start

    <Box style={{ display:'flex', width:'100vw', height:'100vh'}}>
      <LeftBox>
          <Box style={{marginTop:'50px', marginLeft:'11%'}}>
            <img src={vitulogoimg} style={{ height: '42px', width: '91.27px'}} />
          </Box>
        

        <Box
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
          height={"calc(100vh - 142px)"}
        >
          <Box width={"60%"} display={"flex"} flexDirection={"column"}  style={{ rowGap:'16px', maxWidth:'520px', marginTop:'80px'}}>
            <Box display={"flex"} flexDirection={"column"} style={{rowGap:'0px', width:'100%'}}>
                <Typography className="gothamText" style={{ fontWeight: '300', color: '#013D4F', fontSize: '30px'}} >Welcome Back!</Typography>
                <Typography className="gothamText" style={{ fontWeight: '300', color: '#4B4C4B', fontSize: '18px' }}>Please log in to your account</Typography>
            </Box>
            {this.state.receivedError && 
            <Box style={{backgroundColor:'#FEE2E2', height:'46px', width:'380px', borderLeft:'4px solid #C43937', borderRadius:'8px'}}>
                  <Typography style={{color:'#C43937', paddingTop:'13px', marginLeft:'10px', fontSize:'14px', fontWeight:700}}>{this.state.receivedError}</Typography>
            </Box>
            }

            <Formik
                initialValues={{ email: '', password: '' }}
                validationSchema={this.validationSchema}
                onSubmit={this.handleSubmit}
              >
                {({ errors, touched, handleChange, handleBlur, values }) => (
                  <Form>
                    <Box style={{ width: '100%' }}>
                      <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', border:`1px solid ${this.isErrorborder(touched.email, errors.email ,'#C43937', '#C5CBC9')}`, height: '56px', padding: '10px, 24px, 10px, 24px', borderRadius: '8px'}}>
                        <EmailOutlinedIcon style={{ textAlign: 'start', marginLeft: '10px', padding: '5px', color: values.email ? '#4B4C4B' : '#C5CBC9' }} />
                        <Field
                          name="email"
                          type="email"
                          placeholder="Please enter your email ID"
                          as={TextField}
                          InputProps={{ disableUnderline: true, style: { border: 'none', height: '', marginTop:'5px' } }}
                          InputLabelProps={{ shrink: false }}
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="email"
                          fullWidth
                        />
                      </Box>
                      <ErrorMessage name="email" component="div" style={{marginTop:'5px', color: "#DC2626", fontSize:'12px', fontFamily:'Roboto', fontWeight:400 }} />
                    </Box>
                    <Box  style={{ marginTop: '16px',width: '100%' }} >
                      <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center', border:`1px solid ${this.isErrorborder(touched.password, errors.password ,'#C43937', '#C5CBC9')}`, height: '56px', padding: '10px, 24px, 10px, 24px', borderRadius: '8px' }}>
                        <LockOutlinedIcon style={{ textAlign: 'start', padding: '5px', color: values.password ? '#4B4C4B' : '#C5CBC9', marginLeft: '10px' }} />
                        <Field
                        className="password"
                          name="password"
                          data-test-id="passwordtest"
                          type={this.state.showPassword ? 'text' : 'password'}
                          placeholder="Please enter your password"
                          as={TextField}
                          InputProps={{
                            disableUnderline: true,
                            style: { border: 'none', height: '',marginTop:'5px' },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                className="password_button_type"
                                 data-test-id="eyebutton"
                                  aria-label="toggle password visibility"
                                  onClick={this.handleShowPass}
                                  onMouseDown={this.handleMouseDownPassword}
                                  edge="end"
                                  style={{ marginRight: '10px', color: values.password ? '#4B4C4B' : '#C5CBC9' }}
                                >
                                  {this.state.showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          InputLabelProps={{ shrink: false }}
                          inputProps={{ maxLength: 15 }}
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                        />
                      </Box>
                      <ErrorMessage name="password" component="div" style={{fontSize:'12px', color: "#DC2626",marginTop:'5px',fontFamily:'Roboto', fontWeight:400  }} />
                    </Box>
                    <Box display={'flex'} flexDirection={'column'} style={{ width:'100%' }}>
                        <Box style={{width:'100%', display:'flex', justifyContent:'flex-end'}}>
                        <Typography data-test-id="forgotpass" onClick={this.navigateToForgotPassword}  
                        style={{ cursor:'pointer',textTransform:'none', fontWeight: 600, fontSize: '16px', color: '#013D4F', textAlign: 'end', marginTop: '15px', marginBottom: '16px' }}>Forgot Password?</Typography>
                      </Box>

                      <Buttondb data-test-id="loginhere" className="loginhere"  type="submit" disabled={!values.email || !values.password || Boolean(errors.email) || Boolean(errors.password)} variant="contained" style={{width:'100%', height: '56px' }}>
                        <Typography style={{textTransform: 'none', fontSize:'16px', fontWeight:700}}>Login to Vitu</Typography>
                      </Buttondb>
                    </Box>
                  </Form>
                )}
            </Formik>

          <GoogleLogin
          data-test-id="Gloginbutton"
            clientId="441584058688-05ov470a7n99j7884oatccmqk4q5f2r8.apps.googleusercontent.com"
            render={renderProps => (
              <Button className="googleloginbutton" onClick={renderProps.onClick} disabled={renderProps.disabled} style={{ width: '100%', height: '56px', marginTop: '-10px', display: 'flex', justifyContent: 'center', border: '1px solid #F0FAFA' }}>
              <img src={googleimgicon} alt="icon" />
              <Typography style={{ textTransform: 'none', marginTop: '5px', marginLeft: '10px', fontSize: '16px', fontWeight: 600, color: '#013D4F' }}>Login with Google</Typography>
            </Button>
            )}
            onSuccess={this.responseGoogle}
            onFailure={this.responseGoogle}
            cookiePolicy={'single_host_origin'}
            />

            <Box style={{width:'100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
              <Typography style={{ fontWeight: 400, fontSize: '16px', color: '#4B4C4B' }}>Don’t have an account yet?</Typography>
              <Button variant="text" onClick={this.goToSignUp} data-test-id="gotosignup">
                <Typography style={{ textTransform: 'none', fontWeight: 550, fontSize: '16px', marginTop: '5px', color: '#013D4F' }}>Create a Vitu account</Typography>
              </Button>
            </Box>
          </Box>

  
        </Box>
      </LeftBox>
                 

      <RightBox width='33.4%' style={{position:'fixed', height:'100vh', right:'0'}}>
        <img src={grouppng} height="100%" width='100%'>
        </img>
      </RightBox>    
    
  </Box>
       // Customizable Area End
    );
  }
}
