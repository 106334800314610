import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {getName} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
navigation: any;
id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    anchorEl: null | HTMLElement;
    profilePhoto:string | null |undefined;
    initials:string | null;
    fullName:string;
    isOpenLogoutModal:boolean;
    role:string;
    description:string | undefined;
    isOpen: boolean,
    isExpanded : boolean,
    showMore : boolean,
    selectedDeals: number[];
    searchTerm : string;
    loading : boolean;
    dealsCount : number;
    // Customizable Area End
}

interface SS {
id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class HamburgerMenuFullController extends BlockComponent<
Props,
  S,
  SS
  > {
    // Customizable Area Start
    getContactInfoCallIdFull: string = "";
    testCallId: string="";
    getDealsCountCallId : string = "";
    // Customizable Area End

    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        // Customizable Area Start
        getName(MessageEnum.RestAPIResponceMessage)

        // Customizable Area End
      ];

      this.state = {
        // Customizable Area Start
        selectedDeals: [],
        anchorEl: null,
        profilePhoto:'',
        initials:'',
        fullName:'',
        showMore : false,
        isOpenLogoutModal:false,
        role : '',
        description: '',
        isOpen : false,
        isExpanded : false,
        searchTerm : '',
        loading : true,
        dealsCount : 0,
        // Customizable Area End
      };
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

      // Customizable Area Start
      // Customizable Area End
    }

    async receive(from: string, message: Message) {
      // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )
      if (apiCallId === this.getDealsCountCallId) {
        this.setLoadingFalse()
        this.setState({
          dealsCount : responseJson?.total_deals,
        })
      }
    }
      // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        this.getContactInfo()
        this.getDealsCount()
    }

    isOpen=()=>{
      this.setState(
        {
          isOpen:!this.state.isOpen,
      }
    )
    }

    toggleExpand = () => {
      this.setState(prevState => (
        {isExpanded : !prevState.isExpanded}
      )
    )
    }

    setLoadingFalse = () => {
      this.setState({loading : false})
    }

    handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ 
        searchTerm: event.target.value 
      });
    };

      getContactInfo = async () => {
        let tokennn = await getStorageData("authToken");
        const Webheader = {"token" : tokennn};

        const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getContactInfoCallIdFull = 
        WebrequestMessage.messageId;
        WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.getContactInfoAPIEndpoint);
        WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(Webheader));
        WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.httpGetMethod);
        runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage
        );
      }

      getDealsCount=async()=>{
        let tokenn = await getStorageData("authToken");
        const Webheader = {"token": tokenn};
        const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getDealsCountCallId = WebrequestMessage.messageId;
        WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.dealsCountGetEndPoint)
        WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(Webheader))
        WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod);
        runEngine.sendMessage(WebrequestMessage.id, 
          WebrequestMessage
        );
      }
    // Customizable Area End
  }