Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.apiFormDataContentType = "multipart/form-data"
exports.getApiMethodType = "GET"
exports.postApiMethodType = "POST"
exports.putApiMethodType = "PUT"

exports.loginApiEndPoint = "bx_block_login/logins";
exports.createTemplateApiEndpoint = "bx_block_multilevelapproval/multi_level_approvals"
exports.editTemplateApiEndpoint = "bx_block_multilevelapproval/multi_level_approvals"
exports.fetchTemplatesApiEndpoint = "bx_block_multilevelapproval/multi_level_approvals"
exports.fetchTemplatesDetailsApiEndpoint = "bx_block_multilevelapproval/multi_level_approvals"
exports.changeTemplateStatusApiEndpoint = "bx_block_multilevelapproval/multi_level_approvals/approve_or_reject_with_comment"
exports.completeTheDealEndPoint = "bx_block_posts/deals/291"
exports.postDealerReviewEndPoint = "bx_block_reviews/service_provider_reviews"
exports.postSpReviewEndPoint = "bx_block_reviews/dealer_review"

exports.loginScreenTitle = "Login Screen";
exports.emailTextInputPlaceholder = "Enter your email address";
exports.passwordTextInputPlaceholder = "Enter your password";
exports.btnLoginTitle = "Login";
exports.errorPasswordText = "Please enter your password"

exports.dashboardTitle = "Dashboard";
exports.somethingWentWrong = "Something went wrong"

exports.addTempleteScreenTitle = "Add Template";
exports.editTempleteScreenTitle = "Edit Template";
exports.submit = "Submit"
exports.labelTemplateName="Template Name";
exports.placeholderTemplateName="Enter Your Template Name";
exports.labelDescription="Description";
exports.placeholderDescription="Description";
exports.errorTextTemplateName="Template Name is Required";
exports.errorTextDescription = "Description is Required";

exports.templateDetailsTitle = "Templates Details"
exports.labelConfirmation = "Please provide your confirmation"
exports.labelTemplateID = "Template ID"
exports.labelStatus = "Status"
exports.labelComment = "Comment"
exports.placeholderComment = "Enter your comment"
exports.btnApprove = "Approve"
exports.btnReject = "Reject"

exports.userRoleList = [
  {name:"level-0", value:"level-0"},
  {name:"level-1", value:"level-1"},
  {name:"level-2", value:"level-2"},
]
exports.statusList = [
  {name:"Pending", value:"pending"},
  {name:"Partial Approved", value:"partially_approved"},
  {name:"Approved", value:"approved"},
  {name:"Rejected", value:"rejected"}
]

// Customizable Area End
