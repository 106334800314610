import React from "react";
// Customizable Area Start
import { Avatar, Box, Button, Divider, Menu, MenuItem, Modal, Typography, createTheme, styled } from "@material-ui/core";
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { MailOutlineOutlined } from "@material-ui/icons";
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import CloseIcon from '@material-ui/icons/Close';

const theme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff",
      },
    },
  });

// Customizable Area End

import ProfileDropdownController, {
  Props,
} from "./ProfileDropdownController";
import { subscription } from "./assets";

export default class ProfileDropdown extends ProfileDropdownController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  logoutModal = ()=>{
    return(
      <Modal
      open={this.state.isOpenLogoutModal}
      onClose={this.toggleLogoutModalNew}
      aria-describedby="simple-modal-description"
      aria-labelledby="simple-modal-title"
      style={{  display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
   }}
    >
      <StyledModalBox >
        <Box display={'flex'} style={{width:'545px'}} justifyContent={'end'} >
          <Button onClick={this.toggleLogoutModalNew} data-test-id="closebtn"><CloseIcon/></Button>
        </Box>
  
       <Box>
        <Box><Typography style={{color:'#013D4F', fontWeight:500, fontSize:'24px',marginBottom:'12px', fontFamily:'Roboto'}}>Log Out</Typography></Box>
        <Typography style={{color:'#4B4C4B',fontSize:'18px',fontWeight:300, fontFamily:'Gotham light'}}>Are you sure you want to log out?</Typography>
       </Box>
  
       <Divider style={{ height:'2px', width:'113%', marginLeft:'-33px',backgroundColor:'#F0FAFA', marginTop:'30px'}}/>
       
       <Box style={{display:'flex', marginTop:'20px',justifyContent:'end', }}>
        <Button data-test-id="cancellogout" variant="outlined" onClick={this.toggleLogoutModalNew} style={{height:'56px', border:'1px solid #013D4F', width:'156px', marginRight:'15px', borderRadius:'8px'}}>
          <Typography style={{color:'#013D4F',fontSize:'16px', fontWeight:700, textTransform:'none',fontFamily:'Roboto'}}>
            Cancel
          </Typography>
        </Button>
        <Button data-test-id="finallogout" variant="contained" onClick={this.gotoLogout} style={{ width:'156px',height:'56px', backgroundColor:'#4FC8EC', borderRadius:'8px'}}>
          <Typography style={{color:'white', textTransform:'none', fontSize:'16px', fontFamily:'Roboto', fontWeight:700, }}>
            Log Out
          </Typography>
        </Button>
       </Box>
      </StyledModalBox>
  </Modal>
    )
  }

  profileMenuDropdownn=()=>{
    return(
      <Menu
      id="simple-menu"
      data-test-id="profileMenuDropdown"
      anchorEl={this.state.anchorEl}
      open={Boolean(this.state.anchorEl)}
      keepMounted
      onClose={this.handleCloseMenu}
      getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom', 
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top', 
      horizontal: 'center',
    }}
      PaperProps={{
        style: {
          padding: '10px 0', 
          borderRadius: 8, 
          width: '270px', 
        },
      }}
    >
     
      <Box display="flex" alignItems="center" pt={0}  p={2}>
        <Avatar
          style={{
           height:'57px',
           width:'57px', 
           color: '#013D4F',
           backgroundColor: 'rgb(240, 250, 250)',
           }} 
           src={this.state.profilePhoto ? this.state.profilePhoto : ''}>
          {this.state.initials}
        </Avatar>
  
        <Box ml={2}>
          <Typography style={{ fontSize:'20px', fontWeight:400, color:'#4B4C4B', fontFamily:'Roboto' }}>
            {this.state.fullName}
          </Typography>
          <Typography style={{ fontWeight: 400, fontSize:'16px',  fontFamily:'Roboto', color:'#C5CBC9', }}>
            {this.state.description}
          </Typography>
        </Box>
      </Box>
  
    <Box style={{gap:'15px',marginTop:'7px'}}>
      <MenuItem data-test-id="editprofile" onClick={this.gotoEditProfile} style={{gap:'15px'}} >
        <PersonOutlineOutlinedIcon style={{color:'#013D4F'}}/>
        <Typography style={{fontWeight:400, color:'#4B4C4B', fontSize:'16px',fontFamily:'Roboto'}}>Edit Profile</Typography>
      </MenuItem>
      
      {
        (this.state.role === ('dealer_admin') || this.state.role === ('service_provider_admin')) && 
        (
          <MenuItem data-test-id="editprofile" onClick={this.gotoEditProfile} style={{marginTop:'15px', gap:'15px'}} >
            <img src={subscription} style={{width:'24px', height:'24px'}}/>
            <Typography style={{ fontWeight: 400, color: '#4B4C4B', fontSize: '16px', fontFamily: 'Roboto' }}>My Subscription Plan</Typography>
          </MenuItem>
        )
      }
      <MenuItem onClick={this.gotoContactUs} data-test-id="contactusprofile" style={{marginTop:'15px', gap:'15px'}} >
        <MailOutlineOutlined style={{color:'#013D4F'}}/>
        <Typography style={{fontSize:'16px',fontFamily:'Roboto', color:'#4B4C4B',  fontWeight:400}} >Contact Us</Typography>
      </MenuItem>
  
      <Divider style={{marginTop:'10px',  marginLeft:'20px', width:'230px' , background:'rgba(240, 250, 250, 1)'}}/>
  
      <MenuItem data-test-id="logoutprofile"  onClick={this.toggleLogoutModalNew} style={{gap:'15px',marginTop:'25px'}}>
        <ExitToAppOutlinedIcon style={{color:'#013D4F',transform: 'rotateY(180deg)'}}/>
        <Typography style={{fontWeight:400, fontSize:'16px', color:'#4B4C4B', fontFamily:'Roboto'}}>Log Out</Typography>
      </MenuItem>
    </Box>
    </Menu>
    )
  }
  // Customizable Area End

  render() {
    return (
        // Customizable Area Start
        <Box style={{width:'40px', height:'40px', display:'flex', justifyContent:'center', alignItems:'center'}}>
            <Avatar
              className="avatar"
              variant="square"
              onClick={(event)=>this.handleOpenMenu(event)}
              src={this.state.profilePhoto ? this.state.profilePhoto : ''}
              style={{
                width: "102%",
                height: "102%",
                backgroundColor: 'rgb(240, 250, 250)', 
                borderRadius: "24px",   
                color: '#013D4F'
              }}
            >
               {this.state.initials}
            </Avatar>
            {this.profileMenuDropdownn()}
            {this.logoutModal()}
        </Box>
        // Customizable Area End
        );
  }
}

// Customizable Area Start
const StyledModalBox = styled(Box)({
    width: '512px',
    border: '1px solid #FFFFFF',
    boxShadow: theme.shadows[5],
    backgroundColor:'#FFFFFF',
    padding: theme.spacing(2, 4, 3),
    borderRadius:'8px',
  })
// Customizable Area End
