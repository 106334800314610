import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Alert } from "react-native";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  templateName: string;
  description: string;
  isFromEdit: boolean;
  templateID: number;
  token: string,
  initials: string,
  likeButton : boolean | string,
  toggleNoti : boolean,
  id : number | null,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class MultilevelTemplateController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  templeteAPIID: string = '';
  postSpReviewCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      templateName: '',
      description: '',
      isFromEdit:false,
      templateID: 0,
      token:'',
      initials: '',
      likeButton : "",
      toggleNoti : false,
      id : null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const navigationPayload = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      if(navigationPayload.isFromEdit){
        this.setState({
          templateName: navigationPayload.item.attributes.name,
          description: navigationPayload.item.attributes.description,
          isFromEdit: navigationPayload.isFromEdit,
          templateID: navigationPayload.item.attributes.id,
        });
      }
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.templeteAPIID) {
      if(responseJson != null){
        this.props.navigation.pop()
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

navigateToContactUs = ()=>{
  const contactUsTxtData =new Message( getName(MessageEnum.NavigationMessage));
  contactUsTxtData.addData(getName( MessageEnum.NavigationTargetMessage ),"Contactus" );
  contactUsTxtData.addData(getName(MessageEnum.NavigationPropsMessage ),this.props );
  this.send(contactUsTxtData);
}

goToDealDashboard = ()=>{
  const dealDashboard =new Message(
    getName(MessageEnum.NavigationMessage)
  );
  dealDashboard.addData(
    getName( 
      MessageEnum.NavigationTargetMessage 
    ),
    "ProjectTemplatesDealDashboard" 
  );
  dealDashboard.addData(getName(MessageEnum.NavigationPropsMessage ),
    this.props );
  this.send(dealDashboard );
}

toggleScreen = () => {
  this.setState({
    toggleNoti : !this.state.toggleNoti
  })
}

  handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({
      likeButton: value === "true",
    });
  };

  async componentDidMount() {
    const userDetails = await getStorageData("userDetails", true);
    this.setState({token: userDetails.token});
 }
 async componentDidUpdate() {
   console.log(this.state.likeButton.toString().length, "parth")
 }
  onTemplateNameChange(text:string){
    this.setState({templateName:text})
  }

  goBackPress(){
    this.props.navigation.pop()
  }

  onDescriptionChange(text:string){
    this.setState({description:text})
  }

  async onSaveTamplete(){
    if(!this.state.templateName){
      Alert.alert("",configJSON.errorTextTemplateName);
      return false
    }

    if(!this.state.description){
      Alert.alert("",configJSON.errorTextDescription);
      return false
    }
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token
    };

    const body = {
      template:{
        name: this.state.templateName,
        description: this.state.description
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.templeteAPIID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.isFromEdit ? `${configJSON.editTemplateApiEndpoint}/${this.state.templateID}` : configJSON.createTemplateApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.isFromEdit ? configJSON.putApiMethodType : configJSON.postApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  postSpReviewRequest = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let tokenn = await getStorageData("authToken");
      const rrrr = {
        rating: this.state.likeButton,
      }
      const myData = {
        dealer_review: rrrr,
        service_provider_information_id : 291,
      };
  
      const headers = {
        "Content-Type": "application/json",
        "token" : tokenn
      };
  
      this.postSpReviewCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postSpReviewEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(myData)
      );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
