import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area   Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string,
  dropdownData: any[];
  data: any[],
  closeDrawer: boolean;
  selectedCardService: any;
  searchValue: any,
  sortType: string,
  currentPage: number,
  totalpages: number,
  checked: any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ServiceProviderDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getFetchData = "";
  advancedsearchApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      data: [],
      closeDrawer: false,
      selectedCardService: [],
      searchValue: '',
      sortType: "",
      dropdownData: [],
      currentPage: 1,
      totalpages: 0,
      checked: {}
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.setState({ currentPage: 1 })
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.advancedsearchApiCallId = requestMessage.messageId;

    const stateCode = localStorage.getItem("state_code")

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getServiceProviderListApiEndPoint}?state_code=${stateCode}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Received", message);
      const authToken = localStorage.getItem("authToken")
      this.setState({ token: authToken || '' })
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (!responseJson?.errors && responseJson?.service_providers?.data && responseJson?.service_providers?.data?.length > 0) {
        this.setState({ data: responseJson && responseJson?.service_providers && responseJson?.service_providers?.data, totalpages: responseJson?.total_pages });
      } else {
        this.setState({data: [], currentPage: 0, totalpages: 0})
      } 
    }
    // Customizable Area End
  }

  // Customizable Area Start
  selectServiceHandler = (provider: any) => {
    const finalData = this.state.data && this.state.data.map((item) => {
      if (item.id === provider.id) {
        return {
          ...item,
          buttonIndex: provider.buttonIndex
        }
      } else {
        return item
      }
    }
    )
    this.setState({ 
      data: finalData, 
      selectedCardService: [...this.state.selectedCardService, provider] })
  }

  closeDrawerHandler = () => {
    this.setState({ closeDrawer: !this.state.closeDrawer })
  }

  removeSelectedServiceHandler = (provider: any) => {
    const data = this.state.selectedCardService && this.state.selectedCardService.filter((item: any) => item.id !== provider.id)
    const finalData = this.state.data && this.state.data.map((item) => {
      if (provider.id === item.id) {
        const { buttonIndex, ...rest } = item
        return {
          ...rest
        }
      } else {
        return item
      }
    }
    )
    this.setState({ data: finalData, selectedCardService: data })
  }

  removeSelectedCardsFromServiceBlock = (cardItem: any) => {
    const finalData = this.state.selectedCardService && this.state.selectedCardService.filter((item: any) => item.id !== cardItem.id)
    const data = this.state.data && this.state.data.map((item) => {
      if (cardItem.id === item.id) {
        const { buttonIndex, ...rest } = item
        return rest
      } else {
        return item
      }
    }
    )
    this.setState({ data: data, selectedCardService: finalData })
  }

  getServiceProviderList = (searchValue: string, sortType: string, multipleServiceSort: any, page: number) => {
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.advancedsearchApiCallId = requestMessage.messageId;

    const stateCode = localStorage.getItem("state_code")

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getServiceProviderListApiEndPoint}?state_code=${stateCode}&search=${searchValue}&sort=${sortType}&provider_services=${multipleServiceSort}&page=${page}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  searchServiceListHandler = (event: any, type: string) => {
    const value = event.target.value as string;
    const { searchValue, sortType, dropdownData, currentPage } = this.state;
    if (type === 'sort') {
      this.setState({ sortType: event.target.value })
      this.getServiceProviderList(searchValue, value, dropdownData, currentPage);
    } else if (type === 'search') {
      this.setState({ searchValue: event.target.value })
      this.getServiceProviderList(value, sortType, dropdownData, currentPage);
    }
  }

  handleChangeDropdown = (event: any) => {
    const { searchValue, sortType, currentPage } = this.state
    const value: any = event.target.value;
    this.setState({
      dropdownData: typeof value === 'string' ? value.split(',') : value
    });

    this.getServiceProviderList(searchValue, sortType, value, currentPage)

  };

  handlePageChange = (event: any, value: any) => {
    const { searchValue, sortType, dropdownData } = this.state
    this.setState({ currentPage: value });
    this.getServiceProviderList(searchValue, sortType, dropdownData, value)
  };

  handleChecked = (key: any) => {
    if (this.state.checked.hasOwnProperty(key)) {
      const {[key]: removed, ...rest } = this.state.checked
      this.setState({ checked: rest })
    } else {
      this.setState({ checked: { ...this.state.checked, [key]: key } })
    }
  }

  navigateToScreen = (screen: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), screen);
    this.send(msg);
  }

  backNavigation = () => {
    this.navigateToScreen('ProjectTemplatesDealDashboard');
  }
  // Customizable Area End
}