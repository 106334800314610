import React from "react";
// Customizable Area Start
import { Box,
  Button,
  Typography,
  styled,
  createTheme,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  AppBar,
  Tabs,
  Tab,
  TablePagination,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemAvatar,
  Avatar,
  Select,
  FormControl,
  MenuItem,
  CircularProgress
 } from "@material-ui/core";
 import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
 import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
 import SearchIcon from '@material-ui/icons/Search';
 import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
 import Chart from 'react-apexcharts';
 import { ApexOptions } from 'apexcharts';
 import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
 import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined';
 import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
 import CreateDealButton from "../../postcreation/src/CreateDealButton.web";
 import Pushnotifications from "../../pushnotifications/src/Pushnotifications.web";
 import HamburgerMenu from "../../customisableuserprofiles/src/HamburgerMenu.web";
 import ProfileDropdown from "../../customisableuserprofiles/src/ProfileDropdown.web";



 interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import BlockController, {
  Props,
} from "./HomeDashboardController";

export default class Block extends BlockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  ChartData = [
    { label: 'In Progress', count: 462, percentage: '50%', color: '#8fc9f7' },
    { label: 'Issues Sent to Dealer', count: 126, percentage: '15%', color: '#ffd700' },
    { label: 'Resolved, In Dealer Review', count: 168, percentage: '20%', color: '#d3d3d3' },
    { label: 'Dealer Flags Incomplete', count: 42, percentage: '5%', color: '#f08080' },
    { label: 'Completed', count: 42, percentage: '5%', color: '#8fc9f7' },
  ];

Documents = [
    { id: '#203193', customer: 'Smith', title: 'MV-1 Motor Vehicle Title Application.pdf', date: '2 days ago', provider: 'Blue Tree Service' },
    { id: '#203193', customer: 'Hoover', title: 'MV-1 Motor Vehicle Title Application.pdf', date: '2 days ago', provider: 'WheelsUp' },
    { id: '#943021', customer: 'Arthur', title: 'Fee Quote.pdf', date: '5 days ago', provider: 'Smart Lock Solutions' },
    { id: '#943021', customer: 'Arthur', title: 'Forms Checklist.pdf', date: '5 days ago', provider: 'Smart Lock Solutions' },
  ];


 TabPanel(props : TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TableDealerOne = ()=>{
    return (
        <TableContainer component={Box} style={{ width:'105%',  overflowX: 'auto', marginLeft:'-25px'}}>
        <Box style={{overflowX: 'auto', width: '100%',  }}>
          <Table  aria-label="sticky table" stickyHeader style={{ overflowX: 'auto'}}>
            <TableHead>
            <TableRow>
              <TabCellHeader style={{borderLeft:'none'}}> Deal ID</TabCellHeader>
              <TabCellHeader> Customer's Last Name </TabCellHeader>
              <TabCellHeader>Dealership </TabCellHeader>
              <TabCellHeader> States</TabCellHeader>
              <TabCellHeader>Date Created </TabCellHeader>
              <TabCellHeader> Date Accepted</TabCellHeader>
              <TabCellHeader style={{borderRight:'none'}} > Service</TabCellHeader>
            </TableRow>
            </TableHead>
          <TableBody>

            {this.state.tableDataOngoingDealer.map((deal, index) => (
              <StyledTableRow className="tablehover" key={index}>
                <TabCells style={{borderLeft:'none'}}>{deal.id}</TabCells>
                <TabCells> { deal.customerLastName } </TabCells>
                <TabCells> { deal.dealership} </TabCells>
                <TabCells> { deal.states } </TabCells>

                <TabCells> {deal.dateCreated }</TabCells>
                <TabCells> { deal.dateAccepted }</TabCells>
                <TabCells  style={{borderRight:'none'}}>{deal.service} </TabCells>
              </StyledTableRow>
            ))
            }
            </TableBody>
          </Table>
          </Box>
      </TableContainer>
    )
  }

  TableDealerOneTabtwo = ()=>{
    return (
        <TableContainer component={Box} style={{ width:'105%', marginLeft:'-25px',
         overflowX: 'auto'}}>
        <Box style={{overflowX: 'auto', width: '100%', }}>
          <Table  aria-label="sticky table" stickyHeader style={{ overflowX: 'auto'}}>
            <TableHead>
            <TableRow>
              < TabCellHeader style={{borderLeft:'none'}}> Deal ID</TabCellHeader>
               <TabCellHeader> Customer's Last Name </TabCellHeader>
              < TabCellHeader>Dealership </TabCellHeader>
               <TabCellHeader> States</TabCellHeader>
              < TabCellHeader>Date Sent </TabCellHeader>
               <TabCellHeader style={{borderRight:'none'}} >Service</TabCellHeader>
            </TableRow>
            </TableHead>
          <TableBody>
            {this.state.tableDataOngoingDealer.map((deal, index) => (
              <StyledTableRow className="tablehover" key={index }>
                <TabCells style={{borderLeft:'none'}}>{deal.id}</TabCells>
                <TabCells> {deal.customerLastName } </TabCells>
                <TabCells> { deal.dealership} </TabCells >
                <TabCells> { deal.states } </ TabCells>
                <TabCells>  {deal.dateSent }</TabCells>
                <TabCells  style={{borderRight:'none'}}>{deal.service} </TabCells>
              </StyledTableRow>
            )
          )
            }
            </TableBody>
          </Table>
          </Box>
      </TableContainer>
    )
  }

  TableServiceProviderOne = ()=>{
    return (
     this.state.totaldeals>0 ?
        <TableContainer component={Box} style={{ overflowX: 'auto', width:'100%', }}>
        <Box style={{overflowX: 'auto', width: '100%'}}>
          <Table  aria-label="sticky table" stickyHeader style={{ overflowX: 'auto'}}>
            <TableHead>
            <TableRow>
              <TabCellHeader style={{borderLeft:'none', paddingLeft:'20px'}}> Dealership</TabCellHeader>
              <TabCellHeader> States </TabCellHeader>
              <TabCellHeader>Service </TabCellHeader>
              <TabCellHeader>Payout </TabCellHeader>
              <TabCellHeader> Date Received</TabCellHeader>
              <TabCellHeader style={{borderRight:'none'}}> Actions</TabCellHeader>
            </TableRow>
            </TableHead>
          <TableBody>

          {this.state.tablePendingDataSp.map((deal, index) => (
            <StyledTableRow className="tablehover" key={index} >
              <TabCells style={{borderLeft:'none', paddingLeft:'20px'}}> {deal.dealership} </TabCells>
              <TabCells> {deal.states} </TabCells>
              <TabCells> {deal.service}  </TabCells>
              <TabCells> {deal.payout}</TabCells>
              <TabCells> {deal.dateReceived} </TabCells>
              <TabCells style={{borderRight:'none'}}> 
                <Button><Typography style={{fontSize:'14px', color:'#26BBB3', fontWeight:700, fontFamily:'Roboto', textTransform:'none'}}>Accept</Typography></Button>
                <Button><Typography  style={{fontSize:'14px',  fontWeight:700, fontFamily:'Roboto',textTransform:'none',color:'#C43937',}}>Reject</Typography></Button>
              </TabCells>
            </StyledTableRow>
          ))}

            </TableBody>
        </Table>
        </Box>
    </TableContainer>
    : 
    <Box style={{width:'100%', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', height:'40%'}}>
      <Typography style={{fontFamily:'Gotham', color:'#C5CBC9', fontWeight:300, fontSize:'20px'}}>No deals added yet</Typography>
    </Box>
    )
  }

  TableDealerTwo = ()=>{
    return (
      <>
      <Box style={{display:'flex', justifyContent:'space-between'}}>
      <Box>
          <Typography style={{color:'#013D4F', fontFamily:'Gotham', fontWeight:400, fontSize:'28px'}}>Drafts</Typography>
          <Typography style={{color:'#4B4C4B', fontFamily:'Roboto', fontWeight:400, fontSize:'16px'}}>Pick back up where you left off on creating these deals</Typography>
      </Box>
      
      <Button variant="outlined" style={{border:'1px solid #013D4F', height:'44px', marginTop:'10px', borderRadius:'8px'}} onClick={this.gotoAlldeals}>
          <Typography style={{color:'#013D4F', fontFamily:'Roboto', textTransform:'none', fontWeight:700, fontSize:'16px'}}>View All</Typography>
          <ArrowForwardIosIcon style={{color:'#013D4F',height:'14px' }}/>
      </Button>
    </Box>
    {this.state.loading ? (
       <Box
        display="flex"
         justifyContent="center"
        alignItems="center"
        
         >
          <CircularProgress />
          </Box>
         ) :(
        <TableContainer component={Box} style={{width:'100%',overflowX: 'auto',}} >
        <Box style={{width: '100%',overflowX: 'auto'}}>
        <Table stickyHeader aria-label="sticky table" style={{overflowX: 'auto',}}>
          <TableHead>
            <TableRow>
                <TabCellHeader style={{borderLeft:'none',}}> Deal ID </TabCellHeader>
                <TabCellHeader > Customer's Last Name </TabCellHeader>
                <TabCellHeader > Dealership</TabCellHeader >
                <TabCellHeader>States </TabCellHeader >
                <TabCellHeader> Date Created</TabCellHeader>
                <TabCellHeader> Date Accepted </TabCellHeader>
                <TabCellHeader style={{borderRight:'none'}}>Actions</TabCellHeader>
            </TableRow>
            </TableHead>
            <TableBody >
              {this.state.tableDataDraftDealer.map((deal,index) =>(
              <StyledTableRow key={index} className="tablehover" >
                  <TabCells style={{borderLeft:'none'}}>{deal.id}</TabCells>
                  <TabCells>{ deal.customerLastName}</TabCells >
                  <TabCells >{ deal.dealership}</TabCells>
                  <TabCells> {deal.states}</TabCells>
                  <TabCells >{deal.dateCreated}</TabCells >
                  <TabCells>{ deal.dateAccepted }</TabCells>
                  <TabCells style={{borderRight:'none'}}>
                      <Box style={{display:'flex', alignItems:'center'}}>
                          <Typography style={{fontFamily:'Roboto', color:'#4FC8EC', fontSize:'16px', fontWeight:700}}>Continue </Typography>
                          <span><ArrowForwardIosIcon style={{height:'14px', color:'#4FC8EC'}}/></span>
                      </Box>
                      
                  </TabCells>
              </StyledTableRow>
              ))
              }
            </TableBody>
        </Table>
        </Box>
    </TableContainer>
    )}
    </>
    )
  }


  SpTableTwo = ()=>{
    return(
      this.state.totaldeals>0 ? 
      <TableContainer component={Box} style={{overflowX:'auto', width:'100%'}} >
      <Box style={{ width: '100%', overflowX: 'auto'}}>
        <Table stickyHeader aria-label="sticky table" style={{overflowX:'auto'}}>
            <TableHead>
              <TableRow>
                  <TabCellHeader style={{borderLeft:'none'} }> Deal ID</TabCellHeader>
                  <TabCellHeader>Customer's Last Name</TabCellHeader>
                  <TabCellHeader>Dealership</TabCellHeader>
                  <TabCellHeader>States </TabCellHeader>
                  <TabCellHeader>Date Received</TabCellHeader>
                  <TabCellHeader>Date Accepted </TabCellHeader>
                  <TabCellHeader style={{borderRight:'none'} }>Service</TabCellHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.tableDataAcceptedSp.map((deal, index) => (
                  <StyledTableRow key={index} className="tablehover">
                  <TabCells style={{borderLeft:'none'}}>{deal.id}</TabCells>
                  <TabCells>{ deal.customerLastName}</TabCells>
                  <TabCells> {deal.dealership}</TabCells>
                  <TabCells>{deal.states}</TabCells>
                  <TabCells> {deal.dateCreated}</TabCells>
                  <TabCells>{deal.dateAccepted}</TabCells>
                  <TabCells style={{borderRight:'none'}}> {deal.service}</TabCells>
                  </StyledTableRow>
              ))}
            </TableBody>
          </Table>
      </Box>
  </TableContainer>
  :
    <Box style={{width:'100%', display:'flex', flexDirection:'column',  alignItems:'center',justifyContent:'center', height:'40%'}}>
      <Typography style={{fontFamily:'Gotham', color:'#C5CBC9',  fontSize:'20px', fontWeight:300,}}>No deals added yet</Typography>
    </Box>
    )
  }

  TableServiceProviderTwo = ()=>{
    return (
      <>
      <Box style={{display:'flex', justifyContent:'space-between',marginBottom:'20px'}}>
      <Box>
          <Typography style={{color:'#013D4F', fontFamily:'Gotham', fontWeight:400, fontSize:'28px'}}>Recent Deals</Typography>
          <Typography style={{color:'#4B4C4B', fontWeight:400,fontFamily:'Roboto',  fontSize:'16px'}}>Deals you've recently worked on</Typography>
      </Box>
      
      <Button variant="outlined" style={{border:'1px solid #013D4F', height:'44px', marginTop:'10px', borderRadius:'8px', display: this.state.totaldeals>0 ? 'flex' : 'none'}} onClick={this.gotoAlldeals}>
          <Typography style={{color:'#013D4F', fontFamily:'Roboto', fontWeight:700, fontSize:'16px', textTransform:'none'}}>View All Deals</Typography>
          <ArrowForwardIosIcon style={{color:'#013D4F', height:'14px',}}/>
      </Button>
    </Box>

    {this.state.loading ? (
      <Box
       display="flex"
       justifyContent="center"
          alignItems="center">
                <CircularProgress />
          </Box>
          ): this.SpTableTwo()
    }
    </>
    )
  }

  TableDealerThree=()=>{
    return(
        <>
        <Box style={{display:'flex', justifyContent:'space-between'}}>
            <Box style={{width:'100%'}}>
                <Typography style={{color:'#013D4F', fontFamily:'Gotham', fontWeight:400, fontSize:'28px'}}>Review Service Providers </Typography>
                <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', }}>
                    <Typography style={{color:'#4B4C4B', fontFamily:'Roboto', fontWeight:400, fontSize:'16px'}}>Let us know how it was working with these Service Providers</Typography>
                    <TablePagination
                        component="div"
                        data-test-id="pagination"
                        count={this.state.totalReviewTableDeal}
                        page={this.state.page}
                        onPageChange={this.handleChangePage}
                        rowsPerPage={this.state.rowsPerPage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                        rowsPerPageOptions={[]}
                        labelDisplayedRows={({ from, to, count }) => `${this.state.page+1} of ${Math.ceil(count / this.state.rowsPerPage)}`}
                        style={{color:'#4B4C4B', fontFamily:'Roboto', fontSize:'14px', fontWeight:500}}
                    />
                </Box>
            </Box>
        </Box>
        {this.state.loadingReview ? (
          <Box display="flex" justifyContent="center" alignItems="center" >
            <CircularProgress />
          </Box>
          ) :
        <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
                <TabCellHeader style={{borderLeft:'none'}}>Deal ID</TabCellHeader>
                <TabCellHeader>Customer's Last Name</TabCellHeader>
                <TabCellHeader>Service Provider</TabCellHeader>
                <TabCellHeader>Service</TabCellHeader>
                <TabCellHeader>Date Completed</TabCellHeader>
                <TabCellHeader style={{borderRight:'none'}}>Actions</TabCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.ThirdTableDataDealer.map((row) => (
                <StyledTableRow key={row.id} >
                  <TabCells style={{borderLeft:'none'}}>{row.id} </TabCells>
                  <TabCells>{ row.customerLastName}</TabCells>
                  <TabCells >{row.provider }</TabCells>
                  <TabCells>{row.service}</TabCells>
                  <TabCells >{row.date}</TabCells>
                    <TabCells style={{borderRight:'none'}}>
                      <Box style={{display:'flex', alignItems:'center'}}>
                          <Typography style={{fontFamily:'Roboto',  fontSize:'16px',color:'#4FC8EC', fontWeight:700}}>Review Now </Typography>
                          <span><ArrowForwardIosIcon style={{color:'#4FC8EC',height:'14px',}}/></span>
                      </Box>
                    </TabCells>
                  </StyledTableRow>
              ))
              }
          </TableBody>
          </Table>
        </TableContainer>
        }
        </>
    )
  }

  TableServiceProviderThree=()=>{
    return(
        <>
        <Box style={{display:'flex',marginBottom:'20px', justifyContent:'space-between'}}>
            <Box style={{width:'100%'}}>
                <Typography style={{color:'#013D4F', fontFamily:'Gotham', fontWeight:400, fontSize:'28px'}}>Review Dealers</Typography>
                <Typography style={{color:'#4B4C4B', fontFamily:'Roboto', fontWeight:400, fontSize:'16px'}}>Let us know how it was working with these Dealers</Typography>
            </Box>
        </Box>
        {this.state.loadingReview ? (
          <Box display="flex" justifyContent="center" alignItems="center" >
            <CircularProgress />
          </Box>
          ) :
        <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
                <TabCellHeader style={{borderLeft:'none'}}>Deal ID</TabCellHeader>
                <TabCellHeader>Customer's Last Name</TabCellHeader>
                <TabCellHeader>Dealer</TabCellHeader>
                <TabCellHeader>Service</TabCellHeader>
                <TabCellHeader>Date Completed</TabCellHeader>
                <TabCellHeader style={{borderRight:'none'}}> Actions</TabCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.ThirdTableDataSp
              .map((row) => (
                <StyledTableRow key={row.id}>
                  <TabCells style={{borderLeft:'none'}}>{row.id}</TabCells>
                  <TabCells>{row.customerLastName}</TabCells>
                  <TabCells>{row.provider}</TabCells>
                  <TabCells>{row.service}</TabCells>
                  <TabCells>{row.date}</TabCells>
                  <TabCells style={{borderRight:'none'}}>
                    <Box style={{display:'flex', alignItems:'center'}}>
                        <Typography style={{fontFamily:'Roboto', color:'#4FC8EC', fontSize:'16px', fontWeight:700}}>Review Now </Typography>
                        <span><ArrowForwardIosIcon style={{height:'14px', color:'#4FC8EC'}}/></span>
                    </Box>
                  </TabCells>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      }
      </>
    )
  }

ChartListOne =()=>{
  return(
    <List style={{width:'100%'}}>
    {this.ChartData.map((item, index) => (
      <ListItem
        key={index}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: 'white',
          marginBottom: '8px',
          borderRadius: '8px',
        }}
      >
      <Box style={{display:'flex', flexDirection:'column', width:'100%'}}>
        <Box>
          <Typography style={{color:'rgba(197, 203, 201, 1)', fontFamily:'Roboto', fontWeight:'500', fontSize:'14px'}}>{item.label}</Typography>
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center', justifyContent:'space-between'}}>
          <Typography  style={{color:'rgba(75, 76, 75, 1)', fontFamily:'Roboto', fontWeight:'500', fontSize:'24px'}}>{item.count}</Typography>
          <Box style={{backgroundColor:'rgba(230, 249, 255, 1)', borderRadius:'26px', width:'50px', height:'30px'}}>
            <Typography style={{ color: item.color, textAlign:'center', fontFamily:'Roboto', fontWeight:'500', fontSize:'14px', paddingTop:'5px'}}>
              {item.percentage}
            </Typography>
          </Box>
        </Box>
      </Box>
      </ListItem>
    ))}
</List>
  )
}

 chartOptions = {
    chart: {
      type: 'donut',
    },
    labels: ['Deal 1', 'Deal 2', 'Deal 3', 'Deal 4', 'Deal 5'],
    colors: ['#004c6d', '#fcb900', '#525252', '#00bcd4', '#f06725'], 
    plotOptions: {
      pie: {
        donut: {
          size: '75%',
          labels: {
            show: true,
            total: {
              show: true,
              label: 'Deals',
              fontSize: '14px',
              color: 'rgba(197, 203, 201, 1)', 
              fontFamily:'Roboto',
              fontWeight:500,
              formatter: () => '840',
            },
          },
        },
      },
    },
    stroke: {
      show: false,  
    },
    dataLabels: {
      enabled: false  
    },
    legend: {
      show: false,  
    },
    tooltip: {
      enabled: false,  
    },
  }

  ReviewsOne=()=>{
    return(
      <>
      <Box style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
        <Box style={{display:'flex', alignItems:'center'}}>
          <Box style={{backgroundColor:'rgba(240, 250, 250, 1)', height:'25px', display:'flex', alignItems:'center'}}>
            <ThumbUpAltOutlinedIcon style={{height:'14px', color:'rgba(75, 76, 75, 1)'}}/> <span style={{fontSize:'12px', color:'rgba(75, 76, 75, 1)'}}>{this.state.likes}</span>
            <ThumbDownAltOutlinedIcon style={{height:'14px', color:'rgba(75, 76, 75, 1)', marginLeft:'10px'}}/><span style={{fontSize:'12px', color:'rgba(75, 76, 75, 1)'}}>{this.state.dislikes}</span>
          </Box>
          <Typography style={{marginLeft:'10px', fontSize:'12px', fontWeight:500, fontFamily:'Roboto', color:'rgba(75, 76, 75, 1)',textDecoration:'underline', textDecorationStyle:'dashed'}}>{this.state.totalReviews} Reviews</Typography>
        </Box>
        <Box style={{display:'flex', alignItems:'center'}}>
          <ThumbUpAltOutlinedIcon style={{height:'14px', color:'rgba(75, 76, 75, 1)'}}/>
          <Typography style={{fontSize:'12px', fontWeight:500, fontFamily:'Roboto', color:'rgba(75, 76, 75, 1)'}}>{this.getPositivePercentage(this.state.likes, this.state.totalReviews)}% Positive</Typography>
        </Box>
      </Box>

      <List>
        {this.state.reviewsReceived.map((review) => (
          <ListItem key={review.id} alignItems="flex-start" style={{ borderBottom: '1px solid #f0f0f0',padding: '20px 0',  }}>
          <Box style={{display:'flex', width:'100%',flexDirection:'column', }}>
            <Box>
              <Box style={{display:'flex'}}>
                <ListItemAvatar>
                  <Avatar variant="square" alt={review.name} src={review.avatar} style={{ width: '50px', borderRadius:'8px', height: '50px', }} />
                </ListItemAvatar>
                <ListItemText
                primary={
                  <Box display="flex" alignItems="center" justifyContent="space-between" >
                      <Box style={{display:'flex', alignItems:'center'}}>
                        <Typography style={{color:'rgba(75, 76, 75, 1)',  fontSize:'16px', fontFamily:'Roboto',fontWeight:600}}>{review.name}</Typography>
                        <span style={{backgroundColor:'rgba(240, 250, 250, 1)', borderRadius:'5px'}}><ThumbUpAltOutlinedIcon style={{ color:'rgba(75, 76, 75, 1)',height:'15px',}}/></span>
                      </Box>
                    
                      <Typography style={{color:'rgba(197, 203, 201, 1)',  fontSize:'14px',fontFamily:'Roboto', fontWeight:500}}>
                          {review.date}
                      </Typography>
                  </Box>
                }
                secondary={
                  <>
                    <Typography style={{color:'rgba(197, 203, 201, 1)', fontSize:'16px', fontWeight:500, fontFamily:'Roboto', }}>
                         {review.company}
                    </Typography>
                  </>
                }
              />
              </Box>
            </Box>
            <Box>
              <Typography style={{ marginTop: '8px', color:'rgba(75, 76, 75, 1)',  fontWeight:400, fontFamily:'Roboto',fontSize:'16px'}}>
                {review.text}
              </Typography>
            </Box>
           </Box>
        </ListItem>
        ))
        }
      </List>
      </>
    )
  }

  ReviewsTwo=()=>{
    return(
      <>
      <Box style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
        <Box style={{display:'flex', alignItems:'center'}}>
          <Box style={{backgroundColor:'rgba(240, 250, 250, 1)', height:'25px', display:'flex', alignItems:'center'}}>
            <ThumbUpAltOutlinedIcon style={{height:'14px', color:'rgba(75, 76, 75, 1)'}}/> <span style={{fontSize:'12px', color:'rgba(75, 76, 75, 1)'}}>{this.state.likesGiven}</span>
            <ThumbDownAltOutlinedIcon style={{height:'14px', color:'rgba(75, 76, 75, 1)', marginLeft:'10px'}}/><span style={{fontSize:'12px', color:'rgba(75, 76, 75, 1)'}}>{this.state.dislikesGiven}</span>
          </Box>
          <Typography style={{marginLeft:'10px', fontSize:'12px', fontWeight:500, fontFamily:'Roboto', color:'rgba(75, 76, 75, 1)',textDecoration:'underline', textDecorationStyle:'dashed'}}>{this.state.totalReviewsGiven} Reviews</Typography>
        </Box>
        <Box style={{display:'flex', alignItems:'center'}}>
          <ThumbUpAltOutlinedIcon style={{height:'14px', color:'rgba(75, 76, 75, 1)'}}/>
          <Typography style={{fontSize:'12px', fontWeight:500, fontFamily:'Roboto', color:'rgba(75, 76, 75, 1)'}}>{this.getPositivePercentage(this.state.likesGiven, this.state.totalReviewsGiven)}% Positive</Typography>
        </Box>
      </Box>

      <List>
        {this.state.reviewsGiven.map((review) => (
          <ListItem key={review.id} alignItems="flex-start" style={{ padding: '20px 0', borderBottom: '1px solid #f0f0f0' }}>
          <Box style={{display:'flex', width:'100%', flexDirection:'column'}}>
            <Box>
              <Box style={{display:'flex'}}>
                <ListItemAvatar>
                  <Avatar variant="square" alt={review.name} src={review.avatar} style={{ width: '50px', height: '50px', borderRadius:'8px' }} />
                </ListItemAvatar>
                <ListItemText
                primary={
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box style={{display:'flex', alignItems:'center'}}>
                      <Typography style={{color:'rgba(75, 76, 75, 1)', fontFamily:'Roboto', fontSize:'16px', fontWeight:600}}>{review.name}</Typography>
                      <span style={{backgroundColor:'rgba(240, 250, 250, 1)', borderRadius:'5px'}}><ThumbUpAltOutlinedIcon style={{height:'15px', color:'rgba(75, 76, 75, 1)'}}/></span>
                    </Box>
                    
                    <Typography style={{color:'rgba(197, 203, 201, 1)', fontFamily:'Roboto', fontSize:'14px', fontWeight:500}}>
                      {review.date}
                    </Typography>
                  </Box>
                }
                secondary={
                  <>
                    <Typography style={{color:'rgba(197, 203, 201, 1)', fontFamily:'Roboto', fontSize:'16px', fontWeight:500}}>
                      {review.company}
                    </Typography>
                  </>
                }
              />
              </Box>
              
              
            </Box>
            
            <Box>
              <Typography style={{ marginTop: '8px', color:'rgba(75, 76, 75, 1)', fontFamily:'Roboto', fontWeight:400, fontSize:'16px'}}>
                  {review.text}
              </Typography>
            </Box>
          </Box>
          </ListItem>
        ))}
      </List>
      </>
    )
  }


  HeaderDealSelect = ()=>{
    return(
      <FormControl
      variant="outlined"
      style={{ width: '100%', maxWidth: '200px', padding:'0', marginRight:'15px'}}
    >
      <style>
        {`
        .MuiSelect-outlined.MuiSelect-outlined{
          padding:5px;
        }
        `}
      </style>
      <CustomSelect
        className="selecttest"
        data-test-id="selectoption"
        labelId="contactus-reason-label"
        id="contactus-reason"
        value={this.state.dealer || ""}
        displayEmpty
        onChange={this.handleSelectUserDeal}
        IconComponent={ExpandMoreIcon}
        disabled={this.state.optionForDealer.length <= 1}
        style={{
          borderRadius: '12px',
          paddingLeft: '8px',
          minHeight:'45px',
          minWidth:'200px'
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              maxHeight: '300px',
              overflowY: 'auto',
              borderRadius: '8px',
            },
          },
        }}
        renderValue={(selected) => {
          const selectedDealer = this.state.optionForDealer.find(
            (option) => option.dealership_name === selected
          );
          return (
            <div style={{ display: 'flex', alignItems: 'center'}}>
              <Avatar
                src={this.findavatarsrc(selectedDealer)}
                style={{ marginRight: '8px',height:'35px', width:'35px'}}
              />
              <Typography style={{ color: 'rgba(1, 61, 79, 1)', fontSize: '14px', fontWeight: 500, fontFamily:'Roboto',
                whiteSpace: 'nowrap',     
                overflow: 'hidden',         
                textOverflow: 'ellipsis',
                maxWidth: '110px',   
              }}>
                {this.finddealername(selectedDealer)}
              </Typography>
             
            </div>
          );
        }}
      >
        {this.state.optionForDealer.map((option, index) => (
          <MenuItem key={index} data-test-id={`menu-item-${index}`} value={option.dealership_name} style={{ height: '60px',backgroundColor: this.state.dealer === option.dealership_name ? '#E8F8FD' : 'transparent', maxWidth:'190px'}}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                src={option.dealership_logo}
                style={{ marginRight: '8px' }}
              />
              <span style={{ color: 'rgba(1, 61, 79, 1)', fontSize: '16px', fontWeight: 300 }}>
                {option.dealership_name}
              </span>
            </div>
          </MenuItem>
        ))}
      </CustomSelect>
    </FormControl>
    )
  }

  headerr = () => {
    return (
      <Box sx={webStyles.header}>
        <Box sx={webStyles.headerLeftSide}>
          <HamburgerMenu navigation={this.props.navigation} id={""} data-test-id="hamburgerMenuTestId"/>
        {this.HeaderDealSelect()}
        </Box>


        <Box sx={webStyles.headerRightSide}>
          <Button variant="outlined" style={{border:'1px solid rgba(1, 61, 79, 1)',boxShadow:'none', borderRadius:'8px'}}>
            <Box style={{display:'flex', alignItems:'center'}}>
              <ChatBubbleOutlineIcon style={{color:'rgba(1, 61, 79, 1)', height:'20px'}}/>
              <Typography style={{marginLeft:'5px', color:'rgba(1, 61, 79, 1)', fontFamily:'Roboto', fontWeight:700, fontSize:'16px', textTransform:'none'}}>Chats</Typography>
            </Box>
          </Button>
          <Box style={{display: (this.state.role==='dealer_admin' || this.state.role==='dealer') ? 'block' : 'none'}}>
            <CreateDealButton fullWidth={false}  data-test-id="mainbtnn" navigation={this.props.navigation} dealerships={this.state.dealerships} savedDeals={this.state.saveddeals}>Create Deal</CreateDealButton>
          </Box>
          <Box
            data-test-id=""
            sx={webStyles.notificationsButton}
          >
            <Pushnotifications navigation={this.props.navigation} id=""/>
          </Box>

          <ProfileDropdown navigation={this.props.navigation} id={""} data-test-id="profildDropdownTestId"/>

        </Box>
      </Box>
    )
  }

  cards = ()=>{
    if(this.state.role==='dealer_admin' || this.state.role==='dealer'){
      return(
      <Grid container style={{ marginTop: '20px', gap:'10px', display:'flex', justifyContent:'space-between'}}>
        <Grid item xs={12} sm={12} md={3} lg={2} style={{ border: '1px solid rgba(240, 250, 250, 1)', padding:'15px', boxSizing: 'border-box', borderRadius:'8px'}}>
            <Box display={'flex'} alignItems="center">
                <StyledTypo>ONGOING DEALS</StyledTypo>
                <ArrowForwardIosIcon style={{ height: '11px', color: '#C5CBC9' }} />
            </Box>
            <StyledTypoDesc>{this.state.ongoing_total}</StyledTypoDesc>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={2} style={{  boxSizing: 'border-box',padding:'15px',border: '1px solid rgba(240, 250, 250, 1)', borderRadius:'8px' }}>
            <Box display={'flex'}  alignItems="center">
                <StyledTypo>IN PROGRESS DEALS</StyledTypo>
                <ArrowForwardIosIcon style={{ height: '11px', color: '#C5CBC9' }} />
            </Box>
            <StyledTypoDesc>{this.state.pending_total}</StyledTypoDesc>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={2} style={{ border: '1px solid rgba(240, 250, 250, 1)', borderRadius:'8px', boxSizing: 'border-box',padding:'15px' }}>
            <Box display={'flex'} alignItems="center">
                <StyledTypo>NO PROVIDER ASSIGNED</StyledTypo>
                <ArrowForwardIosIcon style={{ height: '11px', color: '#C5CBC9' }} />
            </Box>
            <StyledTypoDesc>50</StyledTypoDesc>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={2} style={{ padding:'15px', border: '1px solid rgba(240, 250, 250, 1)', boxSizing: 'border-box', borderRadius:'8px' }}>
            <Box display={'flex'}  alignItems="center">
                <StyledTypo>NEEDS YOUR REVIEW</StyledTypo>
                <ArrowForwardIosIcon style={{ height: '11px', color: '#C5CBC9' }} />
            </Box>
            <StyledTypoDesc>168</StyledTypoDesc>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={2} style={{ border: '1px solid rgba(240, 250, 250, 1)',boxSizing: 'border-box', borderRadius:'8px', padding:'15px' }}>
            <Box display={'flex'}   alignItems="center">
                <StyledTypo>ISSUES SENT TO DEALER</StyledTypo>
                <ArrowForwardIosIcon style={{ height: '11px', color: '#C5CBC9' }} />
            </Box>
            <StyledTypoDesc>126</StyledTypoDesc>
        </Grid>
      </Grid>
      )
    }
    else{
      return(
      <Box 
      display="flex" 
      flexWrap="wrap" 
      justifyContent="space-between" 
      marginTop="20px"
      data-test-id="spcardbox"
  >
        <Box 
            flex="1 1 150px" 
            margin="5px" 
            padding="15px"
            alignContent="center" 
            border="1px solid rgba(240, 250, 250, 1)" 
            borderRadius="8px" 
            boxSizing="border-box"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
        >
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <StyledTypo variant="h6">ACCEPTED DEALS</StyledTypo>
                <ArrowForwardIosIcon style={{ height: '11px', color: '#C5CBC9' }} />
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <StyledTypoDesc variant="body1">{this.state.accepted_total}</StyledTypoDesc>
                <Box 
                    style={{ 
                        backgroundColor: '#E6F9FF', 
                        borderRadius: '16px', 
                        height: '26px', 
                        display: 'flex', 
                        alignItems: 'center', 
                        padding: '4px 7px' 
                    }}
                >
                    <Typography style={{ color: '#013D4F', fontWeight: 500, fontSize: '12px' }}>
                        3 New Deals
                    </Typography>
                </Box>
            </Box>
        </Box>

        <Box 
            flex="1 1 150px" 
            margin="5px" 
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            padding="15px" 
            border="1px solid rgba(240, 250, 250, 1)" 
            borderRadius="8px" 
            boxSizing="border-box"
            alignContent="center"
        >
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <StyledTypo variant="h6">IN PROGRESS DEALS</StyledTypo>
                <ArrowForwardIosIcon style={{ height: '11px', color: '#C5CBC9' }} />
            </Box>
            <StyledTypoDesc variant="body1">462</StyledTypoDesc>
        </Box>

        <Box 
            flex="1 1 150px" 
            margin="5px" 
            padding="15px" 
            border="1px solid rgba(240, 250, 250, 1)" 
            alignContent="center"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            borderRadius="8px" 
            boxSizing="border-box"
        >
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <StyledTypo variant="h6">PENDING DEALS</StyledTypo>
                <ArrowForwardIosIcon style={{ height: '11px', color: '#C5CBC9' }} />
            </Box>
            <StyledTypoDesc variant="body1">{this.state.pending_total}</StyledTypoDesc>
        </Box>

        <Box 
            flex="1 1 150px" 
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            margin="5px" 
            padding="15px" 
            alignContent="center"
            border="1px solid rgba(240, 250, 250, 1)" 
            borderRadius="8px" 
            boxSizing="border-box"
        >
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <StyledTypo variant="h6">DEALER FLAGS INCOMPLETE</StyledTypo>
                <ArrowForwardIosIcon style={{ height: '11px', color: '#C5CBC9' }} />
            </Box>
            <StyledTypoDesc variant="body1">168</StyledTypoDesc>
        </Box>
      </Box>
      )
    }
  }

  dealeraccordianOne = ()=>{
    return(
      <Accordion style={{boxShadow:'none'}}>
      <AccordionSummary
      expandIcon={<ExpandMoreIcon/>}
      aria-controls="panel1a-content"
      id="panel1a-header"
      style={{boxShadow:'none'}}
      >
      <Box style={{display:'flex', flexDirection:'column', padding:'10px'}}>
          <Box style={{display:'flex', alignItems:'center'}}>
              <Typography style={{fontSize:'28px', color:'#013D4F', fontFamily:'Gotham', fontWeight:400}}>{this.getHeaderOfIssueAccordian()}</Typography>
              <InfoOutlinedIcon style={{fontSize:'28px', color:'#013D4F', marginLeft:'10px'}}/>
          </Box>
          
          <Typography style={{fontSize:'16px', color:'#4B4C4B', fontFamily:'Roboto', fontWeight:400}}>{this.getSubHeaderOfIssueAccordian()}</Typography>
      </Box>
      
      </AccordionSummary>
      <AccordionDetails style={{ maxHeight: '450px', overflowY: 'auto' }}>
          <Box style={{width:'100%'}}>
            {(this.state.role==='dealer_admin' || this.state.role==='dealer') && 
              <TextField
                  variant="outlined"
                  placeholder="Search for deal ID, customer name, provider, or last 6 of VIN..."
                  fullWidth
                  size="small"
                  style={{ marginBottom: '20px', width:'100%', backgroundColor:'rgba(241, 244, 243, 0.5)', borderRadius:'12px'}}
                  InputProps={{
                      startAdornment: <InputAdornment position="start" style={{paddingLeft:'10px'}}><SearchIcon style={{color:'rgba(152, 154, 152, 1)', height:'20px'}}/></InputAdornment>,
                      style: { borderRadius: '8px', paddingLeft:'5px', height:'52px'},
                  }}
              />
              }
              <List>
                  {this.Documents.map((doc, index) => (
                  <div key={index}>
                      <ListItem alignItems="flex-start">
                      <ListItemText
                          primary={
                          <Box>
                              <Typography  style={{ fontWeight: 500, color: 'rgba(197, 203, 201, 1)', fontFamily:'Roboto', fontSize:'14px'}}>
                              {doc.id} - {doc.customer}
                              </Typography>
                              <Typography style={{ fontWeight: 500, color: 'rgba(75, 76, 75, 1)', fontFamily:'Roboto', fontSize:'18px', marginTop:'5px'}}>
                              {doc.title}
                              </Typography>
                          </Box>
                          }
                          secondary={
                          <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:'10px'}}>
                              <Typography style={{ fontWeight: 500, color: 'rgba(197, 203, 201, 1)', fontFamily:'Roboto', fontSize:'14px'}}>
                              {doc.date}
                              </Typography>
                              <Typography style={{ fontWeight: 500, color: 'rgba(197, 203, 201, 1)', fontSize:'14px',fontFamily:'Roboto', }}>
                              {doc.provider}
                              </Typography>
                          </Box>
                          }
                      />
                      </ListItem>
                      {index < this.Documents.length - 1 && <Divider component="li" />}
                  </div>
                  ))}
              </List>
          </Box>
      </AccordionDetails>
  </Accordion>
    )
  }

  dealeraccordianTwo = ()=>{
    return( <Accordion style={{boxShadow:'none'}}>
    <AccordionSummary
    expandIcon={<ExpandMoreIcon/>}
    aria-controls="panel1a-content"
    id="panel1a-header"
    style={{boxShadow:'none'}}
    >
    <Box style={{display:'flex', padding:'10px', flexDirection:'column'}}>
        <Typography style={{fontSize:'28px', color:'#013D4F', fontFamily:'Gotham', fontWeight:400}}>Deals by Status</Typography>
        <Typography style={{fontSize:'16px', color:'#4B4C4B', fontFamily:'Roboto', fontWeight:400}}>Number of deals in each status</Typography>
    </Box>
    
    </AccordionSummary>
    <AccordionDetails style={{ maxHeight: '600px', overflowY: 'auto' }}>
        <Box style={{width:'100%'}}>
        <style>
          {`
           .apexcharts-datalabel-value{
            font-size:36px;
            font-weight:500;
            font-family:Roboto;
            margin-top:15px;
            color:rgba(75, 76, 75, 1);
          }
          `}
        </style>
        <Box style={{padding:'10%', marginTop:'-7%'}}>
          <Chart data-test-id="ApexCharts" options={this.chartOptions as ApexOptions} series={[40, 30, 15, 10, 5]} type="donut" />
        </Box>
          {(this.state.role==='dealer_admin' || this.state.role==='dealer') ? 
          <>
          <AppBar position="static" color="default" style={{ backgroundColor:'white', boxShadow:'none', marginTop:'20px', maxWidth:'390px'}}>
            <Tabs
                value={this.state.chartTabValue}
                data-test-id="tabs1"
                onChange={this.handleChangeChartTab}
                indicatorColor="primary"
                textColor="primary"
                aria-label="full width tabs example"
                classes={{
                  indicator: 'customIndicator', 
                }}
                >
                <Tab label="Service Provider Hire" {...this.a11yProps(0)} classes={{ root: 'customTab' }} style={{color:this.getTabColor(this.state.chartTabValue === 0, '#013D4F','#C5CBC9')}}/>
                <Tab label="No Service Provider" {...this.a11yProps(1)} classes={{ root: 'customTab' }} style={{color:this.getTabColor(this.state.chartTabValue === 1, '#013D4F','#C5CBC9')}}/>
            </Tabs>
        </AppBar>
        <style>
        {`
          .customTab {
            color: rgba(1, 61, 79, 1); 
            font-size: 16px;
            font-weight:500;
            font-family:Roboto;
            text-transform:none;
            min-width: auto;
          }

          .Mui-selected.customTab {
            color: rgba(1, 61, 79, 1); 
          }

          .customIndicator {
            background-color:rgba(1, 61, 79, 1);
          }
        `}
      </style>
          <this.TabPanel value={this.state.chartTabValue} index={0}>
           {this.ChartListOne()}
          </this.TabPanel>
          <this.TabPanel data-test-id="charttab" value={this.state.chartTabValue} index={1}>
            {this.ChartListOne()}
          </this.TabPanel>
          </>
        : this.ChartListOne()}
        </Box>
    </AccordionDetails>
</Accordion>)
  }

  getTableonedealercondition=()=>{
    if(this.state.totaldeals>0){
      return(
        <>
        <AppBar position="static" color="default" style={{ backgroundColor:'white', boxShadow:'none', marginTop:'20px', marginBottom:'-25px'}}>
            <Tabs
                value={this.state.tabvalue}
                data-test-id="tabs"
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                classes={{
                  indicator: 'customIndicator', 
                }}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="full width tabs example"
                >
                <Tab label="On Going" {...this.a11yProps(0)} classes={{ root: 'customTab' }} style={{paddingBottom: '25px', color:this.getTabColor(this.state.tabvalue === 0, '#013D4F','#C5CBC9')}}/>
                <Tab label="Pending Hire" {...this.a11yProps(1)} classes={{ root: 'customTab' }} style={{paddingBottom: '25px',color:this.getTabColor(this.state.tabvalue === 1, '#013D4F','#C5CBC9'), marginLeft:'20px'}}/>
            </Tabs>
        </AppBar>
        <this.TabPanel value={this.state.tabvalue} index={0}>
            {this.TableDealerOne()}
        </this.TabPanel>
        <this.TabPanel data-test-id="trackingDetailsTab" value={this.state.tabvalue} index={1}>
            {this.TableDealerOneTabtwo()}
        </this.TabPanel>
      </>
      )
    }
    else{
      return(
        <Box style={{width:'100%', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', height:'40%'}}>
          <Typography style={{fontFamily:'Gotham', color:'#C5CBC9', fontWeight:300, fontSize:'20px'}}>No deals created yet</Typography>
          <Box style={{width:'67%', marginTop:'10px'}}>
            <CreateDealButton fullWidth={true}  data-test-id="mainbtnn" navigation={this.props.navigation} dealerships={this.state.dealerships} savedDeals={this.state.saveddeals}>Create Your First Deal Now</CreateDealButton>
          </Box>
        </Box>
      )
    }
  }

  tabelOneSection = ()=>{
    if(this.state.role==='dealer_admin' || this.state.role==='dealer'){
      return(
        <>
         <Box style={{display:'flex', justifyContent:'space-between'}}>
            <Box>
              <Typography style={{color:'#013D4F', fontFamily:'Gotham', fontWeight:400, fontSize:'28px'}}>Recent Deals</Typography>
              <Typography style={{color:'#4B4C4B', fontFamily:'Roboto', fontWeight:400, fontSize:'16px'}}>Deals you have recently worked on</Typography>
            </Box>
                        
            <Button data-test-id="viewlall" variant="outlined" style={{border:'1px solid #013D4F',borderRadius:'8px', height:'44px', marginTop:'10px', display:this.state.totaldeals>0 ? 'flex': 'none'}} onClick={this.gotoAlldeals}>
              <Typography style={{color:'#013D4F', fontFamily:'Roboto', textTransform:'none', fontWeight:700, fontSize:'16px'}}>View All Deals</Typography>
              <ArrowForwardIosIcon style={{color:'#013D4F', height:'14px'}}/>
            </Button>
          </Box>

                {this.state.loading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100"
                    >
                      <CircularProgress />
                  </Box>
                    ) : this.getTableonedealercondition()
                  }
          </>
      )
    }
    else{
      return(
        <>
        <Box style={{display:'flex', justifyContent:'space-between', marginBottom:'20px'}}>
           <Box>
             <Typography style={{color:'#013D4F',fontWeight:400,  fontFamily:'Gotham', fontSize:'28px'}}>Pending Deals</Typography>
             <Typography style={{color:'#4B4C4B', fontFamily:'Roboto',  fontSize:'16px',fontWeight:400, maxWidth:'800px'}}>You have the following incoming deal requests. You may accept or reject this deal. Please bear in mind that other providers may be able to accept this deal.</Typography>
           </Box>
                       
           <Button variant="outlined" style={{border:'1px solid #013D4F',borderRadius:'8px', height:'44px', marginTop:'10px', minWidth:'180px', display: this.state.totaldeals>0 ? 'flex' : 'none'}} onClick={this.gotoAlldeals}>
             <Typography style={{color:'#013D4F', fontFamily:'Roboto', textTransform:'none', fontWeight:700, fontSize:'16px'}}>View All Pending</Typography>
             <ArrowForwardIosIcon style={{color:'#013D4F', height:'14px'}}/>
           </Button>
         </Box>
         {this.state.loading ? (
          <Box
           display="flex"
            justifyContent="center"
            alignItems="center">
                 <CircularProgress />
          </Box>
          ):
          this.TableServiceProviderOne()
        }
         </>
      )
    }
  }

  noreview=()=>{
    return(
      <Typography style={{textAlign:'center', color:'#C5CBC9', fontFamily:'Gotham', fontSize:'20px', marginTop:'30px'}}>No reviews added yet</Typography>
    )
  }

  tabelTwoSection = ()=>{
    if(this.state.role==='dealer_admin' || this.state.role==='dealer'){
      return this.TableDealerTwo()
    }
    else{
      return this.TableServiceProviderTwo()
    }
  }

  tabelThreeSection = ()=>{
    if(this.state.role==='dealer_admin' || this.state.role==='dealer'){
      return this.TableDealerThree()
    }
    else{
      return this.TableServiceProviderThree()
    }
  }

  selectAcordianOne = ()=>{
    if(this.state.role==='dealer_admin' || this.state.role==='dealer'){
      return this.dealeraccordianOne()
    }
    else{
      return this.dealeraccordianTwo()
    }
  }

  selectAcordianTwo = ()=>{
    if(this.state.role==='dealer_admin' || this.state.role==='dealer'){
      return this.dealeraccordianTwo()
    }
    else{
      return this.dealeraccordianOne()
    }
  }
  // Customizable Area End

  render() {
    return (
        // Customizable Area Start
      <Box height={'100vh'} style={{paddingLeft:'105px', width:'calc(100vw - 125px)'}}>
        <Box>{this.headerr()}</Box>
        <Box style={{marginTop:'20px'}}>
            <Typography style={{fontFamily:'Gotham', color:'#4B4C4B', fontSize:'36px', fontWeight:400, paddingLeft:'40px'}}>
                Welcome, {this.state.fullname}! <span>&#128075;</span>
            </Typography>
        </Box>

        <Grid container spacing={2} style={{marginTop:'15px', paddingLeft:'30px'}}>
            <Grid item xs={12} sm={8} className="left side">
                <Grid item style={{ border: '1px solid rgba(240, 250, 250, 1)', padding:'20px', borderRadius:"24px"}}>
                    <Typography style={{ fontFamily: 'Gotham', color: '#013D4F', fontSize: '28px', fontWeight: 400 }}>
                      Deal Stats
                    </Typography>
                    <Typography style={{ fontFamily: 'Roboto', color: '#4B4C4B', fontSize: '16px', fontWeight: 400 }}>
                      {this.getSubHeader()}
                    </Typography>

                  {this.cards()}
                </Grid>

                <Grid item style={{ border: '1px solid rgba(240, 250, 250, 1)', marginTop:'20px', padding:'20px', borderRadius:'24px'}}>
                   {this.tabelOneSection()}
                </Grid>

                <Grid item style={{ border: '1px solid rgba(240, 250, 250, 1)', marginTop:'20px', padding:'20px', borderRadius:'24px', display: this.gettabletwodisplay()}}>
                    {this.tabelTwoSection()}
                </Grid>

                <Grid item style={{ border: '1px solid rgba(240, 250, 250, 1)', marginTop:'20px', borderRadius:'24px', padding:'20px', display:this.state.totalReviewTableDeal>0 ? 'block' : 'none'}}>
                    {this.tabelThreeSection()}
                </Grid>
            </Grid>



            <Grid item xs={12} sm={4} className="rightside">
               <Grid item style={{border:'1px solid rgba(240, 250, 250, 1)', borderRadius:'24px', paddingTop:'10px'}}>
                   {this.selectAcordianOne()}
               </Grid>

               <Grid item style={{border:'1px solid rgba(240, 250, 250, 1)', marginTop:'10px', borderRadius:'24px'}}>
                   {this.selectAcordianTwo()}
               </Grid>

               <style>
                  {`
                    .customTabb {
                      color: rgba(1, 61, 79, 1); 
                      font-size: 16px;
                      font-family:Roboto;
                      font-weight:500;
                      text-transform:none;
                      min-width: auto;
                    }

                    .Mui-selected.customTabb {
                      color: rgba(1, 61, 79, 1); 
                    }

                    .customIndicatorr {
                      background-color:rgba(1, 61, 79, 1);
                    }
                  `}
                </style>
               <Grid item style={{border:'1px solid rgba(240, 250, 250, 1)',borderRadius:'24px', marginTop:'10px'}}>
                    <Accordion style={{boxShadow:'none'}}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{boxShadow:'none'}}
                        >
                        <Box style={{display:'flex', flexDirection:'column', padding:'10px'}}>
                            <Typography style={{fontSize:'28px', color:'#013D4F', fontFamily:'Gotham', fontWeight:400}}>Reviews</Typography>
                            <Typography style={{fontSize:'16px', color:'#4B4C4B', fontFamily:'Roboto', fontWeight:400}}>Feedback from your deals</Typography>
                        </Box>
                        
                        </AccordionSummary>
                        <AccordionDetails style={{ maxHeight: '420px', overflowY: 'auto' }}>
                            <Box style={{width:'100%'}}>
                              <AppBar position="static" color="default" style={{ backgroundColor:'white', boxShadow:'none', marginTop:'20px', maxWidth:'300px'}}>
                                <Tabs
                                    value={this.state.reviewTab}
                                    onChange={this.handleReviewTab}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    data-test-id="review1"
                                    classes={{
                                      indicator: 'customIndicatorr', 
                                    }}
                                    aria-label="full width tabs example"
                                    >
                                    <Tab label="Received" {...this.a11yProps(0)} classes={{ root: 'customTabb' }} style={{color:this.getTabColor(this.state.reviewTab === 0, '#013D4F','#C5CBC9')}}/>
                                    <Tab label="Given" {...this.a11yProps(1)} classes={{ root: 'customTabb' }} style={{color:this.getTabColor(this.state.reviewTab === 1, '#013D4F','#C5CBC9')}}/>
                                </Tabs>
                            </AppBar>
                              <this.TabPanel value={this.state.reviewTab} index={0}>
                               {(this.state.totalReviews !== null && this.state.totalReviews>0) ? this.ReviewsOne() : this.noreview()}
                              </this.TabPanel>
                              <this.TabPanel value={this.state.reviewTab} data-test-id="reviewtab" index={1}>
                              {(this.state.totalReviewsGiven !== null && this.state.totalReviewsGiven>0) ? this.ReviewsTwo() : this.noreview()}
                              </this.TabPanel>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
               </Grid>
            </Grid>
        </Grid>
      </Box>
        // Customizable Area End
        );
  }
}

// Customizable Area Start
const CustomSelect = styled(Select )({
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(240, 250, 250, 1)', 
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(240, 250, 250, 1)', 
  },
})

const StyledTableRow = styled(TableRow)({
    '&:hover': {
      backgroundColor: '#F0FAFA',
    },
  });

const TabCellHeader = styled(TableCell )({
    fontFamily: 'Roboto', color: '#C5CBC9', border:'1px solid #F0FAFA', fontWeight:700, fontSize:'14px', backgroundColor:'white'
})

const TabCells = styled(TableCell)({
    border:'1px solid #F0FAFA', fontFamily: 'Gotham',color: '#191A19', fontWeight:400, fontSize:'16px'
})

const StyledTypo = styled(Typography)({
    fontFamily:'Roboto', fontSize:'14px', color:'#989A98', fontWeight:500
})
const StyledTypoDesc = styled(Typography)({
    fontFamily:'Roboto', fontSize:'36px', color:'#4B4C4B', fontWeight:500
})

const MenuItemStyled = styled(MenuItem)({
  '&.Mui-selected': {
    backgroundColor: 'rgba(240, 250, 250, 1)',  
  },
  '&:hover': {
    backgroundColor: 'rgba(240, 250, 250, 1)',  
  },
  
})

interface Styles {
  [key: string]: any;
}
const webStyles: Styles = {
  container: {
    flexDirection: "column",
    height: "100%",
    display: "flex",
    width: "100%",
  },
  header: {
    flexWrap: "nowrap",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #F0FAFA",
    padding: "12px 16px 12px 32px",
  },

  menuButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: "24px",
    width: "24px",
    cursor: "pointer",
    border: "none",
    padding: "unset",
  },

  headerLeftSide: { display: "flex",
    flexWrap: "nowrap",
    gap: "0px",
    alignItems: "center",
  },

  logo: {width: "52px"},

  notificationsButton: {
    
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
   
    height: "24px",
    width: "24px",
    cursor: "pointer",
  },

  headerRightSide: {display: "flex",
    flexWrap: "nowrap",
   
    gap: "24px",
    alignItems: "center",
  },

  userAvatar: {
   
    height: "40px",
    width: "40px",
    borderRadius: "50%"
  },

 


  buttonText: {
    fontFamily: "Roboto, sans-serif",
    
    fontSize: "14px",
    color: "#013D4F",
    fontWeight: 500,
  },

  content: {
   
    marginLeft: "120px",
    width: "100%",
  },

  main: {
    display: "flex",
   
    position: "relative",
    flexWrap: "nowrap",
  },

}
// Customizable Area End