import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities"

export interface GetUserResponseSuccess {
  data: Data;
}
export interface Data {
  id: string;
  type: string;
  attributes: Attributes;
}
export interface Attributes {
  activated: boolean;
  country_code: string;
  email: string;
  full_phone_number: string;
  phone_number: string;
  type: string;
  full_name: string;
  created_at: string;
  updated_at: string;
  is_dealer_group: boolean;
  dealer_group_name?: null;
  role: string;
  profile_picture?: null;
  group_created_on?: null;
  is_deal_pending: boolean;
  deal_data?: null;
  solo_dealership_id: number;
}

interface GetUserError {
  errors:[
    {
      token:string;
    }
  ]
}

interface ContactUsFailure{
  errors:[
    {account:string;}
  ]
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profilePhoto:string | null |undefined;
  initials:string | null;
  reasonContactUs:string;
  contactusMessage:string;
  charCount:number;
  tellusMoreMessage:string;
  label:string;
  email:string;
  fullName:string;
  anchorEl: null | HTMLElement;
  isOpenLogoutModal:boolean;
  openToast:boolean;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  changeNameApiCallId:string="";
  getuserDataApiCallId:string="";
  sendContactUsApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
   
    this.state = {
      profilePhoto:'',
      initials:'',
      reasonContactUs:'',
      contactusMessage:'',
      charCount:0,
      tellusMoreMessage:'',
      label:'Tell us what is your message is about',
      email:'',
      fullName:'',
      anchorEl: null,
      isOpenLogoutModal:false,
      openToast:false,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.getuserDataApiCallId:
            this.handleUserData(responseJson);
            break;

          case this.sendContactUsApiCallId:
            this.handleContatUsBeResponse(responseJson);
            break;
      }
      }
    } 

    // Customizable Area End
  }

  // Customizable Area Start
  handleContatUsBeResponse=(responseJson: ContactUsFailure)=>{
    if(!responseJson.errors){
      this.setState({openToast:true})
    }
  }

  contactUsBe=async()=>{
    const newtoken = await getStorageData('authToken')

    const Webdata = {
      email: this.state.email,
      subject: this.state.reasonContactUs==='Other' ? this.state.tellusMoreMessage : this.state.reasonContactUs,
      description: this.state.contactusMessage
    };
    const Webheader = {
      "Content-Type": 'application/json',
      "token": newtoken
    };
    const Wedata = {
      data: Webdata,
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendContactUsApiCallId = WebrequestMessage.messageId;
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     'bx_block_contact_us/contacts'
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Wedata)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  toastFalse=()=>{
    this.setState({openToast:false, contactusMessage:'', reasonContactUs:'', tellusMoreMessage:'', charCount:0}
    )
  }

  toggleLogoutModal=()=>{
    this.setState({isOpenLogoutModal: !this.state.isOpenLogoutModal})
  }

  gotoLogout=()=>{
    removeStorageData('authToken')
    const newmsgg = new Message(getName(MessageEnum.NavigationMessage));
    newmsgg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    newmsgg.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailAccountLoginBlock')
    this.send(newmsgg);
  }
  gotoEditProfile=()=>{
    const routetogo = new Message(getName(MessageEnum.NavigationMessage));
    routetogo.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    routetogo.addData(getName(MessageEnum.NavigationTargetMessage), 'UserProfileBasicBlockWeb')
    this.send(routetogo);
  }

  handleOpenMenu=( event: React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
    this.setState({anchorEl:event.currentTarget})
  }

  handleCloseMenu=()=>{
    this.setState({anchorEl:null})
  }

  handleUserData=(responseJson:GetUserResponseSuccess & GetUserError)=>{
    if(!responseJson.errors){
      this.setState({profilePhoto:responseJson.data.attributes.profile_picture ,email: responseJson.data.attributes.email, fullName:responseJson.data.attributes.full_name},
        ()=>{ this.getInitials()}
      )
    }
    else{
       this.getInitials()
    }
  }

  async componentDidMount(){
    this.getUserDataFromBe();
  }

  getInitials = ()=>{
    if (this.state.fullName) {
      const nameArray = this.state.fullName.split(' ');
      const derivedInitials = nameArray.map(name => name.charAt(0)).join('').slice(0, 2).toUpperCase();
      this.setState({initials:derivedInitials})
    } else {
      this.setState({initials:null})
    }
  }

  getUserDataFromBe=async()=>{
    const newtoken = await getStorageData('authToken')
    const Webheader = {
      "Content-Type": 'application/json',
      "token": newtoken
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getuserDataApiCallId = WebrequestMessage.messageId;   
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     'account_block/accounts/get_user'
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  handleFocus = () => {
    this.setState({ label: "What's your message about?" });
  };

  handleBlur = () => {
    if (!this.state.tellusMoreMessage) {
      this.setState({ label: 'Tell us what is your message about' });
    }
  };

  handlerReasonContactus=(event: React.ChangeEvent<{value:unknown}>)=>{
    this.setState({
      reasonContactUs: event.target.value as string
    }
    )
  }
  
  getDisability=()=>{
    if(this.state.reasonContactUs==='Other'){
      return(!this.state.reasonContactUs || !this.state.tellusMoreMessage)
    }
    return(!this.state.reasonContactUs)
  }
  handleTellUsMoreMessage=(event: React.ChangeEvent<{ value: string }>)=>{
    this.setState({tellusMoreMessage: event.target.value})
  }
  handleTextAreaChangee=(event: React.ChangeEvent<{ value: string }>)=>{
    this.setState({contactusMessage:event.target.value },()=>{ 
      this.setState({charCount:this.state.contactusMessage.length })
      })
  }
  // Customizable Area End

}