// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Integrationwithapi1 from "../../blocks/integrationwithapi1/src/Integrationwithapi1";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Sorting from "../../blocks/sorting/src/Sorting";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Integrationwithapi2 from "../../blocks/integrationwithapi2/src/Integrationwithapi2";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications.web";
import Contactus from "../../blocks/contactus/src/Contactus.web";
import QuestionBank from "../../blocks/questionbank/src/QuestionBank";
import Notifications from "../../blocks/notifications/src/Notifications";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import MultilevelApproval from "../../blocks/multilevelapproval/src/MultilevelApproval.web";
import Multilevelapproval2 from "../../blocks/multilevelapproval2/src/Multilevelapproval2";
import MultilevelTemplate from "../../blocks/multilevelapproval/src/MultilevelTemplate.web";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Custombillingapi from "../../blocks/custombillingapi/src/Custombillingapi";
import CustomisableUserProfiles from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles";
import HamburgerMenu from "../../blocks/customisableuserprofiles/src/HamburgerMenu.web";
import HamburgerMenuFull from "../../blocks/customisableuserprofiles/src/HamburgerMenuFull.web";
import ProfileDropdown from "../../blocks/customisableuserprofiles/src/ProfileDropdown.web";
import CreateDealForm from "../../blocks/postcreation/src/CreateDealForm.web";
import CreateDealMain from "../../blocks/postcreation/src/CreateDealMain.web"
import ResetPassword from "../../blocks/forgot-password/src/ResetPassword.web"
import DashboardOld from "../../blocks/dashboard/src/Dashboard.web";
import ProjectTemplatesDealDashboard from "../../blocks/projecttemplates/src/ProjectTemplatesDealDashboard.web";
import OrderSummary from "../../blocks/ordermanagement/src/OrderSummary.web";
import InviteUserSignup from "../../blocks/email-account-registration/src/InviteUserSignup.web"
import OrderSummaryBlankFormFee from "../../blocks/ordermanagement/src/OrderSummaryBlankFormFee.web";
import OrderSummaryChecklistFee from "../../blocks/ordermanagement/src/OrderSummaryChecklistFee.web";
import OrderSummaryCalculateRegistrationFee from "../../blocks/ordermanagement/src/OrderSummaryCalculateRegistrationFee.web";
import OrderSummaryRegistrationFee from "../../blocks/ordermanagement/src/OrderSummaryRegistrationFee.web";
import ServiceProviderProfiles from '../../blocks/customisableuserprofiles/src/ServiceProviderProfiles.web'
import UserProfileBasicBlockWeb from "../../blocks/user-profile-basic/src/UserProfileBasicBlock.web";
import ConfirmationWeb from "../../blocks/multilevelapproval/src/Confirmation.web"
import ServiceProviderDashboard from "../../blocks/advancedsearch/src/ServiceProviderDashboard.web"
import ServiceProviderNotification from "../../blocks/pushnotifications/src/ServiceProviderNotification.web"
import Dashboard from "../../blocks/dashboard/src/HomeDashboard.web"
import AllDeals from "../../blocks/filteritems/src/Alldeals.web"

const routeMap = {
  AllDeals:{
    component:AllDeals,
    path:'/AllDeals'
  },

  DashboardOld:{
    component:DashboardOld,
    path:'/DashboardOld'
  },
  ServiceProviderNotification:{
    component:ServiceProviderNotification,
    path:'/ServiceProviderNotification'
  },
  ConfirmationWeb:{
    component:ConfirmationWeb,
    path:'/ConfirmationWeb'
  },
  UserProfileBasicBlockWeb:{
    component:UserProfileBasicBlockWeb,
    path:'/UserProfileBasicBlockWeb'
  },
  InviteUserSignup:{
    component: InviteUserSignup,
    path:'/InviteUserSignup'
  },
ResetPassword:{
  component:ResetPassword,
path:"/ResetPassword"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Integrationwithapi1:{
 component:Integrationwithapi1,
path:"/Integrationwithapi1"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Integrationwithapi2:{
 component:Integrationwithapi2,
path:"/Integrationwithapi2"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
QuestionBank:{
 component:QuestionBank,
path:"/QuestionBank"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
EmailAccountRegistration:{
  component:EmailAccountRegistration,
 path:"/EmailAccountRegistration"},
Multilevelapproval2:{
 component:Multilevelapproval2,
path:"/Multilevelapproval2"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},

CreateDealForm:{
  component:CreateDealForm,
  path:"/CreateDealForm"
}
,
CreateDealMain:{
  component:CreateDealMain,
  path:"/CreateDealMain"
},

Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Chat:{
 component:Chat,
path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Custombillingapi:{
 component:Custombillingapi,
path:"/Custombillingapi"},
CustomisableUserProfiles: {
  component: CustomisableUserProfiles,
  path:"/CustomisableUserProfiles",
},
ServiceProviderProfiles: {
  component: ServiceProviderProfiles,
  path:"/ServiceProviderProfiles",
},
Dashboard: {
  component: Dashboard,
  path: "/Dashboard",
},

  AccountGroups: {
    component: AccountGroups,
    path: "/AccountGroups"
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  SocialMediaAccountLogin: {
    component: SocialMediaAccountLogin,
    path: "/SocialMediaAccountLogin"
  },
  SocialMediaAccountRegistration: {
    component: SocialMediaAccountRegistration,
    path: "/SocialMediaAccountRegistration"
  },
  Filteritems: {
    component: Filteritems,
    path: "/Filteritems"
  },
  Filteroptions: {
    component: Filteroptions,
    path: "/Filteroptions"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  Integrationwithapi1: {
    component: Integrationwithapi1,
    path: "/Integrationwithapi1"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  Sorting: {
    component: Sorting,
    path: "/Sorting"
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: "/InvoiceBilling"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: "/Emailnotifications2"
  },
  Integrationwithapi2: {
    component: Integrationwithapi2,
    path: "/Integrationwithapi2"
  },
  Onboardingguide: {
    component: Onboardingguide,
    path: "/Onboardingguide"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  Contactus: {
    component: Contactus,
    path: "/Contactus"
  },
  QuestionBank: {
    component: QuestionBank,
    path: "/QuestionBank"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  MultilevelApproval: {
    component: MultilevelApproval,
    path: "/MultilevelApproval"
  },
  MultilevelTemplate: {
    component: MultilevelTemplate,
    path: "/MultilevelTemplate"
  },
  ProfileDropdown: {
    component: ProfileDropdown,
    path: "/ProfileDropdown"
  },
  HamburgerMenu: {
    component: HamburgerMenu,
    path: "/HamburgerMenu"
  },
  HamburgerMenuFull: {
    component: HamburgerMenuFull,
    path: "/HamburgerMenuFull"
  },
  Multilevelapproval2: {
    component: Multilevelapproval2,
    path: "/Multilevelapproval2"
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: "/AdvancedSearch"
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  Tasks: {
    component: Tasks,
    path: "/Tasks"
  },
  TaskList: {
    component: TaskList,
    path: "/TaskList"
  },
  Task: {
    component: Task,
    path: "/Task"
  },
  PostCreation: {
    component: PostCreation,
    path: "/PostCreation"
  },
  Posts: {
    component: Posts,
    path: "/Posts"
  },
  PostDetails: {
    component: PostDetails,
    path: "/PostDetails"
  },
  Reviews: {
    component: Reviews,
    path: "/Reviews"
  },
  AddReview: {
    component: AddReview,
    path: "/AddReview"
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2"
  },
  Chat: {
    component: Chat,
    path: "/Chat"
  },
  ViewChat: {
    component: ViewChat,
    path: "/ViewChat"
  },
  OrderManagement: {
    component: OrderManagement,
    path: "/OrderManagement"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  Custombillingapi: {
    component: Custombillingapi,
    path: "/Custombillingapi"
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: "/CustomisableUserProfiles",
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard",
  },
  ResetPassword: {
    component: ResetPassword,
    path: "/ResetPassword",
  },

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },

  ProjectTemplatesDealDashboard: {
    component: ProjectTemplatesDealDashboard,
    path: "/ProjectTemplatesDealDashboard"
  },

  OrderSummary: {
    component: OrderSummary,
    path: "/OrderSummary"
  },

  OrderSummaryBlankFormFee: {
    component: OrderSummaryBlankFormFee,
    path: "/OrderSummaryBlankFormFee"
  },

  OrderSummaryChecklistFee: {
    component: OrderSummaryChecklistFee,
    path: "/OrderSummaryChecklistFee"
  },

  OrderSummaryCalculateRegistrationFee: {
    component: OrderSummaryCalculateRegistrationFee,
    path: "/OrderSummaryCalculateRegistrationFee"
  },
  
  ServiceProviderDashboard: {
    component: ServiceProviderDashboard,
    path: "/ServiceProviderDashboard"
  },
  
  OrderSummaryRegistrationFee: {
    component: OrderSummaryRegistrationFee,
    path: "/OrderSummaryRegistrationFee"
  },

  MyTeam:{
    component: DashboardOld,
    path: '/MyTeam'
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

const theme = createTheme({
  typography: {
    fontFamily: [

      'Roboto',
      'Gotham',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
  },
  box: {
    fontFamily: [
      'Gotham',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
  }
});


class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />

      </View>
    );
  }
}

export default App;