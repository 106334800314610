import React from "react";
// Customizable Area Start
import ServiceProviderDashboardController, { Props } from "./ServiceProviderDashboardController";
import ExpandMore from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import { Box, Grid, Button, Typography, Card, CardContent, IconButton, Select, MenuItem, TextField, Drawer, TablePagination } from '@material-ui/core';
import { styled } from "@material-ui/core/styles";
import SearchIcon from '@material-ui/icons/Search';
import FormControl from "@material-ui/core/FormControl";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from '@material-ui/core/Checkbox';
import { emptyCheckbox, filledCheckbox } from "./assets";
export const configJSON = require("./config");
// Customizable Area End


export default class ServiceProviderDashboard extends ServiceProviderDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    renderSelectedService = (i: number, item: any) => {
        return (
            <Box
                key={i}
                width="30%"
                p={2}
                className="serviceSelectChildCard"
                style={{
                    padding: '16px',
                    borderRadius: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    position: 'relative',
                    height:'129px',
                    width:'121%'
                }}
            >
                <Box
                    style={{
                        position: 'absolute',
                        left: '-15px',
                        color: '#0D4659',
                        width: '24px',
                        height: '24px',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight: 'bold',
                        border: '2px dashed #F0FAFA',
                        backgroundColor: 'white'
                    }}
                >
                    {i + 1}
                </Box>
                <Box style={{ width: '100%', display:'flex', flexDirection:'column', justifyContent:'space-around', height:'70%' }}>
                    <Typography onClick={this.closeDrawerHandler} variant="body2" color="textSecondary" style={{ gap: '10px', display: "flex" }}>
                        <span className="reviewsDiv" style={{ fontSize: "16px", border: 'none', fontWeight: "400", padding: "0px", marginLeft: "0px" }}>{item.heading}</span>
                        <span className="ratingDiv">
                            <ThumbUpAltOutlinedIcon /> 86% Positive</span>
                    </Typography>
                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography className="childCardHeading">{item.serviceName}</Typography>
                        <Box style={{ display: 'flex', gap: '10px' }}>
                            <Typography className="childCardHeading">{item.servicePrice}</Typography>
                            <Button
                                variant="outlined"
                                fullWidth
                                className="removeButton"
                                onClick={() => this.removeSelectedCardsFromServiceBlock(item)}
                            >
                                <span style={{textTransform: "none", fontSize:'16px', fontWeight:700}}>Remove</span>
                                
                                <span style={{ marginLeft: '5px' }}><CloseIcon /> </span>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }

    notSelectedService = (i: number) => {
        return (
            <Box
                key={i}
                width="33%"
                p={2}
                className="serviceNotSelectChildCard"
            >
                <Typography style={{fontSize:'18px'}}>No Service Selected</Typography>
            </Box>
        )
    }

    renderBlocks = () => {
        const blocks = [];
        for (let i = 0; i < 3; i++) {
            if (this.state.selectedCardService && i < this.state.selectedCardService.length) {
                const item = this.state.selectedCardService && this.state.selectedCardService[i];
                blocks.push(
                    this.renderSelectedService(i, item)
                );
            } else {
                blocks.push(
                    this.notSelectedService(i)
                );
            }
        }

        return blocks;
    };

    dropDownlable = (labelObject: any, firstItem: string) => {
        return (
            <Box display="flex" alignItems="center" width="100%">
                {labelObject[firstItem]}
            </Box>
        )
    }

    dropDownDefaultValue = () => {
        return (
            <em style={{ color: '#4B4C4B', textTransform: "none"}}>Choose Service</em>
        )
    }

    renderValue = (selected: any) => {
        const labelObject: any = {
            runner: 'Runner Service',
            full_service: 'Full Service',
            white_glove: 'White Glove',
            on_site: 'OnSite'
        }
        if (selected && selected.length === 0) {
            return <em style={{ color: '#4B4C4B', fontFamily: "Roboto", fontWeight: "400", fontStyle: "normal" }}>Choose Service</em>;
        }
        const firstValue = selected[0];
        const firstItem = this.state.dropdownData && this.state.dropdownData.find(option => option === firstValue);
        return firstItem ? this.dropDownlable(labelObject, firstItem) : this.dropDownDefaultValue()
    };

    renderValueSort = (selected: any) => {
        if (selected) {
            return (
                <Box>
                    {selected}
                </Box>
            )
        } else {
            return <em style={{ color: '#4B4C4B', fontFamily: "Roboto", fontWeight: "400", fontStyle: "normal" }}>Sort by</em>
        }
    }

    renderDrawer = () => {
        return (
            <Drawer anchor={"right"} open={this.state.closeDrawer} style={{ zIndex: 1200 }}>
                <Box style={{ padding: '72px', width: '768px' }}>
                    <Box>
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Box style={webStyles.reviewSectionHeading}>
                                LockSolutions Expert
                            </Box>
                            <span onClick={this.closeDrawerHandler}><CloseIcon style={{ width: '32px', height: "32px" }} /></span>
                        </Box>
                        <Typography style={webStyles.reviewSectionPara}>The following review shared by Dealers who have worked with this Provider</Typography>
                    </Box>
                    <Typography variant="body2" style={{ display: "flex", paddingTop: '72px' }}>
                        <span style={webStyles.ratingDiv}><ThumbUpAltOutlinedIcon />750 <ThumbDownOutlinedIcon />450</span>
                        <span style={webStyles.reviewsDiv}>1000+ Reviews</span>
                    </Typography>
                    <Box style={{ border: "1px solid #F0FAFA", margin: "30px 0px" }}></Box>
                    <Box>
                        <Box style={{ display: 'flex', gap: "16px" }}>
                            <Box>
                                <Avatar variant="square" style={{ borderRadius: '12px', width: '56px', height: '56px' }}>
                                    N
                                </Avatar>
                            </Box>
                            <Box>
                                <Box style={{ display: 'flex', alignItems: 'center', paddingBottom: '10px', gap: '10px' }}><span style={webStyles.reviewerNameStyle}>Kiss Dorka</span><span style={webStyles.ratingDiv}><ThumbUpAltOutlinedIcon /></span></Box>
                                <Box style={webStyles.reviewDateStyle}>Mar 25, 2024</Box>
                            </Box>
                        </Box>
                        <Typography style={webStyles.reviewCommentPara}>
                            Impressive results! Their Dealership went above and beyond to ensure my satisfaction. I couldn't be happier with the outcome.
                        </Typography>
                    </Box>
                </Box>
            </Drawer>
        )
    }

    selectedButton = (item: any, provider: any) => {
        return (
            <Box className="showButton" key={provider.id}>
                <Button
                    variant="outlined"
                    fullWidth
                    className="selectedButton"
                >
                    Selected
                    <span style={{ marginLeft: '5px' }}><CheckCircleOutlineIcon /> </span>
                </Button>
                <Button
                    variant="outlined"
                    fullWidth
                    className="removeButton"
                    onClick={() => this.removeSelectedServiceHandler({
                        id: provider.id, buttonIndex: item.id, heading: provider?.attributes?.business_name, serviceName: 'Runner Service', servicePrice: item.price
                    })}
                >
                    <Typography style={{textTransform:'none', fontWeight:700, fontSize:'16px'}}>
                    Remove
                    </Typography>
                    <span style={{ marginLeft: '5px' }}><CloseIcon /> </span>
                </Button>
            </Box>
        )
    }

    selectButton = (item: any, provider: any) => {
        const {id, attributes} = provider
        const {key} = item
        const arg = {
            id: id,
            buttonIndex: item.id,
            heading: attributes?.business_name,
            serviceName: 'Runner Service',
            servicePrice: attributes?.services?.[key]
        }

        const isMaxSelected = this.state.selectedCardService.length === 3;
        const hasDifferentSelected = this.state.selectedCardService &&this.state.selectedCardService.some((selected: any) => selected.id !== provider.id);
        const isServiceUnavailable = !attributes?.services?.[key];
        const isAlreadySelected = this.state.selectedCardService.some((selected: any) => selected.id === provider.id);
        const isDifferentButtonIndex = provider.buttonIndex && provider.buttonIndex !== item.id;
    
        const isDisabled = (isMaxSelected && hasDifferentSelected) || isServiceUnavailable || (isAlreadySelected && isDifferentButtonIndex);
        const buttonClassName = isDisabled ? "disableSelectButton" : "selectButton";

        return (
            <Button
            style={{ width: "78px", height: "32px", borderRadius: '8px'}}
                key={provider.id}
                variant="outlined"
                onClick={() => this.selectServiceHandler(arg)}
                fullWidth
                className={`${buttonClassName} selectFunc`}
                disabled={isDisabled}
            >
                <span style={{textTransform: "none" }}>Select</span>
                
            </Button>
        )
    }

    renderServicesComponentContent = (item: any, provider: any) => {
        return (
            <Box mt={2} key={item.key} className="selectServiceCard" alignItems="center">
                <Box className="serviceName" style={{ display: 'inline-block', whiteSpace: 'nowrap',  }}>{item.type}</Box>
                <Box style={{ width: "100%", }}>
                    <Box className="borderDiv" style={{ border: "1px dashed #F2F3F2" }}></Box>
                </Box>
                <Box className="cardChildDiv" style={{ justifyContent: "flex-end" }}>
                    <span style={{ justifyContent: "flex-end", }}>
                        {provider?.attributes?.services?.[item?.key] || "NA"}
                    </span>
                    {
                        this.state.selectedCardService.some((selected: any) => selected.id === provider.id) && provider.buttonIndex === item.id ?
                            this.selectedButton(item, provider)
                            :
                            this.selectButton(item, provider)
                    }
                </Box>
            </Box>
        )
    }

    renderServiceCardOption = (provider: any) => {
        return configJSON.cardsServiceOption.map((item: any, index: number) => (
            <div key={`${provider.id}-${item.key}-${index}`} style={{marginTop:'0px'}}>
                {this.renderServicesComponentContent(item, provider)}
            </div>
        ));
    }

    renderServicesComponent = (provider: any) => {
        return (
            <Card key={provider.id} variant="outlined" className="cardMainDiv"
                style={{ border: this.state.selectedCardService.some((selected: any) => selected.id === provider.id) && provider.buttonIndex ? "1px solid #013D4F" : "1px solid #F0FAFA" }}
            >
                <CardContent>
                    <Typography onClick={this.closeDrawerHandler} variant="body2" style={{ display: "flex", marginTop:'20px' }}>
                        <span className="ratingDiv"><ThumbUpAltOutlinedIcon style={{width:'15px', height:'15px'}}/>86% Positive</span>
                        <span className="reviewsDiv">10000+ Reviews</span>
                    </Typography>
                    <Typography className="cardHeading">{provider?.attributes?.business_name}</Typography>
                    <Box style={{height:'333px', marginTop:'20px'}}>
                    {this.renderServiceCardOption(provider)}
                    </Box>
                </CardContent>
            </Card>
        )
    }

    renderServices = () => {
        if (this.state.data.length) {
            return this.state.data.map((provider: any) => (
                <Grid key={provider.id} item xs={12} sm={12} md={6} lg={4} xl={4}>
                    {this.renderServicesComponent(provider)}
                </Grid>
            ));
        } else {
            return (
                <Box>
                    No Data Found
                </Box>
            )
        }
    }

    header = () => {
        return (
            <div style={{ padding: '26px' }}>
                <Button style={{width:'86px', height:'40px', display:'flex', alignItems:'center', justifyContent:'space-around'}} onClick={this.backNavigation}>
                    <Box >
                            <ArrowBackIosIcon style={{width:'20px', height:'20px'}}/>
                    </Box>
                    <Grid item>
                        <Typography variant="body1" style={{ color: "#4B4C4B", fontWeight: "700", fontFamily: "Roboto", fontSize: "16px", display: "inline", marginLeft: "-15px", textTransform:'none' }}>
                            Back
                        </Typography>
                    </Grid>
                </Button>
            </div>
        )
    }

    dropDownMenu = (name: any) => {
        return (
            <SelectorComponentStyle key={name.key} value={name.key} className="selectedItems">
                <Box
                    display="flex" justifyContent="space-between" alignItems="center" width="100%">
                    <ListItemText
                        primary={name.type} />
                    <Checkbox
                        onChange={() => this.handleChecked(name.key)}
                        checked={this.state.checked[name.key]}
                        style={{ color: this.state.checked[name.key] ? '#4FC8EC' : "" }}
                        icon={<img src={emptyCheckbox}/>}
                        checkedIcon={<img src={filledCheckbox}/>}
                    />
                </Box>
            </SelectorComponentStyle>
        )
    }

    render() {
        // Customizable Area Start
        return (
            <Box>
                {this.header()}
                <Box className="borderDiv" style={{ border: "1px solid #F0FAFA" }}></Box>
                <ServiceProviderDashboardStyle>
                    {this.renderDrawer()}
                    <Box className="serviceDashboardHeader">
                        <Box className="headerChildDiv">
                            <Box className="hadingText">{((this.state.selectedCardService.length === 1 || this.state.selectedCardService.length === 2) && `${this.state.selectedCardService.length} Select Service Provider`) || (this.state.selectedCardService.length === 3 ? `All 3 Service Selected.` : "Select Service Provider")}</Box>
                            <Box className="paraText">{this.state.selectedCardService.length ? `Select up to 3 options, but choose only one service per provider.` : `Please choose the service provider(s) you would like to work with from the list below.`}</Box>
                        </Box>
                        <Button className={`${this.state.selectedCardService.length > 0 ? 'selectedNextButton' : 'nextButton'}`}>
                            <span style={{ textTransform: "none" }}>Next Step</span>
                        </Button>
                    </Box>
                    <Box className="borderDiv"></Box>
                    <Box mt={12} mb={4} style={{ display: "flex", justifyContent: "space-between", gap: "26px" }}>
                        {this.renderBlocks()}
                    </Box>
                    <Box className="borderDiv" style={{ marginBottom: "0px" }}></Box>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="subtitle1">
                            <span style={{ fontFamily: "Roboto", fontWeight: "400", fontSize: "14px", color: "#4B4C4B" }}> 250 Service Providers</span> <span style={{ fontFamily: "Roboto", fontWeight: "500", fontSize: "14px", color: "#4B4C4B" }}>(Select up to 3 options, but choose only one service per provider.)</span>
                        </Typography>
                        <div>
                            <Grid container alignItems="center" spacing={2}>
                                <Box>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Start typing to search..."
                                        data-test-id="searchTestId"
                                        InputProps={{
                                            startAdornment: <SearchIcon />,
                                        }}
                                        className="searchInput"
                                        style={{width:'100%'}}
                                        onChange={(e) => this.searchServiceListHandler(e, 'search')}
                                    />
                                </Box>
                                <div>
                                    <FormControl style={dropdownCheckbox.formControl}>
                                        <Select
                                            labelId="demo-mutiple-checkbox-label"
                                            id="demo-mutiple-checkbox"
                                            IconComponent={ExpandMore}
                                            className="selectStyle"
                                            multiple
                                            displayEmpty
                                            value={this.state.dropdownData}
                                            onChange={(e) => this.handleChangeDropdown(e)}
                                            MenuProps={MenuProps}
                                            renderValue={this.renderValue}
                                            disableUnderline
                                        >
                                            {configJSON.cardsServiceOption.map((name: any) => (
                                                this.dropDownMenu(name)
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <FormControl variant="outlined" className="selectInput">
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={this.state.sortType || ""}
                                        onChange={(e) => this.searchServiceListHandler(e, 'sort')}
                                        IconComponent={ExpandMore}
                                        className="selectInput"
                                        displayEmpty
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom" as const,
                                                horizontal: "left" as const,
                                              },
                                              getContentAnchorEl: null,
                                            }}
                                        renderValue={this.renderValueSort}
                                    >
                                        <SelectorComponentStyle value="A-Z">A to Z</SelectorComponentStyle>
                                        <SelectorComponentStyle value="Z-A">Z To A</SelectorComponentStyle>
                                        <SelectorComponentStyle value="highly rated">Highly Rated</SelectorComponentStyle>
                                    </Select>
                                </FormControl>
                                <TablePagginationStyle>
                                    <Grid item style={{ paddingLeft: '30px' }}>
                                        <Grid container direction="row"
                                            alignItems="center"
                                        >
                                            <Box >
                                                <Typography style={{ marginLeft: "2px", color: "#4B4C4B", fontWeight: "400" }}
                                                    align="left"
                                                    variant="body1">{`${this.state.currentPage} of ${this.state.totalpages}`}
                                                </Typography>
                                            </Box>
                                            <Grid item className="pagginationGrid">
                                                <TablePagination
                                                    component="div"
                                                    count={this.state.totalpages}
                                                    page={this.state.currentPage}
                                                    onPageChange={this.handlePageChange}
                                                    rowsPerPage={0}
                                                    rowsPerPageOptions={[]}
                                                    labelDisplayedRows={() => ''}
                                                    nextIconButtonProps={{
                                                        disabled: this.state.currentPage === this.state.totalpages || this.state.totalpages <= 1
                                                    }}
                                                    backIconButtonProps={{
                                                        disabled: this.state.currentPage <= 1 || this.state.totalpages === 0
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </TablePagginationStyle>
                            </Grid>
                        </div>
                    </div>
                    <Box className="borderDiv" style={{ marginTop: "0px" }}></Box>
                    <Grid container spacing={3}>
                        {this.renderServices()}
                    </Grid>
                </ServiceProviderDashboardStyle>
            </Box>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const SelectorComponentStyle = styled(MenuItem)(({ theme }) => ({
    '&:hover': {
        backgroundColor: '#F0FAFA',
    },
    '&.Mui-selected': {
        backgroundColor: 'white',
    }
}))

const TablePagginationStyle = styled(Grid)(() => ({
    '.& pagginationGrid': {
        padding: '0px',
    },
    '& .pagginationGrid MuiTablePagination-root .MuiToolbar-gutters': {
        paddingRight: '0px',
        paddingLeft: "0px"
    },
    '& .pagginationGrid MuiTablePagination-root': {
        '& @media (min-width: 600px)': {
            '& .MuiToolbar-gutters': {
                paddingLeft: '0px'
            }
        }
    }
}))

const ServiceProviderDashboardStyle = styled(Box)(() => ({
    padding: '0 16.66%',
    paddingTop: '80px',
    backgroundColor: '#FFFFFF',
    "& .serviceDashboardHeader": {
        display: 'flex'
    },
    "& .ratingDiv": {
        backgroundColor: "#F0FAFA",
        borderRadius: "5px",
        padding: "0px 10px",
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "12px",
        width:'108px',
        height:'26px',
        color: "#4B4C4B",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: "5px"
    },
    "& .thumbIconStyle": {
        fontSize: "30px"
    },
    '& .reviewsDiv': {
        textDecorationLine: "underLIne",
        textDecorationStyle: "dashed",
        textUnderlineOffset: "2px",
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "12px",
        color: "#4B4C4B",
        marginLeft: "5px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        
    },
    "& .hadingText": {
        fontFamily: 'Gotham',
        fontWeight: 300,
        fontSize: '30px',
        lineHeight: '40px',
        color: '#013D4F',
        paddingBottom: '10px'
    },
    "& .paraText": {
        fontFamily: 'Gotham',
        fontWeight: 300,
        fontSize: '18px',
        lineHeight: '27px',
        color: '#4B4C4B'
    },
    "& .nextButton": {
        background: "#E8F8FD",
        color: "#75D3F0",
        fontFamily: "Roboto",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        width: "234px",
        height: "56px",
        borderRadius: "8px"
    },
    "& .selectedNextButton": {
        background: "#4FC8EC",
        color: "#FFFFFF",
        fontFamily: "Roboto",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        width: "200px",
        height: "56px",
        borderRadius: "8px"
    },
    "& .selectServiceCard": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        fontFamily: "Roboto",
        marginTop: "-16px",
    },
    "& .headerChildDiv": {
        width: "100%"
    },
    "& .borderDiv": {
        border: '1px solid #F0FAFA',
        margin: "30px 0px"
    },
    '& .removeButton': {
        width: "auto",
        border: "1px solid #C43937",
        borderRadius: "8px",
        color: "#FFFFFF",
        fontWeight: 700,
        fontFamily: "Roboto",
        backgroundColor: "#C43937",
        cursor: "pointer",
        display: 'none',
        padding: '6px 4px 6px 8px',
        '& .MuiButton-label': {
            display: 'flex',
            justifyContent:'center',
            alignItems:'center',
        },
        justifyContent:'center',
        alignItems:'center',
        height:'32px',
    },
    '& .showButton:hover .selectedButton': {
        display: 'none'
    },
    '& .showButton:hover .removeButton': {
        display: 'flex',
        justifyContent:'center',
        alignItems:'center',
        width:'101px',
        height:'32px',
    },
    "& .serviceSelectChildCard": {
        border: "2px dashed #F0FAFA",
        padding: "20px",
        borderRadius: "12px",
        color: "#C5CBC9",
        fontSize: "18px",
        fontFamily: "Roboto",
        fontWeight: 400,
        display: "flex",
        width: "100%"
    },
    "& .serviceSelectChildCard:hover": {
        border: "2px solid #013D4F",
        padding: "20px",
        borderRadius: "12px",
        color: "#C5CBC9",
        fontSize: "18px",
        backgroundColor: "#013D4F",
        fontFamily: "Roboto",
        fontWeight: 400,
        display: "flex",
        width: "100%",
        '& .reviewsDiv': {
            color: "#FFFFFF"
        },
        '& .ratingDiv': {
            color: "#013D4F",
            backgroundColor: "#FFFFFF"
        },
        '& .cardHeading': {
            color: "#FFFFFF"
        },
        '& .childCardHeading': {
            color: "#FFFFFF"
        },
        '& .removeButton': {
            display: 'flex',
            justifyContent:'center',
            alignItems:'center'
        }
    },
    "& .serviceNotSelectChildCard": {
        border: "2px dashed #F0FAFA",
        padding: "40px",
        borderRadius: "12px",
        color: "#C5CBC9",
        fontSize: "18px",
        fontFamily: "Roboto",
        fontWeight: 400,
        display: "flex",
        width: "100%",
        justifyContent: "center"
    },
    "& .headerOptionDiv": {
        padding: "15px 0px",
        borderTop: "1px solid black",
        borderBottom: "1px solid black",
        fontSize: "14px",
        fontFamily: "Roboto",
        color: "#4B4C4B",
        lineHeight: "22px",
        margin: "15px 0px"
    },
    "& .cardChildDiv": {
        display: "flex",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        fontFamily: "Roboto",
        color: "#4B4C4B",
        alignItems: "center",
        gap: "10px"
    },
    "& .serviceProviderCard": {
        border: '1px solid black',
        padding: '32px',
        borderRadius: "12px",
        width: "410px"
    },
    "& .serviceProviderCardDiv": {
        display: "flex",
        justifyContent: "space-between"
    },
    "& .cardHeading": {
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "32px",
        fontFamily: "Roboto",
        letterSpacing: "-0.5%",
        color: "#4B4C4B",
        marginTop:'5px'
    },
    '& .childCardHeading': {
        fontWeight: 500,
        lineHeight: "32px",
        fontFamily: "Roboto",
        letterSpacing: "-0.5%",
        fontSize: "18px",
        color: "#013D4F"
    },
    "& .selectButton": {
        width: "78px",
        border: "1px solid #013D4F",
        borderRadius: "8px",
        color: "#013D4F",
        fontWeight: "700",
        fontFamily: "Roboto",
        cursor: "pointer",
        fontSize:"16px"
    },
    "& .selectButton:hover": {
        backgroundColor: "#4FC8EC",
        border: '1px solid #4FC8EC !important'
    },
    "& .selectedButton": {
        width: "auto",
        border: "1px solid #26BBB3",
        borderRadius: "8px",
        color: "#FFFFFF",
        fontWeight: "700",
        fontFamily: "Roboto",
        backgroundColor: "#26BBB3",
        cursor: "pointer",
        display: 'flex',
        padding: '6px 4px 6px 8px',
        '& .MuiButton-label': {
            display: 'flex'
        },
        height:'32px',
        alignItems:'center'
    },
    "& .diasbleSelectButton": {
        width: "auto",
        border: "1px solid #DEE4E2",
        borderRadius: "8px",
        color: "#DEE4E2",
        fontWeight: "700",
        fontFamily: "Roboto"
    },
    "& .searchInput": {
        "& .MuiInputBase-root": {
            "& .MuiOutlinedInput-notchedOutline": {
                border: "none"
            },
            "& .MuiSvgIcon-root": {
                fontSize: "14px",
                marginRight: "5px",
                fontWeight: 500
                
            },
            "& .MuiInputBase-input": {
                color: "#4B4C4B",  
                '&::placeholder': {
                    color: '#C5CBC9',
                    opacity: 1,
                    fontWeight: 500,
                    fontSize:'14px'
                  },
            }
        }
    },
    "& .selectInput": {
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
            color: '#4B4C4B',
            fontFamily: "Roboto",
            fontWeight: "400"
        }
    },

    "& .arrowIcon": {
        fontSize: "16px"
    },
    "& .cardMainDiv": {
        borderRadius: "12px",
        maxWidth:'410px',
        maxHeight:'333px'
    },
    '& .cardMainDiv:hover': {
        backgroundColor: "#013D4F",
        '& .selectButton': {
            border: "1px solid #DEE4E2 !important",
            color: '#FFFFFF !important'
        },
        '& .disableSelectButton': {
            border: '1px solid #F0FAFA',
            opacity: '17%',
            color: '#F0FAFA'
        },
        '& .cardChildDiv': {
            color: "#FFFFFF"
        },
        '& .ratingDiv': {
            backgroundColor: "#FFFFFF",
            color: "#013D4F"
        },
        '& .reviewsDiv': {
            color: "#FFFFFF",
        },
        '& .cardHeading': {
            color: "#FFFFFF"
        },
        '& .diasbleSelectButton': {
            border: '1px solid #F0FAFA !important',
            color: "#F0FAFA !important"
        },
        '& .serviceName': {
            color: 'white !important'
        },
    }
}))

const webStyles = {
    reviewsDiv: {
        padding: "5px 10px",
        borderBottom: "1px dashed #4B4C4B",
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "12px",
        color: "#4B4C4B",
        marginLeft: "5px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    ratingDiv: {
        backgroundColor: "#F0FAFA",
        borderRadius: "5px",
        padding: "5px 10px",
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "12px",
        color: "#4B4C4B",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: "5px"
    },
    reviewSectionHeading: {
        color: "#013D4F",
        fontFamily: "Gotham",
        Weight: '300',
        fontSize: '24px',
    },
    reviewerNameStyle: {
        color: "#4B4C4B",
        fontFamily: "Roboto",
        Weight: '600',
        fontSize: '18px'
    },
    reviewDateStyle: {
        color: "#C5CBC9",
        fontFamily: "Roboto",
        Weight: '500',
        fontSize: '14px'
    },
    reviewCommentPara: {
        color: "#4B4C4B",
        fontFamily: "Roboto",
        Weight: '400',
        fontSize: '16px',
        paddingTop: '20px'
    },
    reviewSectionPara: {
        color: "#4B4C4B",
        fontFamily: "Roboto",
        Weight: '500',
        fontSize: '14px',
        paddingTop: '10px'
    },
    serviceItem: {
        '&:hover': {
            backgroundColor: '#F0FAFA',
            cursor: 'pointer',
        },
    }
}

const dropdownCheckbox = {
    formControl: {
        minWidth: 120,
        maxWidth: 300,
    },
    dropdown: {
        margin: "10px",
        backgroundColor: "#F0FAFA"
    },
}

const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "224px",
        width: "300px",
        overflow: 'auto',
      },
    },
    anchorOrigin: {
      vertical: "bottom" as const,
      horizontal: "left" as const,
    },
    getContentAnchorEl: null,
  };
// Customizable Area End
