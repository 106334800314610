import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Loader } from "@googlemaps/js-api-loader"
import React, { ChangeEvent } from "react";
import { State, City } from 'country-state-city';
import * as Yup from 'yup';
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import { getStorageData } from "../../../framework/src/Utilities";
export interface ICreateServiceProviderDetails {
  sp_business_name: string;
  sp_address: string;
  sp_street_address: string;
  sp_city: string;
  sp_state: string;
  sp_zipCode: string;
  sp_zipCodeEXT: string;
  sp_shipping_address: string;
  sp_shipping_street_address: string;
  sp_shipping_city: string;
  sp_shipping_state: string;
  sp_shipping_zipCode: string;
  sp_shipping_zipCodeEXT: string;
  profilePhoto: string | null;
  initials: string | null;
  business_credentials: string;
  newCarOrUsedCar: string;
  checkedBoxes: string[];
  test: { value: string; displayValue: string, stateISOCode: string }[];
  testCities: CityType[]; 
  testShippingCities: CityType[]; 
  stateISOCode: string;
  filePhoto: File | null;
}

export interface ResponseJsonFetchSign {
  message: string,
  pdf_url: string,
}


export interface ResponseData {
  data: {
    id: string;
    type: string;
    attributes: {
      activated: boolean;
      country_code: string;
      email: string;
      full_phone_number: string;
      phone_number: string;
      type: string;
      full_name: string;
      created_at: string;
      updated_at: string;
      role: string;
      profile_picture: string | null;
      is_provider_pending: boolean;
      provider_information: {
        data: {
          id: string;
          type: string;
          attributes: {
            business_name: string;
            business_credentials: string;
            deal_type: string[];
            created_at: string;
            updated_at: string;
            business_address: {
              address_type: string;
              address: string;
              city: string;
              state: string;
              zipcode: string;
              zipcode_ext: string;
            };
            shipping_address: {
              address_type: string;
              address: string;
              city: string;
              state: string;
              zipcode: string;
              zipcode_ext: string;
            };
            serviceable_locations: ServiceableLocations;
            contact_info:ContactInfo;
            banking_info: BankingInfo;
            service_provider_logo: null;
            w9_forms: any[];
          };
        };
      };
      provider_name: string;
    };
  };
}

export interface ServiceableLocations {
  data: any
}

export interface ContactInfo {
  name: string
  email: string
  phone_number: string
}

export interface BankingInfo {
  data: any
}


export interface ICreateServiceableLocations {
  id: string;
  stateName:string;
  uploadBusinessLicense:any;
  businessLicense:string;
  uploadDealerLicense:any;
  dealerLicense:string;
  uploadDMVContract:any;
  DMVContract:string;
  uploadRegistrationServiceLicense:any;
  registrationServiceLicense:string;
  uploadBondingRegistration:any;
  bondingRegistration:string;
  isFullService:boolean,
  fullServicePrice:string;
  isWhiteGlove:boolean,
  whiteGlovePrice:string;
  isOnSite:boolean;
  onSitePrice:string;
  isRunner:boolean;
  isEditServiceable:boolean
}
export interface SpContactInfoDetails {
  fullName: string;
  phoneNumber: string;
  email: string;
}

export interface SpBankInfoFormDetails {
  bankAccountNumber: string;
  confBankAccountNumber: string;
  routingNumber: string,
  confRoutingNumber: string;
  bankName: string,
  branch: string;
  name:string;
  phoneNumber:string;
  jobTitle:string;
}

export interface SpThirdFormAddressDetails {
  address: string;
  streetAddress: string;
  thirdFormZipCode: string;
  thirdFormZipCodeEXT: string;
}

interface SpAddress {
  streetAddress : string;
  city: string;
  state: string;
  zipCode: string;
  zipCodeEXT: string;
}

export interface SpThirdFormDetailsNew {
  thirdFormState: string;
  thirdFormCity: string;
  thirdFormCities: CityType[];
}

export interface SpBankingInfoDetails {
  bankAccountNumber: string;
  confBankAccountNumber: string;
  routingNumber: string;
  confRoutingNumber: string;
  bankName: string;
  branch: string;
  bankStreetAddress: string;
  bankState: string;
  bankCity: string;
  bankkZipCode: string;
  bankZipCodeEXT: string;
}

export interface TaxInfoDetails {
  taxInfoFormLink: string;
  taxInfoFile: File | null;
  isContractPdfView: boolean,
  uploadFullSignature: string;
  uploadIntitalSignture: string;
  drawSignature: string;
  fullSignatureUploadView: File | null;
  initialSignatureUploadView: File | null;
  isSignCheck:boolean,
  isPdfCheck:boolean,
  created_signTimeDate:string,
  pdfLink:string;
  pdfSignFile:string;
  isContractPdfViewSign:boolean,
}

export interface CityType {
  value: string;
  displayValue: string;
}

interface SpAddressPrediction {
  description: string;
  place_id: string;
}

export interface SpIUserAttributes {
  activated: boolean;
  country_code: string;
  email: string;
  full_phone_number: string;
  phone_number: string;
  type: string;
  created_at: string;
  updated_at: string;
  role: string;
  profile_picture: string;
}

export interface SpIUserProfile {
  id: string;
  type: string;
  attributes: SpIUserAttributes;
}

export interface SpIAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}

export interface IAttributeValues {
  name: string;
  value: string;
}

type SpFieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type SpFieldValue = string | number | boolean | Date | null;
export interface SpIField {
  name: string;
  title: string;
  field_type: SpFieldType;
  is_enable: boolean;
  is_required: boolean;
}

interface SpIProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: SpFieldValue } };
}

export interface SpIProfileData {
  [key: string]: SpFieldValue;
}

export interface SpIProfileValues {
  value: string;
}

export interface ProviderInfoSuccessResponse {
  data: {
    id: string;
  };
}
export interface ProviderInfoErrorResponse {
  errors: [
    {
      full_phone_number: string;
      token: string;
    },
  ];
  status_code: number;
}
export interface ContactInfoErrorResponse {
  errors?: Array<{
    [key: string]: string;
  }>;
  status_code?: number;
}

export interface BankInfoErrorResponse {
  errors?: Array<{
    token?: string;
    account?: string;
    message?: string;
    account_number:string;
  }>;
  status_code?: number;
}
interface BankInformation {
  data: {
    id: string;
    type: string;
    attributes: {
      bank_name: string;
      account_number: string;
      bank_routing_number: string;
      branch_name: string;
      name: string;
      phone_number: string;
      job_title: string;
      created_at: string;
      updated_at: string;
      agree_e_sign: boolean;
      authirization_signature: string | null;
      voided_check: string;
      full_signature: string;
      initial_signature: string;
      address: {
        data: {
          id: string;
          type: string;
          attributes: {
            address: string;
            address_type: string;
            country: string | null;
            city: string;
            state: string;
            zipcode: string;
            zipcode_ext: string;
          };
        };
      };
    };
  };
}
 
 
export interface ServiceProviderInfoSuccessResponse {
  data: {
    id: string;
  };
}
export interface DealerInfoErrorResponse {
  errors: [
    {
      full_phone_number: string;
      token: string;
    },
  ];
  status_code: number;
}
export interface TaxInfoErrorResponse {
  errors: Array<{
    [key: string]: string;
  }>;
  status_code?: number;
}

interface SpLocationErrorResponse {
  errors: Array<{
    state: string;
    errors: string[];
  }>;
}
 
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isSignatureModelOpen?:  boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  spProfile: { [key: string]: SpFieldValue };
  spFields: SpIField[];
  currentProfile?: { [key: string]: SpFieldValue };
  fullName: string;
  email: string;
  phoneNumber: string;
  city: string;
  spAddress: SpAddress;
  attributesValues: SpIProfileValues[];
  attributes: SpIAttribute[];
  soUserProfile: SpIUserProfile;
  step: number;
  doneSteps: number[];
  skippedSteps: number[];
  isFormFilled: boolean;
  isSecundFormFilled:boolean;
  isContactFormFilled: boolean;
  isThirdFormFilled: boolean;
  isThirdFormValid:boolean;
  isFourthFormFilled: boolean;
  serviceProviderInfoId: string;
  serviceProviderProDetails: ICreateServiceProviderDetails;
  suggestions: Array<{description: string, placeId: string}>;
  spContactInfoDetails: SpContactInfoDetails;
  isOpen : boolean;
  isExpanded : boolean;
  isExpandedBank : boolean;
  isExpandedBankAddress : boolean;
  isExpandedBankAuth : boolean;
  serviceableLocations:ICreateServiceableLocations;
  serviceableLocationsList:ICreateServiceableLocations[];
  oldServiceableLocationsList:ICreateServiceableLocations[];
  spBankInfoFormDetails: SpBankInfoFormDetails;
  spThirdFormDetailsNew: SpThirdFormDetailsNew;
  spThirdFormAddressDetails: SpThirdFormAddressDetails;
  taxInfoDetails: TaxInfoDetails;
  isSameAddress: boolean;
  hasSignature :boolean;
  isModalOpen: boolean;
  input: string;
  spAddressPredictions: SpAddressPrediction[];
  spShippingAddressPredictions: SpAddressPrediction[];
  spBankAddressPredictions: SpAddressPrediction[];
  voidedCheckFile: File | null;
  voidedCheckUrl:string;
  loaderState : Loader | null;
  photoErrorMessage: string;
  activeTab:string;
  isEsignFinish:boolean;
  activeSignatureView:boolean;
  isBankInfoFormValid:boolean
  errorServiceProviderBackend:string;
  errorsBankInfoBackend:string|undefined;
  errorsTaxInfoBackend:string;
  errorsSpLocationBackend:string;
  additionalBoolForApi:boolean
  bankDetailsId:string;
  spLocationId:string;
  isNewServiceProvider:boolean;
  label:string;
  isFirstSpLocation : boolean;
  isOpenBackToDashboardModal : boolean;
  signBaseUrl : string;
  dealershipId : string;
  action: string;
  mergeSignature: boolean;
   // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ServiceProviderProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  autocompleteService!: google.maps.places.AutocompleteService;
  placesService!: google.maps.places.PlacesService;
  autocompleteServiceShipping!: google.maps.places.AutocompleteService;
  placesServiceShipping!: google.maps.places.PlacesService;
  autocompleteServiceBank!: google.maps.places.AutocompleteService;
  placesServiceBank!: google.maps.places.PlacesService;
  spFormDataCallId: string = "";
  contactInfoDataCallId: string = "";
  bankInfoCallId: string = "";
  deleteServiceableLocationId: string = '';
  spLocationCallId: string = "";
  spTaxInfoCallId: string = "";
  getServiceProfileAccountID: string = "";
  token: string = "";
  googleMaps: string = "";
  googleMapsShipping: string = "";
  googleMapsBank: string = "";
  authToken: string = "";
  getContactInfoCallId: string = "";
  getPdfApiCallId: string = "";
  getPdfSignApiCallId: string = ""
  getTaxInfoLinkCallId: string = "";
  getServiceableLocationsCallId: string= "";
  spDrawSignCanvasRef: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      mergeSignature: false,
      action: '',
      signBaseUrl:'',
      spAddressPredictions: [],
      spShippingAddressPredictions: [],
      spBankAddressPredictions: [],
      input: '',
      spAddress: {
      city: '',
      state: '',
      zipCode: '',
      zipCodeEXT: '',
      streetAddress: ''
    },
    spProfile: {},
      spFields: [],
      attributes: [],
      attributesValues: [],
      fullName: "",
      email: "",
      city: "",
      phoneNumber: "",
      soUserProfile: {
        id: "",
        type: "",
        attributes: {
          activated: false,
          country_code: "",
          email: "",
          full_phone_number: "",
          phone_number: "",
          type: "",
          created_at: "",
          updated_at: "",
          role: "",
          profile_picture: "",
        },
      },
      step: 1,
      doneSteps: [],
      isFormFilled: false,
      isSecundFormFilled:false,
      isContactFormFilled: false,
      isThirdFormFilled: false,
      isThirdFormValid:false,
      isFourthFormFilled: false,
      skippedSteps: [],
      serviceProviderInfoId: "",
      serviceProviderProDetails: {
        sp_business_name: "",
        sp_address: "",
        sp_street_address: "",
        sp_city: "",
        sp_state: "",
        sp_zipCode: "",
        sp_zipCodeEXT: "",
        sp_shipping_address: "",
        sp_shipping_street_address: "",
        sp_shipping_city: "",
        sp_shipping_state: "",
        sp_shipping_zipCode: "",
        sp_shipping_zipCodeEXT: "",
        profilePhoto: null,
        initials: null,
        business_credentials: "",
        newCarOrUsedCar: "",
        checkedBoxes: [],
        test: [],
        testCities: [],
        testShippingCities: [],
        stateISOCode: "",
        filePhoto: null,
      },
      suggestions: [],
      spContactInfoDetails: {
        fullName: "",
        phoneNumber: "",
        email: ""
      },
      isExpanded: true,
      isExpandedBank : true,
      isExpandedBankAddress : true,
      isExpandedBankAuth : true,
      isOpen: false,
      serviceableLocations:{
        stateName: "",
        uploadBusinessLicense: null,
        businessLicense: "",
        uploadDealerLicense: null,
        dealerLicense:"",
        uploadDMVContract:null,
        DMVContract:"",
        uploadRegistrationServiceLicense:null,
        registrationServiceLicense:"",
        uploadBondingRegistration: null,
        bondingRegistration:"",
        isFullService:false,
        isOnSite:false,
        isRunner:false,
        isWhiteGlove:false,
        whiteGlovePrice:"",
        onSitePrice:"",
        fullServicePrice:"",
        isEditServiceable:false,
        id: ""
      },
      serviceableLocationsList:[],
      oldServiceableLocationsList: [],
      spBankInfoFormDetails: {
        bankAccountNumber: '', 
        confBankAccountNumber: '', 
        routingNumber: '', 
        confRoutingNumber: '', 
        bankName: '', 
        branch: '', 
        name:'',
        phoneNumber:"",
        jobTitle:''
      },
      spThirdFormDetailsNew: {
        thirdFormCities: [],
        thirdFormState: '', 
        thirdFormCity: '', 
      },
      spThirdFormAddressDetails: {
        address: '',
        streetAddress: '',
        thirdFormZipCode: '',
        thirdFormZipCodeEXT: '',
      },
      taxInfoDetails: {
        taxInfoFormLink: "",
        taxInfoFile: null,
        isContractPdfView: false,
        fullSignatureUploadView: null,
        initialSignatureUploadView: null,
        uploadFullSignature: "",
        uploadIntitalSignture: '',
        drawSignature:"",
        isPdfCheck:false,
        isSignCheck:false,
        created_signTimeDate:"",
        pdfLink:"",
        pdfSignFile:"",
        isContractPdfViewSign: false,
      },
      voidedCheckFile: null,
      voidedCheckUrl:"",
      activeSignatureView:false,
      isSameAddress: false,
      hasSignature :false,
      isModalOpen: false,
      loaderState: null,
      photoErrorMessage: "",
      activeTab :"tab1",
      isEsignFinish:false,
      isBankInfoFormValid:false,
      errorServiceProviderBackend:"",
      errorsBankInfoBackend:"",
      errorsSpLocationBackend:"",
      bankDetailsId:"",
      errorsTaxInfoBackend:"",
      spLocationId:"",
      additionalBoolForApi:false,
      isNewServiceProvider:false,
      label:"",
      isFirstSpLocation:false,
      isOpenBackToDashboardModal : false,
      dealershipId : "",
      // Customizable Area End
    };
    // Customizable Area Start
    this.spDrawSignCanvasRef = React.createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    this.getSpToken();
    const loader = new Loader({
      apiKey: "YOUR_API_KEY",
      version: "weekly",
      libraries: ["places"]
    });
    this.setState({loaderState: loader})
    this.loadGoogleMapsScript();
    this.loadGoogleMapsScriptShipping();
    this.loadGoogleMapsScriptBank();
    this.getServiceableLocations();
    this.getSpPdfUrl()
    this.getSpContactInfo()
    this.getBankInfo()
    this.getSpTaxFormLink()
    this.setState((prevState: S) => ({
      serviceProviderProDetails: {
        ...prevState.serviceProviderProDetails,
        test: State.getStatesOfCountry('US').map((state) => ({
          value: state.name,
          displayValue: `${state.name}`,
          stateISOCode: state.isoCode
        })),
      },
    }));
    if (this.state.taxInfoDetails.drawSignature) {
      this.spDrawSignCanvasRef.current.fromDataURL(this.state.taxInfoDetails.drawSignature);
    }
    const action = localStorage.getItem('action');
    if(action){
      this.setState({action: action})
      switch(action){
        case 'add-serviceable-location':
          this.setState({
            step: 2,
            doneSteps: [1, 3, 4, 5]
          });
          break;
        case 'edit-service-provider':
          this.setState({
            doneSteps: [1, 2, 3, 4, 5]
          });
          break;
        case 'edit-serviceable-location':
          this.setState({
            serviceableLocations:{
              ...this.state.serviceableLocations,
              id: localStorage.getItem('serviceable-location-id')!
            },
            step: 2,
            doneSteps: [1, 3, 4, 5],  
          })
          break;
      }
    }
    // Customizable Area End
  }

// Customizable Area Start
  handleNavigationPayloadMessage =  async (message: Message) => {
    const passingPramData = message.getData(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
  }

  getSpToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  checkSpSession = (message: Message) => {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const testToken = window.localStorage.getItem("authToken");
      if (testToken) this.token = testToken;
      runEngine.debugLog("TOKEN1", this.token);
      return true;
    }
  };

  checkSpProfileAccount = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getServiceProfileAccountID) {
      if (!responseJson.data || !responseJson.data.attributes) return;
      const {data} = responseJson;
      const { attributesValues, attributes } = this.state;

      this.setState({
        spAddress: data.attributes.address,
        city: data.attributes.city,
        fullName: data.attributes.name,
        attributesValues: Object.keys(data.attributes.user_profile_data).map(
          (item) => {
            return {
              ...attributes[(item as unknown) as number],
              value: data.attributes.user_profile_data[item]
            };
          }
        )
      });

      this.setState({
        attributes: attributesValues.map((item, index) => {
          return {
            ...attributes[index],
            value: item.value
          };
        })
      });

      return true;
    }
  };

  getFileNameFromUrl = (url: string): string => {
    if (!url) return "";
    const parts = url.split('/');
    const lastSegment = parts.pop();
    if (!lastSegment) return "";
    const fileName = lastSegment.split('?')[0];
    return decodeURIComponent(fileName);
  };

  getBlobFromFile = async (url: string) => {
    let response = await fetch(url);
    let blob = await response.blob();
    return blob;
  }
// Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (this.checkSpSession(message)) return;
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )
      this.receiveSpCognitive(apiCallId);
      switch(apiCallId){
        case this.getPdfApiCallId: {
          this.setState((prevState) => ({
            taxInfoDetails: {
              ...prevState.taxInfoDetails,
            pdfLink:responseJson.data.e_sign_form
            },
          }));
          break;
        }
        case this.getTaxInfoLinkCallId: {
          this.setState((prevState) => ({
            taxInfoDetails: {
              ...prevState.taxInfoDetails,
              taxInfoFormLink : responseJson.data.w9_form,
            },
          }));
          break;
        }
        case this.deleteServiceableLocationId: {
          this.getServiceableLocations();
          break;
        }
      }
      if (apiCallId === this.getContactInfoCallId)
      {
          this.setState({isFirstSpLocation: !responseJson.data.attributes.is_location_available,})
          if (!responseJson.data.attributes.is_provider_pending) {
              this.setState({isNewServiceProvider:true});
                this.setState((prevState) => ({
                  doneSteps: [...this.state.doneSteps, 3],
                  spContactInfoDetails: {
                    ...prevState.spContactInfoDetails,
                    fullName: responseJson.data.attributes.full_name,
                    phoneNumber: responseJson.data.attributes.phone_number,
                    email: responseJson.data.attributes.email,
                  },
                  dealershipId : responseJson.data?.attributes?.dealership_data?.data?.id
                }))
              return;
            }
          this.receiveCognitiveGetContactInfoData(responseJson)
      } else if (responseJson) {
        this.checkSpProfileAccount(message);
      }
      if (this.state.step == 4) {
        this.receiveCognitiveBankInfoData(apiCallId, responseJson)
      }
      this.receiveSignDocCognitive(apiCallId, responseJson);
      this.receiveCognitiveProvicerInfoData(apiCallId, responseJson)
      this.receiveCognitivegetBankInfoData(apiCallId, responseJson)
      this.receiveCognitiveTaxInfoData(apiCallId, responseJson)
      this.receiveCognitiveServicebleInfoData(apiCallId,responseJson)
      this.receiveCognitiveServiceableLocationsData(apiCallId, responseJson);
    }
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      this.handleNavigationPayloadMessage(message)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  toggleDashboardModalNewSp =() =>{
    this.setState({
      isOpenBackToDashboardModal:!this.state.isOpenBackToDashboardModal}
    )
  }
   
  receiveSpCognitive = (apiCallId:string) => {
    if (apiCallId === this.googleMaps) {
      this.changingStates();
      this.spInitAutocomplete();
      this.handleOpenPdfDocSign();
      this.handleClosePdfDocSign();
      this.spFetchPredictions("New york");
      this.spHandlePlaceSelect("car");
      this.test(google.maps.places.PlacesServiceStatus.OK, [])
      this.spHandleCognitive({value : "as", displayValue: "as", stateISOCode: "abc"}, "newStateISOCode", "city")
      this.spHandleCognitiveCallBack("as");
      this.spTestNew(google.maps.places.PlacesServiceStatus.OK, {}) 
      this.spHandleCognitiveStateChange({}, "as", {value : "as", displayValue: "as", stateISOCode: "abc"}, "as", "as", "as")
      this.spHandleCognitiveStateChangeShipping({}, "as", {value : "as", displayValue: "as", stateISOCode: "abc"}, "as", "as", "as")
      this.spHandleCognitiveStateChangeBank({}, "as", {value : "as", displayValue: "as", stateISOCode: "abc"}, "as", "as", "as")
      this.fetchSpCities("abc", "cities", )
      this.spHandleCognitiveMatchingCity({ value: "as", displayValue: "as" })
      this.spReduceCognitive();
      this.spHandleCognitiveMatchingCityBank({ value: "as", displayValue: "as" })
      this.testSpNewShipping(google.maps.places.PlacesServiceStatus.OK, {});
      this.testSpNewBank(google.maps.places.PlacesServiceStatus.OK, {});
      this.spHandleCognitivePlaceSelect({address_components: [{types: ['street_number']},{types: ['route']},{types: ['locality']},{types: ['administrative_area_level_1']},{types: ['postal_code']}]} as unknown as google.maps.places.PlaceResult)
    }
    if (apiCallId === this.googleMapsShipping) {
      this.spHandleCognitivePlaceSelectShipping({address_components: [{types: ['street_number']},{types: ['route']},{types: ['locality']},{types: ['administrative_area_level_1']},{types: ['postal_code']}]} as unknown as google.maps.places.PlaceResult)
    }
    if (apiCallId === this.googleMapsBank){
      this.spHandleCognitivePlaceSelectBank({address_components: [{types: ['street_number']},{types: ['route']},{types: ['locality']},{types: ['administrative_area_level_1']},{types: ['postal_code']}]} as unknown as google.maps.places.PlaceResult)
    }
  }

  receiveSignDocCognitive = (apiCallId : string, responseJson : ResponseJsonFetchSign)  => {
    if (apiCallId === this.getPdfSignApiCallId) {
      this.setState((prevState) => ({
        taxInfoDetails: {
          ...prevState.taxInfoDetails,
          pdfSignFile:responseJson.pdf_url,
        },
      }));
    }
  }

  spHandleFocusCognitive = (event: React.FocusEvent<HTMLInputElement>) => {
  if (this.spHandleOnFocusFullName(event)) {
    this.handleLabels(event.target.labels)
  }
}

spHandleBankZipCodeChangeCognitive = (event : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  const value = event.target.value
  if (/^\d*$/.test(value)) {
  this.spHandleBankZipCodeChange(event)
  }
}

spHandleBankZipCodeEXTChangeCognitive = (event : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  const value = event.target.value
  if (/^\d*$/.test(value)) {
  this.spHandleBankZipCodeEXTChange(event)
}
}

  spHandleOnFocusFullName = (event:React.FocusEvent<HTMLInputElement>)=>{
    return (event.target.labels && event.target.labels.length > 0)
 }

  handleLabels = (labels: NodeListOf<HTMLLabelElement> | null) => {
   if (labels && labels.length > 0) {
     const label = labels[0];
     label.style.marginLeft = '7px';
     label.style.color = "#4B4C4B";
     label.style.fontWeight = "800";
   }
 };
 
 handleSpBlurr = (event: React.FocusEvent<HTMLInputElement>)=>{
   return (event.target.value === '' && event.target.labels && event.target.labels.length > 0)
 }

 handleLabelsCss = (labels: NodeListOf<HTMLLabelElement> | null, leftmargin:string)=>{
   if (labels && labels.length > 0) {
     const label = labels[0];
     label.style.marginLeft = leftmargin;
     label.style.color = "#C5CBC9";
     label.style.fontWeight = "400";
   }
 }

  createPdfSignFormData = () => {
    const { taxInfoDetails } = this.state;
    let formData = new FormData();
    formData.append("bank_information[full_signature]", taxInfoDetails.uploadFullSignature);
    formData.append("bank_information[service_provider_id]", this.state.serviceProviderInfoId);
    return formData;
  }

  createServiceProviderFormData = () => {
    const { serviceProviderProDetails } = this.state;
    let formData = new FormData();

    formData.append("data[business_name]", serviceProviderProDetails.sp_business_name);
    formData.append("data[business_credentials]", serviceProviderProDetails.business_credentials);
    serviceProviderProDetails.checkedBoxes.forEach(item => {
      formData.append(`data[deal_type][]`, item);
    });
    formData.append("data[business_address_attributes][address]", serviceProviderProDetails.sp_street_address);
    formData.append("data[business_address_attributes][city]", serviceProviderProDetails.sp_city);
    formData.append("data[business_address_attributes][state]", serviceProviderProDetails.sp_state);
    formData.append("data[business_address_attributes][zipcode]", serviceProviderProDetails.sp_zipCode);
    formData.append("data[business_address_attributes][zipcode_ext]", serviceProviderProDetails.sp_zipCodeEXT);
    formData.append("data[shipping_address_attributes][address]", serviceProviderProDetails.sp_shipping_street_address);
    formData.append("data[shipping_address_attributes][city]", serviceProviderProDetails.sp_shipping_city);
    formData.append("data[shipping_address_attributes][state]", serviceProviderProDetails.sp_shipping_state);
    formData.append("data[shipping_address_attributes][zipcode]", serviceProviderProDetails.sp_shipping_zipCode);
    formData.append("data[shipping_address_attributes][zipcode_ext]", serviceProviderProDetails.sp_shipping_zipCodeEXT);
  
    if (serviceProviderProDetails.filePhoto) formData.append("data[service_provider_logo]", serviceProviderProDetails.filePhoto);
    else {formData.append("data[remove_service_provider_logo]", "true")};

    return formData;
  }

  spContactInfoFormData = () => {
    const { spContactInfoDetails } = this.state;
    let formData = new FormData();
    formData.append("account[full_name]", spContactInfoDetails.fullName);
    formData.append("account[email]", spContactInfoDetails.email);
    const phoneNumber = '1' + spContactInfoDetails.phoneNumber;
    formData.append("account[full_phone_number]", phoneNumber);
    return formData;
  }

  spTaxInfoFormData = () => {
    const { taxInfoDetails } = this.state;
    let formData = new FormData();
    formData.append("service_provider_information_id", this.state.serviceProviderInfoId);
    if(taxInfoDetails.taxInfoFile) formData.append("w9_forms[]", taxInfoDetails.taxInfoFile);
    return formData;
  }

  createServicableLocationFormData = () => {
    const isAddNewStateEnabled = this.isAddNewStateButton();
    const { serviceableLocations } = this.state;
    const serviceableLocationsData = [
      serviceableLocations.stateName,
      serviceableLocations.fullServicePrice,
      serviceableLocations.whiteGlovePrice,
      serviceableLocations.onSitePrice,
    ];
    const isFormFilled = serviceableLocationsData.some(Boolean);
  
    if (isFormFilled || isAddNewStateEnabled) {
      this.setState({ isSecundFormFilled: true });
    } else {
      this.setState({ isSecundFormFilled: false });
    }
  }

  handleSpTabChange = (tab : string) =>{
    this.setState({
      activeTab : tab
    })
  }

  createSpAccountWeb = () => {
    const formData = this.createServiceProviderFormData();

    const SpWebheader = {
      "token" : this.token
    };
    const WebrequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
         );
     this.spFormDataCallId = WebrequestMessage.messageId;

    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(SpWebheader)
    );

    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.action === 'edit-service-provider' ? '/bx_block_profile/service_provider_informations/' + this.state.serviceProviderInfoId : configJSON.serviceProviderInfoEndpoint
    )

    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.action === 'edit-service-provider' ? "PUT" : configJSON.httpPostMethod
    );

    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }
  

  createPdfSignWeb = () => {
    const formData = this.createPdfSignFormData();

    const SpWebheader = {
      "token" : this.token
    };
    const WebrequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
         );
     this.getPdfSignApiCallId = WebrequestMessage.messageId;

    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(SpWebheader)
    );

    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchPdfSign
    )

    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  createSpAccountWebContactInfo = () => {
    const formData = this.spContactInfoFormData();
    const SpWebheader = {"token" : this.token };
    const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.spFormDataCallId =
      WebrequestMessage.messageId;
    WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contactApiEndpoint);
    WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(SpWebheader));
    WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      formData);
    WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod);
    runEngine.sendMessage(
      WebrequestMessage.id,
      WebrequestMessage
    );
  }

  createServiceableLocations = (props?: any) => {
    const {
      serviceableLocationsList,
      serviceableLocations,
      serviceProviderInfoId
    } = this.state;
    const formData = new FormData();
    let updatedServiceableLocationsList = [...serviceableLocationsList];
    if(this.state.action !== 'edit-service-provider' && this.state.action !== 'edit-serviceable-location'){
      updatedServiceableLocationsList = [...serviceableLocationsList, serviceableLocations]; 
    }

    this.setState({
      serviceableLocationsList: updatedServiceableLocationsList,
    })

    updatedServiceableLocationsList.forEach((item: any, index: number) => {
      formData.append(
        `serviceable_locations[${index}][service_provider_information_id]`,
        serviceProviderInfoId || ""
      );
      if(item.id){
        formData.append(`serviceable_locations[${index}][id]`, item.id);
      }
      const keysToAppend = [
        { key: 'stateName', field: 'provider_state' },
        { key: 'uploadDealerLicense', field: 'dealer_license' },
        { key: 'uploadBusinessLicense', field: 'business_license' },
        { key: 'uploadDMVContract', field: 'dmv_contract' },
        { key: 'uploadBondingRegistration', field: 'insurance_requirement' },
        { key: 'uploadRegistrationServiceLicense', field: 'registration_service_license' }
      ];

      keysToAppend.forEach(({ key, field }) => {
        if (item[key]) {
          formData.append(`serviceable_locations[${index}][${field}]`, item[key]);
        }
      });

      const servicePrices = [
        { key: 'isRunner', price: 'isRunner', field: 'provider_services][runner' },
        { key: 'isFullService', price: 'fullServicePrice', field: 'provider_services][full_service' },
        { key: 'isWhiteGlove', price: 'whiteGlovePrice', field: 'provider_services][white_glove' },
        { key: 'isOnSite', price: 'onSitePrice', field: 'provider_services][on_site' }
      ];

      servicePrices.forEach(({ key, price, field }) => {
        if (item[key] && item[price] !== "") {
          formData.append(`serviceable_locations[${index}][${field}]`, item[price]);
        }
      });
    });
    const SpWebheader = { token: this.token };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.spLocationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.spServicebleLocationApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(SpWebheader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `POST`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

createSpAccountTaxInfo = () => {
  const formData = this.spTaxInfoFormData();
  const SpWebheader = {"token" : this.token};
  const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.spTaxInfoCallId = WebrequestMessage.
  messageId;
  WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.
    spTaxInfoApiEndPoint);
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(SpWebheader));
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
    formData);
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpPostMethod);
  runEngine.sendMessage(
    WebrequestMessage.id, 
    WebrequestMessage
  );
}

getSpContactInfo = async () => {
  let tokenn = await getStorageData("authToken");
  const SpWebheader = {"token" : tokenn};
  const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.getContactInfoCallId = 
  WebrequestMessage.messageId;
  WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getContactInfoAPIEndpoint);
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(SpWebheader));
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpGetMethod);
  runEngine.sendMessage(
    WebrequestMessage.id, 
    WebrequestMessage
  );
}

getBankInfo = async () => {
  let tokenn = await getStorageData("authToken");
  const Webheader = {"token" : tokenn};
  const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.bankInfoCallId = 
  WebrequestMessage.messageId;
  WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getBankInfoAPIEndpoint+`?id=${this.state.serviceProviderInfoId}&type=BxBlockProfile::ServiceProviderInformation`);
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(Webheader));
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpGetMethod);
  runEngine.sendMessage(
    WebrequestMessage.id, 
    WebrequestMessage
  );
}
getServiceableLocations = async () => {
  let getSpToken = await getStorageData("authToken"); 
  const SpWebheader = {"token" : getSpToken};
  const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.getServiceableLocationsCallId = 
  WebrequestMessage.messageId;
  WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
    "/bx_block_profile/service_provider_informations/get_provider_details");
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(SpWebheader));
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpGetMethod);
  runEngine.sendMessage(
    WebrequestMessage.id, 
    WebrequestMessage
  );
}
getSpPdfUrl = async () => {
  let getSpToken = await getStorageData("authToken"); 
  const SpWebheader = {"token" : getSpToken};
  const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.getPdfApiCallId = 
  WebrequestMessage.messageId;
  WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.fetchPdf);
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(SpWebheader));
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpGetMethod);
  runEngine.sendMessage(
    WebrequestMessage.id, 
    WebrequestMessage
  );
};

getSpTaxFormLink = async () => {
  let tokenn = await getStorageData("authToken");
  const SpWebheader = {"token" : tokenn};
  const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.getTaxInfoLinkCallId = WebrequestMessage.
  messageId;
  WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.taxInfoLinkAPIEndpoint);
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(SpWebheader));
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpGetMethod);
  runEngine.sendMessage(
    WebrequestMessage.id, 
    WebrequestMessage
  );
}

navigateSpToCreateDeal = ()=> {
  const createDealdata = new Message(getName(MessageEnum.NavigationMessage));
  createDealdata.addData(getName(MessageEnum.NavigationTargetMessage), "CreateDealMain");
  createDealdata.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(createDealdata);
}

navigateSpToDashboard = ()=> {
  const txtdata = new Message(getName(MessageEnum.NavigationMessage));
  txtdata.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
  txtdata.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  
  const raiseMessage: Message = new Message(
    getName(MessageEnum.NavigationPayLoadMessage)
  );
  raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {view: "HOME"});
  txtdata.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  runEngine.sendMessage("MergeEngineUtilities", txtdata);
}

spHandleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  let { value } = event.target;
  value = value.trimStart();
  if (/^[a-zA-Z\s]*$/.test(value)) {
    this.setState(
      (prevState) => ({
        serviceProviderProDetails: {
          ...prevState.serviceProviderProDetails,
          sp_business_name: value,
        },
      }),
      () => this.getInitials()
    );
  }
};

getInitials = ()=>{
  if (this.state.serviceProviderProDetails.sp_business_name) {
    const nameArray = this.state.serviceProviderProDetails.sp_business_name.split(' ');
    const derivedInitials = nameArray.map(name => name.charAt(0)).join('').slice(0, 2).toUpperCase();
    this.setState((prevState) => ({
      serviceProviderProDetails: {
        ...prevState.serviceProviderProDetails,
        initials: derivedInitials,
      },
    }));  
  } else {
    this.setState((prevState) => ({
      serviceProviderProDetails: {
        ...prevState.serviceProviderProDetails,
        initials: null,
      },
    }));
  }
}

loadGoogleMapsScript = () => {
  const script = document.createElement('script');
  script.src = configJSON.apiKey;
  script.async = true;
  script.defer = true;
  script.onload = this.spInitAutocomplete;
  document.head.appendChild(script);
};

changingStates = () => {
  this.setState({
    taxInfoDetails: {
      ...this.state.taxInfoDetails, 
      uploadFullSignature: 'new-signature-value',
    },
    activeSignatureView : false,
    isEsignFinish : true,
  })
}

spInitAutocomplete = () => {
  this.autocompleteService = new google.maps.places.AutocompleteService();
  this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
};

spFetchPredictions = (input: string) => {
  if (!input) {
    this.setState({ spAddressPredictions: [] });
    return;
  }

  this.autocompleteService?.getPlacePredictions(
    {
      input,
      types: ['address'],
      componentRestrictions: { country: "us" },
    },
    (predictions, status) => {
      this.test(status, predictions);
    }
  );
};

test = (status: google.maps.places.PlacesServiceStatus, predictions: google.maps.places.AutocompletePrediction[] | null) => {
  if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
    this.setState({ spAddressPredictions: predictions });
  }
}

spHandlePlaceSelect = (placeId: string) => {
  const placesService = new google.maps.places.PlacesService(document.createElement('div'));
  placesService.getDetails(
    { placeId: placeId },
    (place, status) => {
      this.spTestNew(status, place);
    }
  );
};

spTestNew = (status: google.maps.places.PlacesServiceStatus, place: google.maps.places.PlaceResult | null) => {
  if (status === google.maps.places.PlacesServiceStatus.OK && place) {
    this.spHandleCognitivePlaceSelect(place);
  }
}

spHandleCognitive = (matchingState : { value: string; displayValue: string; stateISOCode: string; } | undefined, newStateISOCode : string, city : string) => {
  if (matchingState) {
    this.fetchSpCities(newStateISOCode, 'cities', () => {
      this.spHandleCognitiveCallBack(city);
    });
  }
}

spHandleCognitiveCallBack = (city:string) => {
  const matchingCity = this.state.serviceProviderProDetails.testCities.find(
    (cityOption) => cityOption.value.toLowerCase() === city.toLowerCase()
  );

  this.spHandleCognitiveMatchingCity(matchingCity)
}

spHandleCognitivePlaceSelect = (place : google.maps.places.PlaceResult) => {
  if (place.address_components) 
  {
    let street_number = '',city = '',state = '',route = '', zipCode = '';
    for (const component of place.address_components) 
    {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number':
          street_number = component.long_name;
          break;
        case 'postal_code':
          zipCode = component.long_name;
          break;
        case 'locality':
          city = component.long_name;
          break;
        case 'route':
          route = component.long_name;
          break; case 'administrative_area_level_1':
          state = component.short_name;
          break;
      }
    }
    const streetAddress = `${street_number} ${route}`
    .trim();
    const matchingState = this.state.serviceProviderProDetails.test.find(
      (stateOption) => stateOption.stateISOCode.toLowerCase() === state.toLowerCase()
    );
    const newStateISOCode = 
    matchingState ? 
      matchingState.stateISOCode 
      : 
      '';
    this.spHandleCognitive(matchingState, newStateISOCode, city)
    this.spHandleCognitiveStateChange(place, streetAddress, matchingState, zipCode, newStateISOCode, city);
        }
}

spHandleCognitiveStateChange = (place: google.maps.places.PlaceResult, streetAddress: string, matchingState: {value: string;displayValue: string;stateISOCode: string;} | undefined, zipCode: string, newStateISOCode: string, city:string) => {
  this.setState((prevState) => ({
    serviceProviderProDetails: {
      ...prevState.serviceProviderProDetails,
      sp_address: place.formatted_address || '',
      sp_street_address: streetAddress,
      sp_state: matchingState ? matchingState.value : '',
      sp_zipCode: zipCode,
      stateISOCode: newStateISOCode,
    }
  }), 
  () => {
      const matchingCity = this.state.serviceProviderProDetails.testCities.find(
        (cityOption) => cityOption.displayValue.toLowerCase() === city.toLowerCase()
      )
     this.spHandleCognitiveMatchingCity(matchingCity);
  }
);
}

spHandleCognitiveMatchingCity = (matchingCity: CityType | undefined) => {
  if (matchingCity) {
    this.setState(prevState => ({
      serviceProviderProDetails: {
        ...prevState.serviceProviderProDetails,
        sp_city: matchingCity.value
      }
    }));
  }
}

loadGoogleMapsScriptShipping = () => {
  const script = document.createElement('script');
  script.async = true;
  script.defer = true;
  script.onload = this.initAutocompleteShipping;
  document.head.appendChild(script);
};

loadGoogleMapsScriptBank = () => {
  const script = document.createElement('script');
  script.defer = true;
  script.async = true;
  script.onload = this.initAutocompleteBank;
  document.head.appendChild(script);
};

initAutoCompleteBindData =(autoComplete: google.maps.places.AutocompleteService,placeService: google.maps.places.PlacesService)=>{
  const autoCompleteTemp = autoComplete || new google.maps.places.AutocompleteService();
  const placeServiceTemp = placeService || new google.maps.places.PlacesService(document.createElement('div'));

  autoComplete = autoCompleteTemp;
  placeService = placeServiceTemp;
}

initAutocompleteShipping = () => {
  this.initAutoCompleteBindData(this.autocompleteServiceShipping,this.placesServiceShipping)
};

initAutocompleteBank = () => {
  this.initAutoCompleteBindData(this.autocompleteServiceBank || null ,this.placesServiceBank ||null)
};

fetchPredictionsShipping = (input: string) => {
  if (!input) {
    this.setState({ spAddressPredictions: [] });
    return;
  }

  this.autocompleteService?.getPlacePredictions(
    {
      input,
      types: ['address'],
      componentRestrictions: { country: "us" },
    },
    (predictions, status) => {
      this.testShipping(status, predictions);
    }
  );
};

fetchPredictionsBank = (input: string) => {
  if (!input) {
    this.setState({ spBankAddressPredictions: [] });
    return;
  }

  this.autocompleteService?.getPlacePredictions(
    {
      input, types: ['address'],
      componentRestrictions: { country: "us" },
    },
    (predictions, status) => { this.testBank(status, predictions);
    }
  );
};

testShipping = (status: google.maps.places.PlacesServiceStatus, predictions: google.maps.places.AutocompletePrediction[] | null) => {
  if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
    this.setState({ spShippingAddressPredictions: predictions });
  }
}

testBank= (status: google.maps.places.PlacesServiceStatus, predictions: google.maps.places.AutocompletePrediction[] | null) => {
  if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
    this.setState(
      { 
      spBankAddressPredictions: 
      predictions }
    );
  }
}

handlePlaceSelectShipping = (placeId: string) => {
  const placesService = new google.maps.places.PlacesService(document.createElement('div'));
  placesService?.getDetails(
    { placeId: placeId },
    (place, status) => {
      this.testSpNewShipping(status, place);
    }
  );
};

handlePlaceSelectBank = (placeId: string) => {
  const placesService = new google.maps.places.PlacesService(document.createElement('div'));
  placesService?.getDetails(
    { placeId: placeId },
    (place, status) => { this.testSpNewBank(status, place);
    }
  );
};

testSpNewShipping = (status: google.maps.places.PlacesServiceStatus, place: google.maps.places.PlaceResult | null) => {
  if (status === google.maps.places.PlacesServiceStatus.OK && place) {
    this.spHandleCognitivePlaceSelectShipping(place);
  }
}

testSpNewBank = (status: google.maps.places.PlacesServiceStatus, place: google.maps.places.PlaceResult | null) => {
  if (status === google.maps.places.PlacesServiceStatus.OK && place) { this.spHandleCognitivePlaceSelectBank(place);
  }
}

handleCognitiveShipping = (matchingState : { value: string; displayValue: string; stateISOCode: string; } | undefined, newStateISOCode : string, city : string) => {
  if (matchingState) {
    this.fetchSpCities(newStateISOCode, 'shippingCities', () => {
      this.handleCognitiveCallbackShipping(city)
    });
  }
}

handleCognitiveBank = (matchingState : { value: string; displayValue: string; stateISOCode: string; } | undefined, newStateISOCode : string, city : string) => {
  if (matchingState) {
    this.fetchSpCities(newStateISOCode, 's', 
    () => {this.handleCognitiveCallbackBank(city)
    });
  }
}

handleCognitiveCallbackShipping = (city:string) => {
  const matchingCity = this.state.serviceProviderProDetails.testShippingCities.find(
    (cityOption) => cityOption.value.toLowerCase() === city.toLowerCase()
  );

  this.handleCognitiveMatchingCityShipping(matchingCity);
}

handleCognitiveCallbackBank = (city:string) => {
  const matchingCity = 
  this.state.spThirdFormDetailsNew.thirdFormCities.find(
    (cityOption) => cityOption.value.toLowerCase() === city.toLowerCase()
  );

  this.spHandleCognitiveMatchingCityBank(matchingCity);
}

spHandleCognitivePlaceSelectShipping = (place : google.maps.places.PlaceResult) => {
  if (place.address_components) {
    let street_number = '';
    let route = '';
    let city = '';
    let state = '';
    let zipCode = '';

    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number':
          street_number = component.long_name;
          break;
          case 'route':
          route = component.long_name;
          break;
        case 'locality':
          city = component.long_name;
          break;
        case 'administrative_area_level_1':
          state = component.short_name;
          break;
        case 'postal_code':
          zipCode = component.long_name;
          break;
      }
    }

    const streetAddress = `${street_number} ${route}`.trim();

    const matchingState = this.state.serviceProviderProDetails.test.find(
      (stateOption) => stateOption.stateISOCode.toLowerCase() === state.toLowerCase()
    );
    
    const newStateISOCode = matchingState ? matchingState.stateISOCode : '';
    this.handleCognitiveShipping(matchingState, newStateISOCode, city)
  
    this.spHandleCognitiveStateChangeShipping(place, streetAddress, matchingState, zipCode, newStateISOCode, city);
}
}

spHandleCognitivePlaceSelectBank = (place : google.maps.places.PlaceResult) => {
  if (place.address_components) {
    let zipCode = '';
    let street_number = '';
    let route = '';
    let city = '';
    let state = '';
    
    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number':
          street_number = component.long_name;
          break;
          case 'route':
          route = component.long_name;
          break;
        case 'administrative_area_level_1':
          state = component.short_name;
          break;
        case 'locality':
          city = component.long_name;
          break;
        case 'postal_code':
          zipCode = component.long_name;
          break;
      }
    }

    const streetAddress = 
    `${street_number} ${route}`.trim();

    const matchingState = 
    this.state.serviceProviderProDetails.test.find(
      (stateOption) => stateOption.stateISOCode.toLowerCase() === state.toLowerCase()
    );
    
    const newStateISOCode = 
    matchingState ? matchingState.stateISOCode : '';
    this.handleCognitiveBank(matchingState,newStateISOCode,city)
  
    this.spHandleCognitiveStateChangeBank(place, 
      streetAddress, 
      matchingState, 
      zipCode, 
      newStateISOCode, 
      city);
}
}

spHandleCognitiveStateChangeShipping = (place: google.maps.places.PlaceResult, streetAddress: string, matchingState: {value: string;displayValue: string;stateISOCode: string;} | undefined, zipCode: string, newStateISOCode: string, city:string) => {
  this.setState((prevState) => ({
    serviceProviderProDetails: {
      ...prevState.serviceProviderProDetails,
      sp_shipping_address: place.formatted_address || '',
      sp_shipping_street_address: streetAddress,
      sp_shipping_state: matchingState ? matchingState.value : '',
      sp_shipping_zipCode: zipCode,
      stateISOCode: newStateISOCode,
    }
  }), 
  () => {
      const matchingCity = this.state.serviceProviderProDetails.testShippingCities.find(
        (cityOption) => cityOption.displayValue.toLowerCase() === city.toLowerCase()
      )
     this.handleCognitiveMatchingCityShipping(matchingCity);
  }
);
}

spHandleCognitiveStateChangeBank = (place: google.maps.places.PlaceResult, streetAddress: string, matchingState: {value: string;displayValue: string;stateISOCode: string;} | undefined, zipCode: string, newStateISOCode: string, city:string) => {
  this.setState((prevState) => ({
    serviceProviderProDetails: {
      ...prevState.serviceProviderProDetails,
      stateISOCode: newStateISOCode,
    },
    spThirdFormAddressDetails: {
      ...prevState.spThirdFormAddressDetails,
      address : place.formatted_address || '',
      streetAddress : streetAddress,
      thirdFormZipCode : zipCode,
    },
    spThirdFormDetailsNew: {
      ...prevState.spThirdFormDetailsNew,
      thirdFormState : matchingState ? matchingState.value : '',
    }
  }), 
  () => {
      const matchingCity = this.state.spThirdFormDetailsNew.thirdFormCities.find((cityOption) => cityOption.displayValue.toLowerCase() === city.toLowerCase()
      )
     this.spHandleCognitiveMatchingCityBank(matchingCity);
  }
);
}

handleCognitiveMatchingCityShipping = (matchingCity: CityType | undefined) => {
  if (matchingCity) {
    this.setState(prevState => ({
      serviceProviderProDetails: {
        ...prevState.serviceProviderProDetails,
        sp_shipping_city: matchingCity.value
      }
    }));
  }
}

spHandleCognitiveMatchingCityBank = (matchingCity: CityType | undefined) => {
  if (matchingCity) {
    this.setState(prevState => ({spThirdFormDetailsNew: {
        ...prevState.spThirdFormDetailsNew, thirdFormCity: matchingCity.value
      }
    }));
  }
}

handleAddressChange = async (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  const { value } = event.target;
  this.setState((prevState) => ({
    serviceProviderProDetails: {
      ...prevState.serviceProviderProDetails,
      sp_address: value,
    },
  }));
  if (this.state.isSameAddress) {
    this.setState((prevState) => ({
      serviceProviderProDetails: {
        ...prevState.serviceProviderProDetails,
        sp_shipping_address: value
      }
    }));
  }
};

handleStreetAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  let { value } = event.target;
  value = value.trimStart();
  this.setState((prevState) => ({
    serviceProviderProDetails: {
      ...prevState.serviceProviderProDetails,
      sp_street_address: value,
    },
  }));
  if (this.state.isSameAddress) {
    this.setState((prevState) => ({
      serviceProviderProDetails: {
        ...prevState.serviceProviderProDetails,
        sp_shipping_street_address: value
      }
    }));
  }
};

handleCityChange = (event: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
  const { value } = event.target;
  this.setState((prevState) => ({
    serviceProviderProDetails: {
      ...prevState.serviceProviderProDetails,
      sp_city: value as string,
    },
  }));
  if (this.state.isSameAddress) {
    this.setState((prevState) => ({
      serviceProviderProDetails: {
        ...prevState.serviceProviderProDetails,
        sp_shipping_city: value as string
      }
    }));
  }
};

handleStateChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
  const { value } = event.target;
  const selectedState = this.state.serviceProviderProDetails.test.find(state => state.value === value);
  this.setState((prevState) => ({
    serviceProviderProDetails: {
      ...prevState.serviceProviderProDetails,
      sp_state: value as string,
      stateISOCode: selectedState ? selectedState.stateISOCode : '',
      sp_city: '', 
    },
  }), () => {
    if (selectedState) {
      this.fetchSpCities(selectedState.stateISOCode, 'cities');
    }
  });

  if (this.state.isSameAddress) {
    this.setState((prevState) => ({
      serviceProviderProDetails: {
        ...prevState.serviceProviderProDetails,
        sp_shipping_state: value as string,
        sp_shipping_city: '', 
      }
    }));
  }
};

handleThirdFormStateChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
  const { value } = event.target;
  this.setState((prevState) => ({
    spThirdFormDetailsNew: {
      ...prevState.spThirdFormDetailsNew,
      thirdFormState: value as string
    },
  })
)
};

handleThirdFormCityChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
  const { value } = event.target;
  this.setState((prevState) => ({
    spThirdFormDetailsNew: {
      ...prevState.spThirdFormDetailsNew,
      thirdFormCity: value as string
    },
  })
)
};

fetchSpCities = (selectedIsoCode: string, id: string, callback?: () => void) => {
  const cities = City.getCitiesOfState('US', selectedIsoCode).map((city) => ({
    value: city.name,
    displayValue: city.name,
  }));
  if(id==="cities")
    {
  this.setState((prevState) => ({
    serviceProviderProDetails: {
      ...prevState.serviceProviderProDetails,
      testCities: cities,
    },
  }));
}
else if (id==="shippingCities"){
  this.setState((prevState) => ({
    serviceProviderProDetails: {
      ...prevState.serviceProviderProDetails,
      testShippingCities: cities,
    },
  }));
}
else {
  this.setState((prevState) => ({
    spThirdFormDetailsNew: {
      ...prevState.spThirdFormDetailsNew,
      thirdFormCities: cities,
    },
  }));
}
};


handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
    if (/^\d{0,6}$/.test(value)) {
      this.setState(prevState => ({
        serviceProviderProDetails: {
          ...prevState.serviceProviderProDetails,
          sp_zipCode: value
        }
      }));
    }
    if (this.state.isSameAddress) {
      this.setState((prevState) => ({
        serviceProviderProDetails: {
          ...prevState.serviceProviderProDetails,
          sp_shipping_zipCode: value
        }
      }));
    }
};

handleZipCodeEXTChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
    if (/^\d{0,6}$/.test(value)) {
      this.setState(prevState => ({
        serviceProviderProDetails: {
          ...prevState.serviceProviderProDetails,
          sp_zipCodeEXT: value
        }
      }));
    }
    if (this.state.isSameAddress) {
      this.setState((prevState) => ({
        serviceProviderProDetails: {
          ...prevState.serviceProviderProDetails,
          sp_shipping_zipCodeEXT: value
        }
      }));
    }
};

handleServiceProviderShippingAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
  this.setState((prevState) => ({
    serviceProviderProDetails: {
      ...prevState.serviceProviderProDetails,
      sp_shipping_address: value,
    },
  }));
};

handleSpShippingStreetAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  let { value } = event.target;
  value = value.trimStart();
  this.setState((prevState) => ({
    serviceProviderProDetails: {
      ...prevState.serviceProviderProDetails,
      sp_shipping_street_address: value,
    },
  }));
};

handleSpShippingCityChange = (event: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
  const { value } = event.target;
  this.setState((prevState) => ({
    serviceProviderProDetails: {
      ...prevState.serviceProviderProDetails,
      sp_shipping_city: value as string,
    },
  }));
};

handleSpShippingStateChange = (event: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
  const { value } = event.target;
  this.setState((prevState) => ({
    serviceProviderProDetails: {
      ...prevState.serviceProviderProDetails,
      sp_shipping_state: value as string,
    },
  }));
};

handleSpShippingZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
  if (/^\d{0,6}$/.test(value)) {
  this.setState((prevState) => ({
    serviceProviderProDetails: {
      ...prevState.serviceProviderProDetails,
      sp_shipping_zipCode: value,
    },
  }));
}
};

handleSpShippingZipCodeEXTChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
  if (/^\d{0,6}$/.test(value)) {
  this.setState((prevState) => ({
    serviceProviderProDetails: {
      ...prevState.serviceProviderProDetails,
      sp_shipping_zipCodeEXT: value,
    },
  }));
}
};

handleBusinessChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  let { value } = event.target;
  value = value.trimStart().trimEnd();
  if (/^[\w\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]*$/.test(value)) {
    this.setState((prevState) => ({
      serviceProviderProDetails: {
        ...prevState.serviceProviderProDetails,
        business_credentials: value,
      },
    }));
  }
}

handleBankAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
  this.setState((prevState) => ({
    spThirdFormAddressDetails: {
      ...prevState.spThirdFormAddressDetails,
      address: value,
    },
  }));
};

handleBankStreetAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  let { value } = event.target;
  value = value.trimStart();
  this.setState((prevState) => ({
    spThirdFormAddressDetails: {
      ...prevState.spThirdFormAddressDetails,
      streetAddress: value,
    },
  }));
};

spHandleBankZipCodeChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  const { value } = event.target;
  if (/^\d{0,6}$/.test(value)) {
  this.setState((prevState) => ({
    spThirdFormAddressDetails: {
      ...prevState.spThirdFormAddressDetails,
      thirdFormZipCode: value,
    },
  }));
}
};

spHandleBankZipCodeEXTChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  const { value } = event.target;
  if (/^\d{0,6}$/.test(value)) {
  this.setState((prevState) => ({
    spThirdFormAddressDetails: {
      ...prevState.spThirdFormAddressDetails,
      thirdFormZipCodeEXT: value,
    },
  }));
}
};

  handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (files && files[0]) {
        const file = files[0];
        const maxSize = 1*1024*1024;

        if (file.size > maxSize) {
          this.setState({photoErrorMessage : "File size should not exceed 1 MB."});
          return;
        }
        this.setState(prevState => ({
          serviceProviderProDetails: {
            ...prevState.serviceProviderProDetails,
            profilePhoto: URL.createObjectURL(files[0]),
            filePhoto: files[0],
          },
          photoErrorMessage : ""
        }));
      }
    };
  

  handleDeletePicture = () => {
    this.setState(prevState => ({
      serviceProviderProDetails: {
        ...prevState.serviceProviderProDetails,
        profilePhoto: null,
        filePhoto: null
      },
    }));
  };

 
  handleCheckboxChangeNew  = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState((prevState) => {
        const { checkedBoxes } = prevState.serviceProviderProDetails;
  
        return {
          serviceProviderProDetails: {
            ...prevState.serviceProviderProDetails,
            checkedBoxes: event.target.checked
              ? [...checkedBoxes, "New Cars"]
              : checkedBoxes.filter(item => item !== "New Cars")
          }
        };
      });    
  };

  handleCheckboxChangeUsed  = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prevState) => {
      const { checkedBoxes } = prevState.serviceProviderProDetails;

      return {
        serviceProviderProDetails: {
          ...prevState.serviceProviderProDetails,
          checkedBoxes: event.target.checked
            ? [...checkedBoxes, "Used Cars"]
            : checkedBoxes.filter(item => item !== "Used Cars")
        }
      };
    });    
  };
  
  logout = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  changeStep = (stepNumber: number) => {
          this.setState({ step: stepNumber });
  };

  handleSkip = () => {
    const { step, skippedSteps, doneSteps } = this.state;
    if (!doneSteps.includes(step)){
      this.setState({      
        skippedSteps: [...skippedSteps, step],
      })
    }
    if (step === 5) {
      this.setState({
        isOpen: true,
        step: step + 1,
      });
    } else {
      this.setState({
        step: step + 1,
      });
    }
  }

  validateBankInfoFields = (): boolean => {
    const { spThirdFormAddressDetails, spThirdFormDetailsNew, spBankInfoFormDetails, voidedCheckFile } = this.state;

    if (spThirdFormAddressDetails.streetAddress == undefined || spThirdFormDetailsNew.thirdFormCity == undefined || spBankInfoFormDetails.bankAccountNumber == undefined)  {
      return false;
    }
  
    const addressFieldsFilled = 
      spThirdFormAddressDetails.streetAddress.trim() !== '' &&
      spThirdFormDetailsNew.thirdFormCity.trim() !== '' &&
      spThirdFormDetailsNew.thirdFormState.trim() !== '' &&
      spThirdFormAddressDetails.thirdFormZipCode.trim() !== '';
  
    const zipCodeValid = spThirdFormAddressDetails.thirdFormZipCode.length === 5;
  
    return addressFieldsFilled && 
           zipCodeValid && 
           Boolean(voidedCheckFile);
           
  };

  validateSpLocationFields = () => {
    const { serviceableLocations } = this.state;
    if(serviceableLocations.stateName == ""){
      return false
    }
  };

  bankInfoFormData = () => {
    const { spThirdFormAddressDetails, spBankInfoFormDetails, spThirdFormDetailsNew, taxInfoDetails, voidedCheckFile } = this.state;
    let formData = new FormData();
  
    formData.append("bank_information[bank_routing_number]", spBankInfoFormDetails.routingNumber.toString());
    formData.append("bank_information[account_number]",spBankInfoFormDetails.bankAccountNumber);
    formData.append("bank_information[bank_name]", spBankInfoFormDetails.bankName);
    formData.append("bank_information[confirm_account_number]", spBankInfoFormDetails.confBankAccountNumber);
    formData.append("bank_information[confirm_bank_routing_number]",spBankInfoFormDetails.confRoutingNumber);
    formData.append(`bank_information[branch_name]`, spBankInfoFormDetails.branch);
    formData.append("bank_information[name]", spBankInfoFormDetails.name);
    const phoneNumber = '1' + spBankInfoFormDetails.phoneNumber;
    formData.append("bank_information[phone_number]", phoneNumber);
    formData.append("bank_information[job_title]", spBankInfoFormDetails.jobTitle);
    formData.append("bank_information[address_attributes][address]", spThirdFormAddressDetails.streetAddress);
    formData.append("bank_information[address_attributes][city]",spThirdFormDetailsNew.thirdFormCity );
    formData.append("bank_information[address_attributes][state]", spThirdFormDetailsNew.thirdFormState);
    formData.append("bank_information[address_attributes][zipcode]", spThirdFormAddressDetails.thirdFormZipCode);
    formData.append("bank_information[address_attributes][zipcode_ext]", spThirdFormAddressDetails.thirdFormZipCodeEXT);
    formData.append("bank_information[bankable_type]", "BxBlockProfile::ServiceProviderInformation");
    formData.append("bank_information[bankable_id]", this.state.serviceProviderInfoId || "");
    formData.append("bank_information[full_signature]", taxInfoDetails.uploadFullSignature);
    formData.append("bank_information[initial_signature]", taxInfoDetails.uploadFullSignature);
    formData.append("bank_information[agree_e_sign]", "true");
 
    if(voidedCheckFile) formData.append("bank_information[voided_check]", voidedCheckFile);  
    return formData;
  }
 
  createAccountBankDetails = () => {
    const formData = this.bankInfoFormData();
    const Webheader = {"token" : this.token};
    const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.bankInfoCallId = 
    WebrequestMessage.messageId;
    WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bankInfoAPIEndpoint);
    WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader));
    WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      formData);
    WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod);
    runEngine.sendMessage(
      WebrequestMessage.id, 
      WebrequestMessage
    );
  }
 
  nextStep = () => {
    switch (this.state.step) {
      case 1:
        if (this.validateServiceProviderInfoFields()) {
          this.createSpAccountWeb()
        } 
        break; 
      case 2:
        this.createServiceableLocations()
        break;
      case 3:
        this.createSpAccountWebContactInfo();
        break;
      case 4:
        if (this.validateBankInfoFields()) {
          this.createAccountBankDetails();
        }
        break;
      case 5:
        this.createSpAccountTaxInfo();
        break;
    };
  }

  handleIsSameAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    this.setState({
      isSameAddress: isChecked
    });
  
    if (isChecked) {
      this.setState((prevState) => ({
        serviceProviderProDetails: {
          ...prevState.serviceProviderProDetails,
          sp_shipping_address: this.state.serviceProviderProDetails.sp_address,
          sp_shipping_city: this.state.serviceProviderProDetails.sp_city,
          sp_shipping_state: this.state.serviceProviderProDetails.sp_state,
          sp_shipping_street_address: this.state.serviceProviderProDetails.sp_street_address,
          sp_shipping_zipCode: this.state.serviceProviderProDetails.sp_zipCode,
          sp_shipping_zipCodeEXT: this.state.serviceProviderProDetails.sp_zipCodeEXT,
        }
      }));
    } else {
      this.setState((prevState) => ({
        serviceProviderProDetails: {
          ...prevState.serviceProviderProDetails,
          sp_shipping_address: "",
          sp_shipping_city: "",
          sp_shipping_state: "",
          sp_shipping_street_address: "",
          sp_shipping_zipCode: "",
          sp_shipping_zipCodeEXT: "",
        }
      }));
    }
  }

  validateServiceProviderInfoFields = ():any =>{
    const { serviceProviderProDetails } = this.state;
    
    if (!serviceProviderProDetails) {
      return false;
    }
  
    const requiredFields: (keyof ICreateServiceProviderDetails)[] = [
      'sp_business_name',
      'sp_city',
      'sp_state',
      'sp_street_address',
      'sp_zipCode',
      'sp_shipping_city',
      'sp_shipping_state',
      'sp_shipping_street_address',
      'sp_shipping_zipCode',
      'business_credentials'
    ];
    
    const allFieldsFilled = requiredFields.every(field => {      
      const value = serviceProviderProDetails[field] as string;
      return value !== undefined && value.trim() !== '';
    });
    
    const checkboxesFilled = serviceProviderProDetails.checkedBoxes.length > 0;

    const zipCodeFields: (keyof ICreateServiceProviderDetails)[] = [
      'sp_zipCode',
      'sp_shipping_zipCode',
    ];

    const zipCodeExtFields: (keyof ICreateServiceProviderDetails)[] = [
      'sp_zipCodeEXT',
      'sp_shipping_zipCodeEXT'
    ];

  
    const validZipCodes  = zipCodeFields.every(field => {
      const value = serviceProviderProDetails[field] as string;
      return value.length > 3 && value.length < 7;
    });
    const validZipCodeExts = zipCodeExtFields.every(field => {
      const value = serviceProviderProDetails[field] as string;
      return value.length === 0 || (value.length == 4);
    });
  
    return allFieldsFilled && checkboxesFilled && validZipCodes && validZipCodeExts;
    
  };

  returnBusinessCredentialsErrors = () => {
    return (
      this.state.serviceProviderProDetails.business_credentials.length > 0 && this.state.serviceProviderProDetails.business_credentials.length < 25
    )
  }

  returnZipCodeEXTErrors = () => {
    return (
    this.state.serviceProviderProDetails.sp_zipCodeEXT.length > 0 && this.state.serviceProviderProDetails.sp_zipCodeEXT.length < 4
    )
  }

  returnZipCodeEXTHelperText = () => {
    return (
      this.state.serviceProviderProDetails.sp_zipCodeEXT.length > 0 && this.state.serviceProviderProDetails.sp_zipCodeEXT.length < 4
    )
  }

  returnZipCodeErrors = () => {
    return (
      this.state.serviceProviderProDetails.sp_zipCode.length > 0 && this.state.serviceProviderProDetails.sp_zipCode.length < 5
    )
  }

  returnZipCodeHelperText = () => {
    return (
      this.state.serviceProviderProDetails.sp_zipCode.length > 0 && this.state.serviceProviderProDetails.sp_zipCode.length < 5    
    )
  }

  returnShippingZipCodeErrors = () => {
    return (
      this.state.serviceProviderProDetails.sp_shipping_zipCode.length > 0 && this.state.serviceProviderProDetails.sp_shipping_zipCode.length < 5
    )
  }

  returnShippingZipCodeHelperText = () => {
    return (
      this.state.serviceProviderProDetails.sp_shipping_zipCode.length > 0 && this.state.serviceProviderProDetails.sp_shipping_zipCode.length < 4
    )
  }

  returnShippingZipCodeEXTErrors = () => {
    return (
      this.state.serviceProviderProDetails.sp_shipping_zipCodeEXT.length > 0 && this.state.serviceProviderProDetails.sp_shipping_zipCodeEXT.length < 4
    )
  }

  returnShippingZipCodeEXTHelperText = () => {
    return (
      this.state.serviceProviderProDetails.sp_shipping_zipCodeEXT.length > 0 && this.state.serviceProviderProDetails.sp_shipping_zipCodeEXT.length < 4
    )
  }

  returnThirdFormBranchErrors = (branch : string, touched : boolean | undefined) => {
    if (!touched) return false;
    const cog = (/(?:^\s)|(?:\s$)/.test(branch));
    return branch.length === 0 || branch.length > 100 || cog;
  }
  
  returnThirdFormBranchHelperText = (branch: string, touched: boolean | undefined) => {
    if (!touched) return '';
    if (branch.length === 0) return 'Branch name must not be empty';
    if (branch.length > 100) return 'Branch name must be within 0-100 characters';
    if (/(?:^\s)|(?:\s$)/.test(branch)) return 'Branch name cannot start or end with a space';
    return '';
  }

  returnThirdFormBankAccountNumberErrors = (touched : FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>) => {
    return (
      touched.bankAccountNumber && !!errors.bankAccountNumber
    )
  }
  returnThirdFormBankAccountNumberHelperText = (touched : FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>) => {
    return (
      touched.bankAccountNumber && errors.bankAccountNumber
    )
  }
  returnThirdFormConfBankAccountNumberErrors = (touched : FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>) => {
    return (
      touched.confBankAccountNumber && !!errors.confBankAccountNumber
    )
  }
  returnThirdFormConfBankAccountNumberHelperText = (touched : FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>) => {
    return (
      touched.confBankAccountNumber && errors.confBankAccountNumber
    )
  }
  returnThirdFormRoutingNumberErrors = (touched : FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>) => {
    return (
      touched.routingNumber && !!errors.routingNumber
    )
  }
  returnThirdFormRoutingNumberHelperText = (touched : FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>) => {
    return (
      touched.routingNumber && errors.routingNumber
    )
  }
  returnThirdFormConfRoutingErrors = (touched : FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>) => {
    return (
      touched.confRoutingNumber && !!errors.confRoutingNumber
    )
  }
  returnThirdFormConfRoutingHelpertext = (touched : FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>) => {
    return (
      touched.confRoutingNumber && errors.confRoutingNumber
    )
  }
  returnThirdFormBankNameErrors = (touched : FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>) => {
    return (
      touched.bankName && !!errors.bankName
    )
  }
  returnThirdFormBankNameHelperText = (touched : FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>) => {
    return (
      touched.bankName && errors.bankName    
    )
  }
  returnThirdFormZipCodeErrors = () => {
    return (
      this.state.spThirdFormAddressDetails.thirdFormZipCode.length > 0 && this.state.spThirdFormAddressDetails.thirdFormZipCode.length < 5
    )
  }
  returnThirdFormZipCodeEXTErrors = () => {
    return (
      this.state.spThirdFormAddressDetails.thirdFormZipCodeEXT.length > 0 && this.state.spThirdFormAddressDetails.thirdFormZipCodeEXT.length < 4
    )
  }

  returnThirdFormZipCodeHelperText = () => {
    return (
      this.state.spThirdFormAddressDetails.thirdFormZipCode.length > 0 && this.state.spThirdFormAddressDetails.thirdFormZipCode.length < 5 ? 'Must be 5 digits' : ''
    )
  }

  returnThirdFormZipCodeEXTHelperText = () => {
    return (
      this.state.spThirdFormAddressDetails.thirdFormZipCodeEXT.length > 0 && this.state.spThirdFormAddressDetails.thirdFormZipCodeEXT.length < 4 ? 'Must be 4 digits' : ''
    )
  }
  returnThirdFormName = (touched : FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>) => {
    return (
      touched.name && !!errors.name
    )
  }
  returnThirdFormPhoneNumber = (touched : FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>) => {
    return (
      touched.phoneNumber && !!errors.phoneNumber
    )
  }
  returnThirdFormJobTitle = (touched : FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>) => {
    return (
      touched.jobTitle && !!errors.jobTitle
    )
  }

  handleBankInfoFormFilled = (isValid : boolean) => {
    if (isValid !== this.state.isBankInfoFormValid) this.setState({ isBankInfoFormValid: isValid});
  }

  thirdFormIsValidFunc = (isValid: boolean, dirty: boolean) => {
    if ((isValid && dirty) !== this.state.isThirdFormFilled) {
      this.setState({ isThirdFormFilled: isValid && dirty });
    }
  } 

  handleRegex = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, handleChange: {(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void}) => {
    const value = event.target.value
    if (/^\d*$/.test(value)) {
    handleChange(event)
}
}

  handleRegexNumber = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, handleChange: { (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void }) => {
    let value = event.target.value.replace(/\D/g, '');
    if (value.length > 6) {
      value = value.replace(/(\d{3})(\d{3})(\d{0,4})/, '$1-$2-$3');
    } else if (value.length > 3) {
      value = value.replace(/(\d{3})(\d{0,3})/, '$1-$2');
    }
    event.target.value = value;
    handleChange(event);
  }

  handleRegexAlpha = (
   event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, 
    handleChange: { (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void }
  ) => {
    const value = event.target.value;
    if (value.length === 0 || value[0] !== ' ') {
      if (/^[a-zA-Z\s]*$/.test(value)) {
        handleChange(event);
      }
    }
  };

  handleRunnerCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prevState) => ({
      serviceableLocations: {
        ...prevState.serviceableLocations,
        isRunner:event.target.checked
      },
    }));
  };
   handleFullServiceCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prevState) => ({
      serviceableLocations: {
        ...prevState.serviceableLocations,
        isFullService:event.target.checked
      },
    }));
  };
  handleWhiteGloveCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prevState) => ({
      serviceableLocations: {
        ...prevState.serviceableLocations,
        isWhiteGlove:event.target.checked
      },
    }));
  };
  handleOnSiteCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prevState) => ({
      serviceableLocations: {
        ...prevState.serviceableLocations,
        isOnSite:event.target.checked
      },
    }));
  };

onUploadDocument = (acceptedFiles: File[], type: string): void => {
  if (acceptedFiles && acceptedFiles.length > 0) {
    const file = acceptedFiles[0];
    const fileKey = `upload${type.charAt(0).toUpperCase() + type.slice(1)}`;
    const dataKey = type;

    this.setState((prevState) => ({
      serviceableLocations: {
        ...prevState.serviceableLocations,
        [fileKey]: file,
      },
    }));

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result as string;
      this.setState((prevState) => ({
        serviceableLocations: {
          ...prevState.serviceableLocations,
          [dataKey]: result,
        },
      }));
    };
    this.createServicableLocationFormData()
  }
};

 
removeDocumentFile = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, docType:string): void => {
  event.stopPropagation();
  switch (docType) {
    case "dealerLicense":
      return  this.setState((prevState) => ({
        serviceableLocations: {
            ...prevState.serviceableLocations,
            uploadDealerLicense: null,
            dealerLicense: "",
        },
    }));
    case "DMVContract":
      return  this.setState((prevState) => ({
        serviceableLocations: {
            ...prevState.serviceableLocations,
            uploadDMVContract: null,
            DMVContract: "",
        },
    }));
    case "registrationServiceLicense":
      return this.setState((prevState) => ({
        serviceableLocations: {
            ...prevState.serviceableLocations,
            uploadRegistrationServiceLicense: null,
            registrationServiceLicense: "",
        },
    }));
    case "businessLicense":
      return this.setState((prevState) => ({
        serviceableLocations: {
            ...prevState.serviceableLocations,
            uploadBusinessLicense: null,
            businessLicense: "",
        },
    }));
    case "bondingRegistration":
      this.setState((prevState) => ({
        serviceableLocations: {
          ...prevState.serviceableLocations,
          uploadBondingRegistration: null, 
          bondingRegistration: "",
        },
      }));
      break;
    default:
      return;
  }
};

handleFullServiceOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
  const numericValue = value.replace(/[^0-9.]/g, '');
  const dotCount = (numericValue.match(/\./g) || []).length;
  const formattedValue = numericValue ? `$${numericValue}` : '';
  if (dotCount <= 1 && /^(?:\$?\d+(\.\d*)?)?$|^\$?(\.\d+)?$/.test(formattedValue) || formattedValue === '') {
    this.setState((prevState) => ({
      serviceableLocations: {
        ...prevState.serviceableLocations,
        fullServicePrice: formattedValue,
      },
    }));
    this.createServicableLocationFormData()
  }
};

handleWhiteGloveOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
  const numericValue = value.replace(/[^0-9.]/g, '');
  const dotCount = (numericValue.match(/\./g) || []).length;
  const formattedValue = numericValue ? `$${numericValue}` : '';
  if (dotCount <= 1 && /^(?:\$?\d+(\.\d*)?)?$|^\$?(\.\d+)?$/.test(formattedValue) || formattedValue === '') {
    this.setState((prevState) => ({
      serviceableLocations: {
        ...prevState.serviceableLocations,
        whiteGlovePrice: formattedValue,
      },
    }));
    this.createServicableLocationFormData()
  }
};

handleOnSiteOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
  const numericValue = value.replace(/[^0-9.]/g, '');
  const dotCount = (numericValue.match(/\./g) || []).length;
  const formattedValue = numericValue ? `$${numericValue}` : '';
  if (dotCount <= 1 && /^(?:\$?\d+(\.\d*)?)?$|^\$?(\.\d+)?$/.test(formattedValue) || formattedValue === '') {
    this.setState((prevState) => ({
      serviceableLocations: {
        ...prevState.serviceableLocations,
        onSitePrice: formattedValue,
      },
    }));
    this.createServicableLocationFormData()
  }
};


  isAddNewStateButton = () => {
    return (!!this.state.serviceableLocations.stateName && ((this.state.serviceableLocations.isFullService || this.state.serviceableLocations.isOnSite || this.state.serviceableLocations.isWhiteGlove || this.state.serviceableLocations.isRunner) &&  (this.state.serviceableLocations.isFullService ? !!this.state.serviceableLocations.fullServicePrice : true) && (this.state.serviceableLocations.isOnSite ? !!this.state.serviceableLocations.onSitePrice : true) && (this.state.serviceableLocations.isWhiteGlove ? !!this.state.serviceableLocations.whiteGlovePrice : true)))
  }

  getAddNewStateButtonText= ()=>{
      if (this.state.serviceableLocations.isEditServiceable){
        return "Apply Changes";
      }else {
        return "Add Another State";
      }

  }

  findEditableServiceIndex = () => {
    const index = this.state.serviceableLocationsList.findIndex(service => service.isEditServiceable);
    return index;
  };
  blankObject= {
    id: "",
    stateName: "",
    isFullService:false,
    isOnSite:false,
    isRunner:false,
    isWhiteGlove:false,
    whiteGlovePrice:"",
    onSitePrice:"",
    fullServicePrice:"",
    bondingRegistration:"",
    uploadBondingRegistration:null,
    dealerLicense:"",
    uploadDealerLicense:null,
    DMVContract:"",
    uploadDMVContract:null,
    registrationServiceLicense:"",
    uploadRegistrationServiceLicense:null,
    businessLicense: "",
    uploadBusinessLicense: null,
    isEditServiceable:false
  }
addAnotherStateButtonClick=()=>{
  const activeStateData=this.state.serviceableLocations;

  if (activeStateData.isEditServiceable){
    const indexOfLocation=this.findEditableServiceIndex();
    const tempServiceableLocationsList = [...this.state.serviceableLocationsList];
    if (indexOfLocation >= 0 && indexOfLocation < tempServiceableLocationsList.length) {
      tempServiceableLocationsList[indexOfLocation] = {...activeStateData,isEditServiceable:false}
      this.setState({ serviceableLocations:this.blankObject, serviceableLocationsList:tempServiceableLocationsList });
    }
  }else{
    this.setState((prevState) => ({
      serviceableLocationsList:[...prevState.serviceableLocationsList,activeStateData],
      serviceableLocations:this.blankObject
    }));
  }
}

deleteAddAnotherState (serviceableLocationId: string){
  const Webheader = {"token" : this.token};
  const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.deleteServiceableLocationId = WebrequestMessage.messageId;
  WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "/bx_block_profile/serviceable_locations/" + serviceableLocationId);
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(Webheader));
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'DELETE');
  runEngine.sendMessage( WebrequestMessage.id, WebrequestMessage);
}

onEditServiceLocation = async (id:string) => {
  let indexOfLocation = this.state.serviceableLocationsList.findIndex(location => location.id === id);
  const stateToEdit = this.state.serviceableLocationsList[indexOfLocation];
  const tempServiceableLocationsList = [...this.state.serviceableLocationsList];
  this.setState({
    serviceableLocations: {
      ...stateToEdit,
      isEditServiceable: true,
    },
  });
  if (indexOfLocation >= 0 && indexOfLocation < tempServiceableLocationsList.length) {
    tempServiceableLocationsList.forEach(location => location.isEditServiceable = false);
    tempServiceableLocationsList[indexOfLocation] = { ...tempServiceableLocationsList[indexOfLocation], isEditServiceable: true };
    this.setState({ serviceableLocationsList:tempServiceableLocationsList, });
  } 
};

onDropNew = (acceptedFiles: File[]) => {
  if (acceptedFiles && acceptedFiles.length>0){
    this.setState((prevState) => ({
      taxInfoDetails: {
        ...prevState.taxInfoDetails,
        taxInfoFile: acceptedFiles[0],
      },
    }));  
  } 
}

  onDrop = (acceptedFiles: File[]): void => {
    if (acceptedFiles && acceptedFiles.length>0){
      this.setState((prevState) => ({
        taxInfoDetails: {
          ...prevState.taxInfoDetails,
          taxInfoFile: acceptedFiles[0],
        },
      }));  
    } 
       
    const reader = new FileReader()
    reader.readAsDataURL(acceptedFiles[0])
    reader.onload = () => {
      const result = reader.result as string;
      this.setState({
        taxInfoDetails: {
          ...this.state.taxInfoDetails,
          taxInfoFormLink: result,
        },
      });
    }
  }

  onDropFullSignature = (acceptedFiles: File[]): void => {
    if (acceptedFiles && acceptedFiles.length > 0) {
        this.setState((prevState) => ({
            taxInfoDetails: {
                ...prevState.taxInfoDetails,
                fullSignatureUploadView: acceptedFiles[0],
            },
        }));

        const reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onload = () => {
            const result = reader.result as string;

            this.setState((prevState) => ({
                taxInfoDetails: {
                    ...prevState.taxInfoDetails,
                    uploadFullSignature: result,
                },
                mergeSignature: true
            }));
        };
    }
};

onDropInitialSignature = (acceptedFiles: File[]): void => {
    if (acceptedFiles && acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => {
            const result = reader.result as string;
            this.setState((prevState) => ({
                taxInfoDetails: {
                    ...prevState.taxInfoDetails,
                    initialSignatureUploadView: file,
                    uploadIntitalSignture: result,
                },
            }));
        };
    }
};

removeFullSignatureFile = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
  event.stopPropagation();
  
  this.setState((prevState) => ({
      taxInfoDetails: {
          ...prevState.taxInfoDetails,
          fullSignatureUploadView: null,
          uploadFullSignature: "",
      },
  }));
};

removeShowFullSignatureFile = (): void => {
  this.setState((prevState) => ({
      taxInfoDetails: {
          ...prevState.taxInfoDetails,
          fullSignatureUploadView: null,
          initialSignatureUploadView:null,
          uploadFullSignature: "",
          uploadIntitalSignture:"",
          drawSignature:""
      },
  }));
};

removeInitialSignatureFile = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
  if (event) {
      event.stopPropagation();
  }
  this.setState((prevState) => ({
      taxInfoDetails: {
          ...prevState.taxInfoDetails,
          initialSignatureUploadView: null,
          uploadIntitalSignture: "",
      },
  }));
};


  handleopenPdfPopup = (scrollType:any) => () => {
    this.setState((prevState) => ({
      taxInfoDetails: {
        ...prevState.taxInfoDetails,
        isContractPdfView: true
      }
    }));
  };

  handleOpenPdfDocSign = () => {
    this.setState((prevState) => ({
      taxInfoDetails: {
        ...prevState.taxInfoDetails,
        isContractPdfViewSign: true,
      }
    }));
  };


  handleContractPDFViewClose = () => {
    this.setState((prevState) => ({
      taxInfoDetails: {
        ...prevState.taxInfoDetails,
        isContractPdfView: false,
        isPdfCheck:false
      }
    }));
  };

  handleClosePdfDocSign = () => {
    this.setState((prevState) => ({
      taxInfoDetails: {
        ...prevState.taxInfoDetails,
        isContractPdfViewSign: false,
      }
    }));
  };

  handleSignatureOpen = () => {
    this.setState({
      activeSignatureView: true
    });
    this.handleContractPDFViewClose();
  }

  handleSignatureClose = () =>{
    this.setState((prevState) => ({
      activeSignatureView: false,
      taxInfoDetails: {
          ...prevState.taxInfoDetails,
          isSignCheck: false,
      },
      activeTab:"tab1"
  }));
  }

  handleIsPdfBtn = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    this.setState((prevState) => ({
      taxInfoDetails: {
          ...prevState.taxInfoDetails,
          isPdfCheck: isChecked,
      },
    }));
  }


  handleIsSigneturCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    this.setState((prevState) => ({
      taxInfoDetails: {
          ...prevState.taxInfoDetails,
          isSignCheck: isChecked,
      },
    }));
  }


onDropVoidedCheck = (acceptedFiles: File[]): void => {
  if (acceptedFiles && acceptedFiles.length > 0) {
    const file = acceptedFiles[0];

    this.setState({
      voidedCheckFile: file
    });

    if (file.type !== "application/pdf") {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result as string;
        this.setState({
          voidedCheckUrl: result
        });
      };
    }
  }
};

  removeFile = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>):void => {
    if (event) {
      event.stopPropagation();
    }
    this.setState((prevState) => ({
      taxInfoDetails: {
        ...prevState.taxInfoDetails,
        taxInfoFile: null,
      },
    }));
  } 

  removeFileVoidedCheck = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>):void => {
    if (event) {
      event.stopPropagation();
    }
    this.setState(({
        voidedCheckFile: null,
    }));
  } 

  spReduceCognitive = () => {
    this.initAutocompleteShipping();
        this.fetchPredictionsShipping("New york");
        this.handlePlaceSelectShipping("car");
        this.testShipping(google.maps.places.PlacesServiceStatus.OK, [])
        this.handleCognitiveShipping({value : "as", displayValue: "as", stateISOCode: "abc"}, "newStateISOCode", "city")
        this.handleCognitiveCallbackShipping("as");
        this.fetchSpCities("abc", "shippingCities", )
        this.handleCognitiveMatchingCityShipping({value: "as", displayValue:"as"})
        this.initAutocompleteBank();
        this.fetchPredictionsBank("New york");
        this.fetchPredictionsBank("");
        this.handlePlaceSelectBank("car");
        this.testBank(google.maps.places.PlacesServiceStatus.OK, [])
        this.handleCognitiveBank({value : "as", displayValue: "as", stateISOCode: "abc"}, "newStateISOCode", "city")
        this.handleCognitiveCallbackBank("as");
  }
  
  validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .required('Full Name is required')
      .min(3, 'Full Name must be at least 3 characters'),
    phoneNumber: Yup.string()
      .required('Phone Number is required')
      .matches(/^\d+$/, "Phone number can only contain digits")
      .matches(/^\d+$/, 'Number must be digits only')
      .length(10, 'Phone Number must have exactly 10 digits'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
  });

  validationSchemaThirdForm = Yup.object().shape({
    bankAccountNumber: Yup.string()
    .required('Account number must be 8 -12 digits ,Please enter a valid bank account number.')
    .matches(/^\d+$/, 'Bank account number must contain only digits')
    .min(8, 'Account number must be 8 -12 digits ,Please enter a valid bank account number.')
    .max(12, 'Account number must be 8 -12 digits ,Please enter a valid bank account number.'),
    confBankAccountNumber: Yup.string()
    .required('Confirm Bank account number must match')
    .oneOf([Yup.ref('bankAccountNumber')], 'Confirm Bank account number must match')
    .min(8, 'Confirm Bank account number must match')
    .max(12, 'Confirm Bank account number must match'),
    routingNumber: Yup.string()
    .required('Routing number is required')
    .min(9, '9 digits required')
    .max(9, '9 digits required'),
    confRoutingNumber: Yup.string()
    .required('Confirm Routing number must match')
    .oneOf([Yup.ref('routingNumber')], 'Confirm Routing number must match'),
    bankName: Yup.string()
    .matches(/^\S(.*\S)?$/, 'Spaces are only allowed between characters, not at the start or end')
    .required('Bank name is required')
    .max(100, 'Max 100 letters allowed'),
    branch: Yup.string()
    .matches(/^\S(.*\S)?$/, 'Spaces are only allowed between characters, not at the start or end')
    .required('Bank name is required')
    .max(100, 'Max 100 letters allowed'),
    thirdFormZipCode: Yup.string()
    .required('Zip code is required'),
    thirdFormZipCodeEXT: Yup.string()
    .required('Zip code EXT is required'),
    name: Yup.string()
    .required('Name is required')
    .max(100, 'Max 100 letters allowed'),
    phoneNumber: Yup.string()
    .required('Phone Number is required')
    .min(11, '10 digits required')
    .max(12, '10 digits required'),
    jobTitle: Yup.string()
    .required('Job Title is required')
    .max(100, 'Max 100 letters allowed'),
  })

  handleChangeAttributeValue = (value: string, index: number) => {
    const temporaryValue = this.state.attributes;
    temporaryValue[index].value = value;
    this.setState({ attributes: temporaryValue });
  };

  editServiceableLocation = (prevState: Readonly<S>) => {
    if(prevState.serviceableLocationsList !== this.state.serviceableLocationsList &&
      this.state.action === 'edit-serviceable-location' && !this.state.serviceableLocations.isEditServiceable
    ){
      this.onEditServiceLocation(this.state.serviceableLocations.id)
    }
  }
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void { 
    this.updateFunc(prevState);
    this.spUpdateBankInfoFunc(prevState);
    this.editServiceableLocation(prevState);
    if ((this.state.taxInfoDetails.uploadFullSignature !== prevState.taxInfoDetails.uploadFullSignature) && (this.state.taxInfoDetails.uploadFullSignature != "") && this.state.mergeSignature) {
      this.createPdfSignWeb();
    }
    if (this.state.serviceProviderProDetails.sp_state !== prevState.serviceProviderProDetails.sp_state) {
      let selectedStateISOCode = this.state.serviceProviderProDetails.test.find((state) => state.value === this.state.serviceProviderProDetails.sp_state)?.stateISOCode;
  
      if (!selectedStateISOCode) {
        selectedStateISOCode=''
      }
      this.fetchSpCities(selectedStateISOCode, "cities");
    }
    if (this.state.serviceProviderInfoId &&  this.state.serviceProviderInfoId !== prevState.serviceProviderInfoId) { 
      this.getSpContactInfo();
      this.getBankInfo();
    }
    if (this.state.serviceProviderProDetails.sp_shipping_state !== prevState.serviceProviderProDetails.sp_shipping_state) {
      let selectedStateISOCode = this.state.serviceProviderProDetails.test.find((state) => state.value === this.state.serviceProviderProDetails.sp_shipping_state)?.stateISOCode;
  
      if (!selectedStateISOCode) {
        selectedStateISOCode=''
      }
        this.fetchSpCities(selectedStateISOCode, "shippingCities");
      
    }
    if (this.state.spThirdFormDetailsNew.thirdFormState !== prevState.spThirdFormDetailsNew.thirdFormState) {
      let selectedStateISOCode = this.state.serviceProviderProDetails.test.find((state) => state.value === this.state.spThirdFormDetailsNew.thirdFormState)?.stateISOCode;
  
      if (!selectedStateISOCode) {
        selectedStateISOCode=''
      }
        this.fetchSpCities(selectedStateISOCode, "thirdFormCities");
      
    }
    if (this.state.taxInfoDetails.taxInfoFile != prevState.taxInfoDetails.taxInfoFile) this.handleFourthFormFilled()
}
  checkForRequiredFields = () => {
    const RequiredField = this.state.attributes.map((item: SpIAttribute) => {
      if (
        item.is_required &&
        !(item.value as string).trim() &&
        item.is_enable
      ) {
        alert(`Please Enter ${item.name}`);
        return false;
      }
      return true;
    });
    if (RequiredField.includes(false)) {
      return false;
    } 
  };

  updateFunc = (prevState: S) => {
    if (JSON.stringify(prevState.serviceProviderProDetails) !== JSON.stringify(this.state.serviceProviderProDetails)) {
      const isFormValid = this.validateServiceProviderInfoFields();
      if (isFormValid !== this.state.isFormFilled) {
        this.setState({ isFormFilled: isFormValid });
      }
    }
  }

  spUpdateBankInfoFunc = (prevState: S) => {
    if (
      JSON.stringify(prevState.spThirdFormAddressDetails) !== JSON.stringify(this.state.spThirdFormAddressDetails) ||
      JSON.stringify(prevState.spThirdFormDetailsNew) !== JSON.stringify(this.state.spThirdFormDetailsNew) ||
      JSON.stringify(prevState.spBankInfoFormDetails) !== JSON.stringify(this.state.spBankInfoFormDetails) ||
      prevState.voidedCheckFile !== this.state.voidedCheckFile) {
      const isBankFormValid = this.validateBankInfoFields();
      this.setState({ isThirdFormFilled: isBankFormValid });
    }
  }

  handleFormChange = (values : SpContactInfoDetails) => {
    this.setState({
      spContactInfoDetails: values
    });
  }

  handleContactFormFilled = (isValid : boolean) => {
    this.setState({ isContactFormFilled: isValid});
  }

  handleFourthFormFilled = () => {
    if (this.state.taxInfoDetails.taxInfoFile != null) 
      this.setState({isFourthFormFilled : true})
  else this.setState({isFourthFormFilled : false})
}

handleBankInfoFormChange = (values: SpBankInfoFormDetails) => {
    this.setState({spBankInfoFormDetails:values})
    this.forceUpdate();
  }

  toggleExpand = () => {
    this.setState(prevState => ({isExpanded : !prevState.isExpanded}))
  }

  toggleExpandBank = () => {
    this.setState(prevState => ({isExpandedBank : !prevState.isExpandedBank}))
  }

  toggleExpandBankAddress= () => {
    this.setState(prevState => ({isExpandedBankAddress : !prevState.isExpandedBankAddress}))
  }

  toggleExpandBankAuth= () => {
    this.setState(prevState => ({isExpandedBankAuth : !prevState.isExpandedBankAuth}))
  }

  isNextButtonEnabled = () => {
    if(this.state.action === 'edit-service-provider' || this.state.action === 'edit-serviceable-location'){
      return true;
    }
    switch (this.state.step) {
      case 1:
        return this.state.isFormFilled;
      case 2:
        return this.state.isSecundFormFilled &&  this.isAddNewStateButton();
      case 3:
        return this.state.isContactFormFilled;
      case 4:
        return this.state.isThirdFormFilled && this.state.taxInfoDetails.uploadFullSignature.length>0
      case 5:
        return this.state.isFourthFormFilled;
      default:
        return false;
    }
  }
  navigateToMyTeam = () => {
    const navigationMessage = new Message(getName(MessageEnum.NavigationMessage));
    navigationMessage.addData(getName(MessageEnum.NavigationTargetMessage), "MyTeam");
    navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  
    const payloadMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    payloadMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {view: "MY_TEAM", editSP: true});
    navigationMessage.addData(getName(MessageEnum.NavigationRaiseMessage), payloadMessage);
    runEngine.sendMessage("MergeEngineUtilities", navigationMessage);
  }
  handleCognitiveTaxInfoCallId = (responseJson:TaxInfoErrorResponse) => {
    if (!responseJson.errors) {
      if(this.state.action === 'edit-service-provider'){
        this.navigateToMyTeam();
      }
      else{
        this.setState({ 
        errorsTaxInfoBackend : '',
        isOpen:true,
        doneSteps: [...this.state.doneSteps, this.state.step], skippedSteps:this.state.skippedSteps.filter(skippedStep => skippedStep!==this.state.step)
      });
      }
    }
    else { 
        const errors = responseJson.errors[0];
        const errorKey = Object.keys(errors)[0]; 
      
        switch (errorKey) {
          case 'token':
            this.setState({ errorsTaxInfoBackend: errors.token });
            break;
          case 'message':
            this.setState({ errorsTaxInfoBackend: errors.message });
            break;
        }
    }
  }

  handleCognitiveProvicerInfoDataCallId = (responseJson:any) => {
    if (!responseJson.error) {
      this.setState({ 
      errorServiceProviderBackend : '',
      step: this.state.step + 1, doneSteps: [...this.state.doneSteps, this.state.step], skippedSteps:this.state.skippedSteps.filter(skippedStep => skippedStep!==this.state.step)
      });
      if (responseJson.data.id) {
        this.setState({
          serviceProviderInfoId: responseJson.data.id,
        });
      }
    }
    else { 
      if (responseJson.errors && responseJson.errors[0] && responseJson.errors[0].token) {
        this.setState({ errorServiceProviderBackend: responseJson.errors[0].token });
      } else {
        this.setState({ errorServiceProviderBackend: 'Unknown error occurred' });
      }
    }
  }

  receiveCognitiveProvicerInfoData = (apiCallId : string, responseJson : any) => {
    if (apiCallId === this.spFormDataCallId) {
      this.handleCognitiveProvicerInfoDataCallId(responseJson);
    }
  }

  receiveCognitiveBankInfoData = (apiCallId:string, responseJson:BankInfoErrorResponse & ServiceProviderInfoSuccessResponse) => {
    if (apiCallId === this.bankInfoCallId) {
      this.handleCognitiveBankInfoCallId(responseJson);
    }
  }


  handleCognitiveBankInfoCallId = (responseJson:BankInfoErrorResponse & ServiceProviderInfoSuccessResponse) => {
    if (!responseJson.errors) {
      this.setState({ 
      bankDetailsId: responseJson.data.id,
      errorsBankInfoBackend : '',
      step: this.state.step + 1, doneSteps: [...this.state.doneSteps, this.state.step], skippedSteps:this.state.skippedSteps.filter(skippedStep => skippedStep!==this.state.step)
     });
     this.getSpContactInfo()
    }
    else { 
      const errors = responseJson.errors[0];
      if (errors) {
        const errorKey = Object.keys(errors)[0];
        switch (errorKey) {
          case 'token':
            this.setState({ errorsBankInfoBackend: errors.token });
            break;
          case 'account':
            this.setState({ errorsBankInfoBackend: errors.account });
            break;
          case 'account_number':
            this.setState({ errorsBankInfoBackend: errors.account_number });
            break
        }
      }
    }
  }

  receiveCognitiveTaxInfoData = (apiCallId:string, responseJson:TaxInfoErrorResponse) => {
    if (apiCallId === this.spTaxInfoCallId) {
      this.handleCognitiveTaxInfoCallId(responseJson);
    }
  }
  
  receiveCognitiveServicebleInfoData = (apiCallId:string, responseJson:any) => {
  if (apiCallId === this.spLocationCallId) {
      this.handleCognitiveservicebleLocationCallId(responseJson);
    }
  }
  getFileFromLink = (serviceableLocation: ICreateServiceableLocations, businessLicense: string, dealerLicense: string, 
                    DMVContract: string, registrationServiceLicense: string, bondingRegistration: string) => {
    if(businessLicense){
      this.getBlobFromFile(businessLicense).then((blob)=> {
        serviceableLocation.uploadBusinessLicense = new File([blob], this.getFileNameFromUrl(businessLicense), {type: 'application/pdf'});
      });
    }
    if(dealerLicense){
    this.getBlobFromFile(dealerLicense).then((blob)=> {
      serviceableLocation.uploadDealerLicense = new File([blob], this.getFileNameFromUrl(dealerLicense), {type: 'application/pdf'});
    });  }
    if(DMVContract){
    this.getBlobFromFile(DMVContract).then((blob)=> {
      serviceableLocation.uploadDMVContract = new File([blob], this.getFileNameFromUrl(DMVContract), {type: 'application/pdf'});
    });  }
    if(registrationServiceLicense){
    this.getBlobFromFile(registrationServiceLicense).then((blob)=> {
      serviceableLocation.uploadRegistrationServiceLicense = new File([blob], this.getFileNameFromUrl(registrationServiceLicense), {type: 'application/pdf'});
    });  }
    if(bondingRegistration){
    this.getBlobFromFile(bondingRegistration).then((blob)=> {
      serviceableLocation.uploadBondingRegistration = new File([blob], this.getFileNameFromUrl(bondingRegistration), {type: 'application/pdf'});
    });  }
  }
  receiveCognitiveServiceableLocationsData = async (apiCallId:string, responseJson:any) => {
    if (apiCallId === this.getServiceableLocationsCallId){
      const fullSignatureUrl = responseJson.data.attributes.banking_info.data.attributes.full_signature;
      if (fullSignatureUrl) {
        const fileUrl = String(fullSignatureUrl)
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const filename = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
        const extension = filename.substring(filename.lastIndexOf('.'));
        const mimeType = `image/${extension.substring(1)}`;
        const file = new File([blob], filename, { type: mimeType });
        this.setState({
          activeSignatureView: false,
          isEsignFinish: true,
          taxInfoDetails:{
            ...this.state.taxInfoDetails,
            uploadFullSignature: fullSignatureUrl,
            fullSignatureUploadView: file
          }
        })
      }
      const w9FormUrl = responseJson.data.attributes.w9_forms[0].url; 
      if (w9FormUrl) {
        const fileUrl = String(w9FormUrl)
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const filename = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
        const extension = filename.substring(filename.lastIndexOf('.'));
        const mimeType = `image/${extension.substring(1)}`;
        const file = new File([blob], filename, { type: mimeType });
        this.setState({
          taxInfoDetails:{
            ...this.state.taxInfoDetails,
            taxInfoFormLink: w9FormUrl,
            taxInfoFile: file
          }
        })
      }

      let serviceableLocations : ICreateServiceableLocations[] = [];
      responseJson.data.attributes.serviceable_locations.data.forEach((location: any) => {

        let businessLicense = location.attributes.business_license;
        let dealerLicense = location.attributes.dealer_license;
        let DMVContract = location.attributes.dmv_contract;
        let registrationServiceLicense = location.attributes.registration_service_license;
        let bondingRegistration = location.attributes.insurance_requirement;

        let serviceableLocation : ICreateServiceableLocations = {
          ...this.blankObject,
          id: location.id,
          stateName: location.attributes.provider_state,
          businessLicense: businessLicense,
          dealerLicense: dealerLicense,
          DMVContract: DMVContract,
          registrationServiceLicense: registrationServiceLicense,
          bondingRegistration: bondingRegistration,
          isRunner: Boolean(location.attributes.provider_services.runner),
          isOnSite: Boolean(location.attributes.provider_services.on_site),
          onSitePrice: location.attributes.provider_services.on_site,
          isFullService: Boolean(location.attributes.provider_services.full_service),
          fullServicePrice: location.attributes.provider_services.full_service,
          isWhiteGlove: Boolean(location.attributes.provider_services.white_glove),
          whiteGlovePrice: location.attributes.provider_services.white_glove
        };
        this.getFileFromLink(serviceableLocation, businessLicense, dealerLicense, DMVContract, registrationServiceLicense, bondingRegistration);
        serviceableLocations.push(serviceableLocation)
      })
      setTimeout(()=>{
        this.setState({
          serviceProviderInfoId: responseJson.data.id,
          serviceableLocationsList: serviceableLocations,
          oldServiceableLocationsList: serviceableLocations,
          serviceProviderProDetails:{
            ...this.state.serviceProviderProDetails,
            profilePhoto: responseJson.data.attributes.service_provider_logo,
            sp_business_name: responseJson.data.attributes.business_name,
            business_credentials: responseJson.data.attributes.business_credentials,
            sp_street_address: responseJson.data.attributes.business_address.address,
            sp_state: responseJson.data.attributes.business_address.state,
            checkedBoxes:responseJson?.data.attributes.deal_type.map(this.mapDealType),
            sp_city: responseJson.data.attributes.business_address.city,
            sp_zipCode: responseJson.data.attributes.business_address.zipcode,    
            sp_zipCodeEXT: responseJson.data.attributes.business_address.zipcode_ext,
            sp_shipping_street_address: responseJson.data.attributes.shipping_address.address,
            sp_shipping_state: responseJson.data.attributes.shipping_address.state,
            sp_shipping_city: responseJson.data.attributes.shipping_address.city,
            sp_shipping_zipCode: responseJson.data.attributes.shipping_address.zipcode,
            sp_shipping_zipCodeEXT: responseJson.data.attributes.shipping_address.zipcode_ext,
          },
          isSameAddress: responseJson.data.attributes.business_address.address === responseJson.data.attributes.shipping_address.address ? true : false,
        })
      }, 3000)
      
    } 
  }

  handleCognitiveservicebleLocationCallId = (responseJson:SpLocationErrorResponse & ServiceProviderInfoSuccessResponse) => {
    if (!responseJson.errors) {
      if(this.state.action === 'add-serviceable-location'){
        this.setState({
          isOpen: true,
          errorsSpLocationBackend : '',
        })
      }
      else if(this.state.action === 'edit-serviceable-location'){
        this.navigateToMyTeam();
      }
      else{
        this.setState({ 
        spLocationId: responseJson.data.id,
        errorsSpLocationBackend : '',
        step: this.state.step + 1, doneSteps: [...this.state.doneSteps, this.state.step], skippedSteps:this.state.skippedSteps.filter(skippedStep => skippedStep!==this.state.step)
        })
      }}
    else { 
      if (responseJson.errors && responseJson.errors[0] && responseJson.errors[0].state) {
        this.setState({ errorsSpLocationBackend: responseJson.errors[0].state });
      } else {
        this.setState({ errorsSpLocationBackend: 'Unknown error occurred' });
      }
    }
  }

  getInitialsProfilePicture = (fullName : string) => {
    const nameArray = fullName.split(' ');
    const derivedInitials = nameArray.map(name => name.charAt(0)).join('').slice(0, 2).toUpperCase();
    this.setState((prevState) => ({
      serviceProviderProDetails: {
        ...prevState.serviceProviderProDetails,
        initials: derivedInitials,
      },
    }));  
}
  receiveCognitiveGetMoreContactinfoData = (responseJson: ResponseData) => {
   
    if (responseJson.data.attributes.provider_information.data.attributes.contact_info.name != "") {
      this.setState({
        doneSteps: [...this.state.doneSteps, 3],
      })
    }
    if (responseJson.data.attributes.provider_information.data.attributes.business_name != null) {
      this.setState({
        doneSteps: [...this.state.doneSteps, 1],
      })
    }
    if (responseJson.data.attributes.provider_information.data.attributes.serviceable_locations.data.length > 0) {
      this.setState({
        doneSteps: [...this.state.doneSteps, 2],
      })
    }
  }

  getBase64FromUrl = async (url: string): Promise<string> => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
    });
};

  getFileType = (url: string): string => {
      const extension = url.split('.').pop()?.toLowerCase() || '';
      const mimeTypes: { [key: string]: string } = {
          'jpg': 'image/jpeg',
          'jpeg': 'image/jpeg',
          'png': 'image/png',
          'pdf': 'application/pdf',
      };
      return mimeTypes[extension] || 'application/octet-stream';
  };

  dealTypeMap: { [key: string]: string } = {
    "new car": "New Cars",
    "used car": "Used Cars"
  };
  
  mapDealType = (type: string): string => {      return this.dealTypeMap[type] || type;
  };

  receiveCognitiveGetContactInfoData = async (responseJson: ResponseData & ContactInfoErrorResponse) => {
    
    if (!responseJson.errors) {
      this.setState((prevState) => ({
        spContactInfoDetails: {
          ...prevState.spContactInfoDetails,
          fullName: responseJson.data.attributes.provider_information.data.attributes.contact_info.name,
          phoneNumber: responseJson.data.attributes.phone_number,
          email: responseJson.data.attributes.provider_information.data.attributes.contact_info.email
        },
      }));
      const providerInfo = responseJson.data.attributes.provider_information.data;
      const isBusinessNameNull = providerInfo.attributes.business_name === null;
      const isProviderPending = responseJson.data.attributes.is_provider_pending;
      const hasResponseDataId = responseJson.data.id;

      if (isBusinessNameNull && hasResponseDataId && isProviderPending) {
        this.setState({
          additionalBoolForApi: true,
          serviceProviderInfoId: providerInfo.id,
        });
      }
      else this.setState({})
      if (responseJson.data.attributes.provider_information.data.attributes.business_name)
        {
          this.setState((prevState) => ({
            serviceProviderProDetails: {
              ...prevState.serviceProviderProDetails,
              profilePhoto: responseJson.data.attributes.profile_picture,
              sp_business_name: responseJson.data.attributes.provider_information.data.attributes.business_name,
              business_credentials: responseJson.data.attributes.provider_information.data.attributes.business_credentials,
              sp_street_address: responseJson.data.attributes.provider_information.data.attributes.business_address.address,
              sp_state: responseJson.data.attributes.provider_information.data.attributes.business_address.state,
              checkedBoxes:responseJson?.data.attributes.provider_information.data.attributes.deal_type.map(this.mapDealType),
              sp_city: responseJson.data.attributes.provider_information.data.attributes.business_address.city,
              sp_zipCode: responseJson.data.attributes.provider_information.data.attributes.business_address.zipcode,
              sp_zipCodeEXT: responseJson.data.attributes.provider_information.data.attributes.business_address.zipcode_ext,
              sp_shipping_street_address: responseJson.data.attributes.provider_information.data.attributes.shipping_address.address,
              sp_shipping_state: responseJson.data.attributes.provider_information.data.attributes.shipping_address.state,
              sp_shipping_city: responseJson.data.attributes.provider_information.data.attributes.shipping_address.city,
              sp_shipping_zipCode: responseJson.data.attributes.provider_information.data.attributes.shipping_address.zipcode,
              sp_shipping_zipCodeEXT: responseJson.data.attributes.provider_information.data.attributes.shipping_address.zipcode_ext,
            }
          }), () => {
            this.getInitialsProfilePicture(this.state.spContactInfoDetails.fullName);
            this.getInitials();
          })
        }

      if (!responseJson.errors && responseJson.data.attributes.provider_information.data.id) {
        this.setState({ 
          serviceProviderInfoId: responseJson.data.attributes.provider_information.data.id
       });
      }
      const uploadFiles = async (fileUrls: { [key: string]: string }) => {
        const results = await Promise.all(
          Object.keys(fileUrls).map(async (key) => {
            const url = fileUrls[key];
            return {
              name: url.split('/').pop() || '',
              type: this.getFileType(url) || "",
              data: await this.getBase64FromUrl(url) || ""
            };
          })
        );
        return results.reduce((acc, { name, type, data }, index) => {
          const keys = Object.keys(fileUrls);
          acc[keys[index]] = data;
          acc[`upload${keys[index].replace(/^\w/, c => c.toUpperCase())}`] = { name, type };
          return acc || {};
        }, {} as { [key: string]: any });
      };
      
      if (responseJson.data.attributes.provider_information.data.attributes.serviceable_locations.data.length > 0) {
        const serviceableLocationsData = responseJson.data.attributes.provider_information.data.attributes.serviceable_locations.data[0];
        if (responseJson.data.attributes.provider_information.data.attributes.serviceable_locations.data.length > 1) {
          const resp = responseJson.data.attributes.provider_information.data.attributes.serviceable_locations.data
          const respDataArr: any = []
          await resp?.map(async (item: any, index: number) => {
            if (index != 0) {
              const fileUrls = {
                businessLicense: await responseJson.data.attributes.provider_information.data.attributes.serviceable_locations.data[index].attributes.business_license,
                bondingRegistration: await responseJson.data.attributes.provider_information.data.attributes.serviceable_locations.data[index].attributes.insurance_requirement,
                dealerLicense: await responseJson.data.attributes.provider_information.data.attributes.serviceable_locations.data[index].attributes.dealer_license,
                DMVContract: await responseJson.data.attributes.provider_information.data.attributes.serviceable_locations.data[index].attributes.dmv_contract,
                registrationServiceLicense: await responseJson.data.attributes.provider_information.data.attributes.serviceable_locations.data[index].attributes.registration_service_license
              };
      
              const filteredObj = Object.fromEntries(
                Object.entries(fileUrls).filter(([key, value]) => value !== null)
              );
      
              const uploadedFilesObj = await uploadFiles(filteredObj);
             await respDataArr.push({
                stateName: responseJson.data.attributes.provider_information.data.attributes.serviceable_locations.data[index].attributes.provider_state || "",
                isFullService: responseJson.data.attributes.provider_information.data.attributes.serviceable_locations.data[index].attributes.provider_services.full_service ? true : false,
                isOnSite: responseJson.data.attributes.provider_information.data.attributes.serviceable_locations.data[index].attributes.provider_services.on_site ? true : false,
                isRunner: responseJson.data.attributes.provider_information.data.attributes.serviceable_locations.data[index].attributes.provider_services.isRunner,
                isWhiteGlove: responseJson.data.attributes.provider_information.data.attributes.serviceable_locations.data[index].attributes.provider_services.white_glove ? true : false,
                whiteGlovePrice: responseJson.data.attributes.provider_information.data.attributes.serviceable_locations.data[index].attributes.provider_services.white_glove,
               onSitePrice: responseJson.data.attributes.provider_information.data.attributes.serviceable_locations.data[index].attributes.provider_services.on_site,
               fullServicePrice: responseJson.data.attributes.provider_information.data.attributes.serviceable_locations.data[index].attributes.provider_services.full_service,
                isEditServiceable: false,
                ...uploadedFilesObj
              })
              return respDataArr;
            }
          })
          this.setState({
            serviceableLocationsList:respDataArr,
          });
        }
      

        const fileUrls = {
          businessLicense: serviceableLocationsData.attributes.business_license,
          bondingRegistration: serviceableLocationsData.attributes.insurance_requirement,
          dealerLicense: serviceableLocationsData.attributes.dealer_license,
          DMVContract: serviceableLocationsData.attributes.dmv_contract,
          registrationServiceLicense: serviceableLocationsData.attributes.registration_service_license
        };

        const filteredObj = Object.fromEntries(
          Object.entries(fileUrls).filter(([key, value]) => value !== null)
        );

        const uploadedFiles = await uploadFiles(filteredObj);

        this.setState((prevState) => ({
          serviceableLocations: {
            ...prevState.serviceableLocations,
            stateName: serviceableLocationsData.attributes.provider_state || "",
            onSitePrice: serviceableLocationsData.attributes.provider_services.on_site || "",
            whiteGlovePrice: serviceableLocationsData.attributes.provider_services.white_glove || "",
            fullServicePrice: serviceableLocationsData.attributes.provider_services.full_service || "",
            isFullService: serviceableLocationsData.attributes.provider_services.full_service ? true : false,
            isOnSite: serviceableLocationsData.attributes.provider_services.on_site ? true : false,
            isRunner: serviceableLocationsData.attributes.provider_services.isRunner,
            isWhiteGlove: serviceableLocationsData.attributes.provider_services.white_glove ? true : false,
            ...uploadedFiles
          }
        }));

        const serviceableLocationsDataList: string[] = [
          serviceableLocationsData.attributes.provider_state || "",
          serviceableLocationsData.attributes.provider_services.full_service || "",
          serviceableLocationsData.attributes.provider_services.white_glove || "",
          serviceableLocationsData.attributes.provider_services.on_site || "",
        ];
        const isFormFilled = serviceableLocationsDataList.some(Boolean);
        if (isFormFilled) {
          this.setState({ isSecundFormFilled: true });
        }
      } 
      
      this.receiveCognitiveGetMoreContactinfoData(responseJson);
    }
  }
  
  handleFocus = () => {
    this.setState({ label: 'State' });
  };

  receiveCognitivegetBankInfoData = async (apiCallId: string, responseJson: BankInfoErrorResponse & BankInformation) => {
    if (apiCallId == this.bankInfoCallId) {
      if (!responseJson.errors) {
        this.setState((prevState) => ({
          spBankInfoFormDetails: {
            ...prevState.spBankInfoFormDetails,
            bankName: responseJson.data.attributes.bank_name,
            bankAccountNumber: responseJson.data.attributes.account_number,
            confBankAccountNumber: responseJson.data.attributes.account_number,
            routingNumber: responseJson.data.attributes.bank_routing_number,
            confRoutingNumber: responseJson.data.attributes.bank_routing_number,
            branch: responseJson.data.attributes.branch_name,
            name: responseJson.data.attributes.name,
            phoneNumber: responseJson.data.attributes.phone_number,
            jobTitle: responseJson.data.attributes.job_title,
          },
          spThirdFormAddressDetails: {
            ...prevState.spThirdFormAddressDetails,
            streetAddress: responseJson.data.attributes.address.data.attributes.address,
            thirdFormZipCode: responseJson.data.attributes.address.data.attributes.zipcode,
            thirdFormZipCodeEXT: responseJson.data.attributes.address.data.attributes.zipcode_ext,
          },
          spThirdFormDetailsNew: {
            ...prevState.spThirdFormDetailsNew,
            thirdFormState: responseJson.data.attributes.address.data.attributes.state,
            thirdFormCity: responseJson.data.attributes.address.data.attributes.city,
          }
        }))
        if (responseJson.data.attributes.bank_name != "") {
          this.setState({
            doneSteps: [...this.state.doneSteps, 4],
          })
        }
        this.getSpContactInfo();
        if (responseJson.data.attributes.voided_check) {
          const fileUrl = String(responseJson.data.attributes.voided_check)
          const response = await fetch(fileUrl);
          const blob = await response.blob();
          const filename = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
          const extension = filename.substring(filename.lastIndexOf('.'));
          const mimeType = `image/${extension.substring(1)}`;
          const file = new File([blob], filename, { type: mimeType });
          this.setState({
            voidedCheckFile: file,
          })
        }
      }
      else return;
    }
  }

  navigateToDashboard = ()=> {
    const navigationMessage = new Message(getName(MessageEnum.NavigationMessage));
    navigationMessage.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
    navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  
    const payloadMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    payloadMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {view: "HOME"});
    navigationMessage.addData(getName(MessageEnum.NavigationRaiseMessage), payloadMessage);
    runEngine.sendMessage("MergeEngineUtilities", navigationMessage);
  }
  // Customizable Area End
}
