import { Document } from "../../projecttemplates/src/ProjectTemplatesDealDashboardController.web";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
  // Customizable Area Start
  compareDocMode: boolean;
  triggerCompareDoc: () => void;
  basicInfos: { [key: string]: any };
  selectedFile: Document;
  files: Document[];
  fileIcon: (file: Document) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstFile: Document;
  secondFile: Document;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectTemplatesDocumentsCompareController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area 
  handleSelectSecondFile = (event: any) => {
    const id = event.target.value as string;
    const secondFile = this.props.files.find(file => file.id === id)!;
    this.setState({
      secondFile: secondFile
    })
  }

  handleSelectFirstFile = (event: any) => {
    const id = event.target.value as string;
    const firstFile = this.props.files.find(file => file.id === id)!;
    this.setState({
      firstFile: firstFile
    })
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      firstFile: this.props.selectedFile,
      secondFile: {} as Document,
    }
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevProps.selectedFile !== this.props.selectedFile){
      this.setState({
        firstFile: this.props.selectedFile
      })
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
}