import React from "react";
// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SavedDealController, { Props } from "./SavedDealController";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography, Box, FormControl, InputAdornment
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  },
  spacing: 8
});

const styles= {
  containerStyle: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "300px",
    marginBottom: "500px",
    gap: "0px"
  },
  tableHeadCell: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: '500px',
    lineHeight: "22px",
    color: "#C5CBC9",
  },
  cellTitle:{
    display: 'flex',
    justifyCotent: 'center',
    alignItems: 'center',
    gap: 10
  }
};

export default class SavedDeal extends SavedDealController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {showSearchBar} = this.props;
    console.log(this.state.rows);
    return (
      <>
        <Box
          margin="auto"
          display={"flex"}
          flexDirection={"column"}
          style={{ gap: "24px" }}
          maxWidth={"1144px"}
          width={"100%"}
        >
          {this.props.checkHeader == "true" ? (
            <Box>
              <Typography
                style={{
                  fontFamily: "Gotham",
                  fontSize: "30px",
                  fontWeight: 300,
                  lineHeight: "40px",
                  textAlign: "left",
                  color: "#013D4F"
                }}
              >
                Pick up where you left off
              </Typography>

              <Typography
                style={{
                  fontFamily: "Gotham",
                  fontSize: "18px",
                  fontWeight: 300,
                  lineHeight: "18px",
                  textAlign: "left",
                  color: "#4B4C4B",
                  marginTop: "5px"
                }}
              >
                Continue Creating Your Deal
              </Typography>
            </Box>
          ) : null}
          <Box
            display={"flex"}
            minWidth={"1144px"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "24px",
                textAlign: "left",
                color: "#013D4F"
              }}
            >
              Your Saved Deals
            </Typography>
            {showSearchBar && 
              <Box>
                <FormControl style={{ margin: 0 }}>
                  <TextField
                    size="small"
                    onChange={this.handleSearchInput}
                    placeholder="Search Deals"

                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      disableUnderline: true
                    }}
                  />
                </FormControl>
              </Box>
            }
          </Box>
        </Box>
        <Box minWidth={"1144px"} width="100%" margin={"auto"}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={styles.tableHeadCell}>
                    <Box style={styles.cellTitle}>Customer Last Name <KeyboardArrowDownIcon style={{width: 20, height: 20}}/></Box>
                  </TableCell>
                  <TableCell style={styles.tableHeadCell}>
                    <Box style={styles.cellTitle}>Deal ID <KeyboardArrowDownIcon style={{width: 20, height: 20}}/></Box>
                  </TableCell>
                  <TableCell style={styles.tableHeadCell}>
                    <Box style={styles.cellTitle}>Date & Time <KeyboardArrowDownIcon style={{width: 20, height: 20}}/></Box>
                  </TableCell>
                  <TableCell style={styles.tableHeadCell}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.filteredDeals.map((row: any, index: any) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{
                        fontFamily: "Roboto",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "22px",
                        textAlign: "left",
                        color: "#4B4C4B"
                      }}
                    >
                      {row?.attributes?.name}
                    </TableCell>
                    <TableCell>{row?.attributes?.id}</TableCell>
                    <TableCell>{row?.attributes?.date_and_ime}</TableCell>
                    <TableCell>
                      <Typography
                        data-test-id={`continue_editing_btn/${index}`}
                        style={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "24px",
                          textAlign: "left",
                          color: "#013D4F",
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          localStorage.setItem("dId", row?.attributes?.id);
                          this.props.handleModal()
                          this.navigateToCreatedeal()
                        }}
                      >
                        Continue Editing
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    );
  }
}
