import React from "react";
// Customizable Area Start
import { Box,
  Button,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  Typography,
  TextareaAutosize,
  styled,
  TextField,
  MenuItem,
  Menu,
  Divider,
  Modal,
  createTheme,
  Grid
 } from "@material-ui/core";
 import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
 import {
  notifications
} from "../../../blocks/dashboard/src/assets";
import { logo } from "../../user-profile-basic/src/assets"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { userDesign, send} from "./assets"
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import { MailOutlineOutlined } from "@material-ui/icons";
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import BlockController, {
  Props,
} from "./ContactusController";

export default class Block extends BlockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  optionForcontactus = [
    'Cancel / delete a deal',
    'Issue with Service Provider',
    'Payment issue',
    'Technical issue',
    'Other',
]

  headerr = () => {
    return (
      <Box sx={webStyles.header}>
        <Box sx={webStyles.headerLeftSide}>
          <Button data-test-id="menu" style={webStyles.menuButton}>
            <MenuRoundedIcon style={{ color: '#C5CBC9' }} />
          </Button>

          <Box sx={webStyles.logo} style={{ marginLeft: '-10px' }}>
            <img style={{ marginBottom: '5px',width:'52px', height:'24px', }} src={logo} alt="vitu logo" />
          </Box>
        </Box>

        <Box sx={webStyles.headerRightSide}>
          <Box
            data-test-id=""
            sx={webStyles.notificationsButton}
          >
            <img src={notifications} alt="notifications button" />
          </Box>

          <Box style={webStyles.userAvatar}>
            <Avatar
              className="avatar"
              variant="square"
              onClick={(event)=>this.handleOpenMenu(event)}
              src={this.state.profilePhoto ? this.state.profilePhoto : ''}
              style={{
                width: "102%",
                height: "102%",
                backgroundColor: 'rgb(240, 250, 250)', 
                borderRadius: "24px",   
                color: '#013D4F'
              }}
            >
               {this.state.initials}
            </Avatar>
          </Box>
        </Box>
      </Box>
    )
  }

tellusmoreBox=()=>{
  if(this.state.reasonContactUs==='Other'){
  return(
    <Box style={{ marginTop: '20px' }}>
    <TextField
      data-test-id="tellmore"
      type="text"
      name="tellmore"
      label={this.state.label}
      variant="outlined"
      fullWidth
      inputProps={{ maxLength: 50 }}
      InputProps={{
        style: { borderRadius: '8px', paddingLeft:'5px' },
      }}
      InputLabelProps={{
        style: { color: this.state.tellusMoreMessage ? '#000' :'#C5CBC9' }
      }}
      value={this.state.tellusMoreMessage}
      onChange={this.handleTellUsMoreMessage}
      onFocus={this.handleFocus}
      onBlur={this.handleBlur}
      />
    </Box>
  )}
  return null
}

logoutModal = ()=>{
  return(
    <Modal
    open={this.state.isOpenLogoutModal}
    onClose={this.toggleLogoutModal}
    aria-describedby="simple-modal-description"
    aria-labelledby="simple-modal-title"
    style={{  display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
 }}
  >
    <StyledModalBox >
      <Box display={'flex'} style={{width:'545px'}} justifyContent={'end'} >
        <Button onClick={this.toggleLogoutModal} data-test-id="closebtn"><CloseIcon/></Button>
      </Box>

     <Box>
      <Box><Typography style={{color:'#013D4F', fontWeight:500, fontSize:'24px',marginBottom:'12px', fontFamily:'Roboto'}}>Log Out</Typography></Box>
      <Typography style={{color:'#4B4C4B',fontSize:'18px',fontWeight:300, fontFamily:'Gotham light'}}>Are you sure you want to log out?</Typography>
     </Box>

     <Divider style={{ height:'2px', width:'113%', marginLeft:'-33px',backgroundColor:'#F0FAFA', marginTop:'30px'}}/>
     
     <Box style={{display:'flex', marginTop:'20px',justifyContent:'end', }}>
      <Button data-test-id="cancellogout" variant="outlined" onClick={this.toggleLogoutModal} style={{height:'56px', border:'1px solid #013D4F', width:'156px', marginRight:'15px', borderRadius:'8px'}}>
        <Typography style={{color:'#013D4F',fontSize:'16px', fontWeight:700, textTransform:'none',fontFamily:'Roboto'}}>
          Cancel
        </Typography>
      </Button>
      <Button data-test-id="finallogout" variant="contained" onClick={this.gotoLogout} style={{ width:'156px',height:'56px', backgroundColor:'#4FC8EC', borderRadius:'8px'}}>
        <Typography style={{color:'white', textTransform:'none', fontSize:'16px', fontFamily:'Roboto', fontWeight:700, }}>
          Log Out
        </Typography>
      </Button>
     </Box>
    </StyledModalBox>
</Modal>
  )
}

profileMenuDropdownn=()=>{
  return(
    <Menu
    id="simple-menu"
    anchorEl={this.state.anchorEl}
    open={Boolean(this.state.anchorEl)}
    keepMounted
    onClose={this.handleCloseMenu}
    PaperProps={{
      style: {
        padding: '10px 0', 
        borderRadius: 8, 
        width: '270px', 
      },
    }}
  >
   
    <Box display="flex" alignItems="center" pt={0}  p={2}>
      <Avatar 
        style={{
         height:'57px',
         width:'57px', 
         color: '#013D4F',
         backgroundColor: 'rgb(240, 250, 250)',
         }} 
         alt="Admin'sName" src={this.state.profilePhoto ? this.state.profilePhoto : ''}>
        {this.state.initials}
      </Avatar>

      <Box ml={2}>
        <Typography style={{ fontSize:'20px', fontWeight:400, color:'#4B4C4B', fontFamily:'Roboto' }}>
          {this.state.fullName}
        </Typography>
        <Typography style={{ fontWeight: 400, fontSize:'16px',  fontFamily:'Roboto', color:'#C5CBC9', }}>
          Dealer/sp Group Name
        </Typography>
      </Box>
    </Box>

  <Box style={{gap:'15px',marginTop:'7px'}}>
    <MenuItem data-test-id="editprofile" onClick={this.gotoEditProfile} style={{gap:'15px'}} >
      <PersonOutlineOutlinedIcon style={{color:'#013D4F'}}/>
      <Typography style={{fontWeight:400, color:'#4B4C4B', fontSize:'16px',fontFamily:'Roboto'}}>Edit Profile</Typography>
    </MenuItem>

    <MenuItem onClick={this.handleCloseMenu} data-test-id="contactusprofile" style={{marginTop:'15px', gap:'15px'}} >
      <MailOutlineOutlined style={{color:'#013D4F'}}/>
      <Typography style={{fontSize:'16px',fontFamily:'Roboto', color:'#4B4C4B',  fontWeight:400}} >Contact Us</Typography>
    </MenuItem>

    <MenuItem style={{marginTop:'15px',gap:'15px'}}>
      <SettingsOutlinedIcon style={{color:'#013D4F'}}/>
      <Typography style={{fontSize:'16px', color:'#4B4C4B', fontWeight:400, fontFamily:'Roboto'}}>Settings</Typography>
    </MenuItem>

    <Divider style={{marginTop:'10px',  marginLeft:'20px', width:'230px',}}/>

    <MenuItem data-test-id="logoutprofile"  onClick={this.toggleLogoutModal} style={{gap:'15px',marginTop:'25px'}}>
      <ExitToAppOutlinedIcon style={{color:'#013D4F',transform: 'rotateY(180deg)'}}/>
      <Typography style={{fontWeight:400, fontSize:'16px', color:'#4B4C4B', fontFamily:'Roboto'}}>Log Out</Typography>
    </MenuItem>
  </Box>
  </Menu>
  )
}

toastCalll = () => {
  toast(
    <>
      <style>{`
      .Toastify__toast-theme--colored.Toastify__toast--default{
        margin: 0px ;
        padding:0px; 
      }
      .Toastify__toast-body{
        min-width: 100%;
        padding:0px;
      }
      .Toastify__toast{
        padding:0px;
        border-radius:12px;
      }
    `}</style>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',justifyContent: 'space-between', 
          alignItems: 'center',  border: '1px solid #013D4F',color: ' #006064',
          borderRadius: '12px',  padding: '0px 24px',height: '65px',
          boxShadow: '0px 16px 48px 0px rgba(1, 61, 79, 0.22)'
        }}>

        <Box style={{
          display: 'flex', flexDirection: 'row',
           gap: '5px', alignItems: 'center',
        }}>
          <Box>
            <img src={send} style={{height:'16px', width:'16px'}}/>
          </Box>
          <Box style={{marginLeft:'10px'}}><Typography style={{  color: '#013D4F', fontSize: '16px', fontWeight:400, fontFamily:'Roboto'}}>Message Sent</Typography></Box>
        </Box>
        <Box><CloseIcon style={{ color: '#013D4F' }} /></Box>
      </Box>
    </>,
    {
      position: "top-center",
      hideProgressBar: false,
      autoClose: 5000,
      closeOnClick: true,
      draggable: true,
      pauseOnHover: true,
      theme: "colored",
    });

   this.toastFalse();
}

  leftSideForm=()=>{
    return(
      <Box style={{display:'flex', justifyContent:'center',  flexDirection:'column',alignItems:'center',  height:'100%',
      position: 'relative', 
      overflow: 'hidden'
       }}>
       
     <ContactUsMainBox className="maincontent" style={{ marginTop:'25px', display:'flex', justifyContent:'center', flexDirection:'column', maxWidth:'512px'}}>
      <Box>
          <Box className="gothamLightText " style={{fontWeight:300,color:'#013D4F',fontSize:'30px'}}>Get in Touch with Us.</Box>
          <Typography className="gothamText" style={{marginTop:'10px',fontWeight:300,color:'#4B4C4B',fontSize:'18px'}}>Get in touch with us. Fill out the form below, and we’ll respond promptly. We look forward to assisting you.</Typography>
      </Box>

      <Box style={{ marginTop: '30px' }}>
              <TextField
                data-test-id="emailtest2"
                type="email"
                name="email"
                label="Email Address"
                variant="outlined"
                fullWidth
                InputProps={{
                  style: { borderRadius: '8px', paddingLeft:'5px', color:'#C5CBC9' },
                }}
                InputLabelProps={{
                  style: { color: '#000' }
                }}
                disabled={true}
                value={this.state.email}
              />
            </Box>

      <FormControl variant="outlined" style={{width:'100%', marginTop:'20px'}}>
          <InputLabel id="demo-simple-select-outlined-label" style={{color: this.state.reasonContactUs ? '#000' : '#C5CBC9',}}>Subject</InputLabel>
          <Select
          className="selecttest"
          data-test-id="selectoption"
          labelId="contactus-reason-label"
          id="contactus-reason"
          value={this.state.reasonContactUs}
          onChange={this.handlerReasonContactus}
          IconComponent={ExpandMoreIcon}
           label="Subject"
          style={{
            borderRadius: '8px', 
            paddingLeft:'8px'
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            getContentAnchorEl: null,

            PaperProps: {
              style: {
                maxHeight: '800px',
                borderRadius: '8px',
              },
            },
          }}
          inputProps={{
            style: {
              borderRadius: '8px',  
            },
          }}
        >
          {this.optionForcontactus.map((option, index) => (
            <MenuItemStyled key={index} value={option} style={{height:'60px', color:'rgba(1, 61, 79, 1)', fontSize:'16px', fontWeight:300}}>
              {option}
            </MenuItemStyled>
          ))}
        </Select>
      </FormControl>

      
        {this.tellusmoreBox()} 
     


      <Box style={{position:'relative', paddingTop:'3px'}}>
      <Box 
        border={1} 
        borderColor="#C5CBC9" 
        borderRadius={8} 
        p={1} 
        pr={2}
        style={{marginTop:'20px', display: 'flex',flexDirection:'column', width:'95%',  justifyContent:'center'}}
      >
      
      <style>
      {`.textarea-red-placeholder::placeholder {
        color: #C5CBC9;
        opacity: 1;
      }`}
      </style>
      <TextareaAutosize  
      className="textarea-red-placeholder"
        data-test-id="textarea"
        value={this.state.contactusMessage}
        onChange={this.handleTextAreaChangee} aria-label="minimum height" minRows={3} 
        maxLength={250} 
        placeholder="Please describe your issue, ask a question, or simply share your feedback." 
        style={{minHeight: '100px', marginTop:'10px', height:'100px',outline: 'none', width:'100%', border:'none', fontSize:'16px', fontWeight:400, fontFamily:'Roboto', paddingLeft:'5px', paddingRight:'5px', resize:'vertical', maxHeight:'200px'}}
      />


      <Box style={{display:'flex', justifyContent:'flex-end', width:'100%'}}>
       <Typography className="gothamText" style={{ fontWeight:300, fontSize:'12px', color:'rgba(51, 65, 85, 1)'}}>{this.state.charCount}/250</Typography>
      </Box>
      </Box>
    </Box>

          <Buttondb onClick={this.contactUsBe} data-test-id="submitbtnn" disabled={this.getDisability()}   variant="contained" style={{width:'100%', height: '56px', marginTop:'25px' }}>
            <Typography style={{fontSize:'16px', textTransform: 'none', fontWeight:700}}>Send Message</Typography>
          </Buttondb>

          {this.state.openToast && this.toastCalll()}

  
      </ContactUsMainBox>  
    
      <Box
        style={{
          position: "absolute",
          bottom: 0,
          left: 0, 
          maxHeight: "350px", 
          maxWidth: "350px",
          zIndex:-1
        }}
      >
        <img
          src={userDesign}
          alt="Design Element"
          style={{
            maxHeight: "100%",
            maxWidth: "100%",
            width: "auto",
            height: "auto",
            transform: "scaleX(-1)",
          }}
        />
      </Box>
   </Box>
    )
  }

  rightSideBox = ()=>{
    return(
      <Box 
      style={{
        backgroundColor: '#F0FAFA',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
         padding: '40px',
        height: '100vh',
        borderTopLeftRadius: '150px',
        position: "relative", 
        overflow: "hidden"
      }}
    >

      <Box style={{display:'flex', flexDirection:'column', alignItems:'start'}}>
        <Typography 
          style={{ 
            fontWeight: 300, 
            fontSize: '30px', 
            color: '#013D4F', 
            marginBottom: '10px',
            fontFamily:'Gotham light',
          }}
        >
          Other ways to contact us
        </Typography>
        
        <Typography 
          style={{ 
            fontWeight: 300, 
            fontSize: '18px', 
            color: '#4B4C4B', 
            marginBottom: '30px',
            fontFamily:'Gotham light',
          }}
        >
          We're always open to your questions or feedback.
        </Typography>
      </Box>
      
      
      <RightBox 
        style={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          width: '100%',
          marginBottom: '30px',
          gap:'50px'
        }}
      >
        <Box>
          <Typography 
            style={{ 
              fontWeight: 600, 
              fontSize: '18px', 
              color: '#4B4C4B', 
              marginBottom: '10px',
              fontFamily:'Roboto'
            }}
          >
            Email
          </Typography>
          
          <Typography 
            style={{ 
              fontFamily:'Roboto',
              fontWeight: 400, 
              fontSize: '14px', 
              color: '#4B4C4B'
            }}
          >
            For general inquiries, email us at
          </Typography>

          <Typography 
            style={{ 
              fontWeight: 600, 
              fontFamily:'Roboto',
              fontSize: '20px', 
              color: '#013D4F'
            }}
          >
            info@vitu.com
          </Typography>
        </Box>

        <Box>
          <Typography 
            style={{ 
              fontWeight: 600, 
              fontSize: '18px', 
              color: '#4B4C4B', 
              marginBottom: '10px',
              fontFamily:'Roboto'
            }}
          >
            Phone
          </Typography>

          <Typography 
            style={{ 
              fontWeight: 400, 
              fontSize: '14px', 
              fontFamily:'Roboto',
              color: '#4B4C4B'
            }}
          >
            Reach out to customer support 24/7/365
          </Typography>

          <Typography 
            style={{ 
              fontWeight: 600, 
              fontFamily:'Roboto',
              fontSize: '20px', 
              color: '#013D4F'
            }}
          >
            844-848-8468
          </Typography>
        </Box>
      </RightBox>

      <Box
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          width: "auto",
          height: "auto",
          maxHeight: "350px", 
          maxWidth: "350px", 
        }}
      >
        <img
          src={userDesign}
          alt="Design Element"
          style={{
            maxHeight: "100%",
            maxWidth: "100%",
            width: "auto",
            height: "auto",
          }}
        />
      </Box>

    </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
        // Customizable Area Start
      <Box height={'100vh'} style={{overflowX:'hidden'}}>
          {this.headerr()}
          {this.profileMenuDropdownn()}
          {this.logoutModal()}
        <Grid container style={{width:'100%'}}>
          <Grid item lg={8} md={8} sm={8} xs={12} className="leftbox" style={{width:'60%'}}>
           {this.leftSideForm()}
          </Grid>

          <StyledGrid item lg={4} md={4} sm={4} xs={12} className="rightBox" style={{width:'40%'}}>
            {this.rightSideBox()}
          </StyledGrid>
        </Grid>
        <ToastContainer
          style={{ width: '450px' }}
        />
      </Box>
        // Customizable Area End
        );
  }
}

// Customizable Area Start
const StyledModalBox = styled(Box)({
  width: '512px',
  border: '1px solid #FFFFFF',
  boxShadow: theme.shadows[5],
  backgroundColor:'#FFFFFF',
  padding: theme.spacing(2, 4, 3),
  borderRadius:'8px',
})
const StyledGrid = styled(Grid)({
  '@media (max-width: 600px)':{
    marginTop:'15px'
  },
})

const RightBox = styled(Box)({
  "@media (max-width:1080px)": {
    flexDirection:'column'
  },
  "@media (min-width:1081px)": {
    flexDirection:'row'
    }
  
})

const MenuItemStyled = styled(MenuItem)({
  '&.Mui-selected': {
    backgroundColor: 'rgba(240, 250, 250, 1)',  
  },
  '&:hover': {
    backgroundColor: 'rgba(240, 250, 250, 1)',  
  },
  
})

const Buttondb = styled(Button)({
  '&:disabled': {
    width: '100%',
    color: '#75D3F0',
    height: '50px',
    backgroundColor: '#F0FAFA',
  },
  '&:enabled': {
    width: '100%',
    color: 'white',
    backgroundColor: '#4FC8EC',
    height: '50px',
  },

});


const ContactUsMainBox = styled(Box)({
  "@media (max-width:1200px)": {
      width:'60%'
    },
    "@media (min-width:1201px)": { width:'80%',}
})
interface Styles {
  [key: string]: any;
}
const webStyles: Styles = {
  container: {
    flexDirection: "column",
    height: "100%",
    display: "flex",
    width: "100%",
  },
  header: {
    flexWrap: "nowrap",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #F0FAFA",
    padding: "12px 32px",
  },

  

  menuButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: "24px",
    width: "24px",
    cursor: "pointer",
    border: "none",
    padding: "unset",
  },

  headerLeftSide: { display: "flex",
    flexWrap: "nowrap",
    gap: "0px",
    alignItems: "center",
  },

  logo: {width: "52px"},

  


  notificationsButton: {
    
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
   
    height: "24px",
    width: "24px",
    cursor: "pointer",
  },

  headerRightSide: {display: "flex",
    flexWrap: "nowrap",
   
    gap: "24px",
    alignItems: "center",
  },

  userAvatar: {
   
    height: "40px",
    width: "40px",
    borderRadius: "50%"
  },

 


  buttonText: {
    fontFamily: "Roboto, sans-serif",
    
    fontSize: "14px",
    color: "#013D4F",
    fontWeight: 500,
  },

  content: {
   
    marginLeft: "120px",
    width: "100%",
  },

  main: {
    display: "flex",
   
    position: "relative",
    flexWrap: "nowrap",
  },

}
// Customizable Area End